import {
  ButtonV2,
  DialogV2,
  FormGroupRadio,
  FormGroupSelect,
  InputDateWithLabelV2,
  InputWithLabelV2,
  LabelV2,
  LinkButton,
  themeV2,
  themeV3,
  useMobile,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import {
  BASE_CODE,
  BaseDto,
  BaseFunctionToggleCode,
  CREATE_QFACE_STR,
  CreateQFaceStrRequest,
  CreateQFaceStrResponse,
  DOMAINS,
  Gender,
  User,
  UserDiv,
  UserDivId,
  UserInflowSource,
  UserInflowSourceId,
  UserStudentDiv,
  UserStudentDivId,
  isBaseFunctionToggleEnabled
} from '@atomica.co/irori';
import { City, Message, URL } from '@atomica.co/types';
import {
  EMPTY,
  PREFECTURE_NAMES,
  Prefecture,
  ZERO,
  builder,
  embedIdInPath,
  hasLength,
  isEmail,
  uuid
} from '@atomica.co/utils';
import compress from 'browser-image-compression';
import { format } from 'date-fns';
import React, { CSSProperties, ReactNode, memo, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { COMPRESSTION_OPTIONS } from '../../../../constants/denso-const';
import env from '../../../../env/env';
import firebase, { auth } from '../../../../firebase';
import CommonRequest from '../../../../requests/common-request';
import { Path } from '../../../../router/Routes';
import { ImageService } from '../../../../services/image-service';
import {
  DEFAULT_FACE_FEATURE_NOTICE,
  FACE_FEATURE_GENERATED_MESSAGE,
  NO_FACE_RECOGNITION_ERROR_MESSAGE,
  QFACE_SERVER_ERROR_MESSAGE
} from '../../../../texts/face-feature-text';
import { USER_GENDER_LABELS } from '../../../../texts/user-text';
import { partialBuilder } from '../../../../utils/common-util';
import { isValidDateString } from '../../../../utils/date-util';
import {
  toCityLabels,
  toInflowSourceLabels,
  toInflowSourceOptions,
  toUserDivLabels,
  toUserDivOptions,
  toUserStudentDivLabels,
  toUserStudentDivOptions
} from '../../../../utils/user-util';
import camera from './../../../../assets/icon/camera.svg';
import mojaco from './../../../../assets/mojaco/mojaco_break.png';

const MASKED_PASSWORD = '*'.repeat(8);

enum PASSWORD_RESET_MAIL_SEND_STATUS {
  UNSENT = 0,
  SENT = 1
}

interface P {
  base: BaseDto;
  user: User;
  setUser: React.Dispatch<React.SetStateAction<User>>;
  userDivs: UserDiv[];
  userStudentDivs: UserStudentDiv[];
  userInflowSources: UserInflowSource[];
  cities: City[];
  useFaceAccess: boolean;
  facePhotoDownloadURL: URL;
  setUserPhotoFileToSave: React.Dispatch<React.SetStateAction<File | undefined>>;
  setFacePhotoFileToSave: React.Dispatch<React.SetStateAction<File | undefined>>;
  setSaveButtonDisabled: React.Dispatch<React.SetStateAction<boolean>>;
}

const AccountProfileCardEdit: React.FC<P> = memo(props => {
  const {
    base,
    user,
    setUser,
    userDivs,
    userStudentDivs,
    userInflowSources,
    cities,
    useFaceAccess,
    facePhotoDownloadURL,
    setUserPhotoFileToSave,
    setFacePhotoFileToSave,
    setSaveButtonDisabled
  } = props;
  const isMobile = useMobile();

  const unmountRef = useUnmountRef();
  const [tempPhotoDataUrl, setTempPhotoDataUrl] = useSafeState<URL | undefined>(unmountRef, undefined);
  const [tempFacePhotoDataUrl, setTempFacePhotoDataUrl] = useSafeState<URL | undefined>(unmountRef, undefined);
  const [hasFaceFeatureError, setHasFaceFeatureError] = useSafeState<boolean>(unmountRef, false);
  const [faceFeatureMessage, setFaceFeatureMessage] = useSafeState<Message>(unmountRef, DEFAULT_FACE_FEATURE_NOTICE);
  const [isPasswordResetDialogOpen, setIsPasswordResetDialogOpen] = useSafeState<boolean>(unmountRef, false);
  const [isPasswordResetMailSending, setIsPasswordResetMailSending] = useSafeState<boolean>(unmountRef, false);
  const [passwordResetMailSendStatus, setPasswordResetMailSendStatus] = useSafeState<PASSWORD_RESET_MAIL_SEND_STATUS>(
    unmountRef,
    PASSWORD_RESET_MAIL_SEND_STATUS.UNSENT
  );

  const errorMessageFamilyName = useMemo<Message>(() => {
    return user.familyName ? EMPTY : '姓を入力してください';
  }, [user]);

  const errorMessageFirstName = useMemo<Message>(() => {
    return user.firstName ? EMPTY : '名を入力してください';
  }, [user]);

  const errorMessageEmail = useMemo<Message>(() => {
    if (!user.email) return 'メールアドレスを入力してください';
    if (isEmail(user.email)) return EMPTY;
    return '正しい形式のメールアドレスを入力してください';
  }, [user]);

  const errorMessageUserDiv = useMemo<Message>(() => {
    return user.userDiv?.userDivId ? EMPTY : '職業を選択してください';
  }, [user]);

  const errorMessageDateOfBirth = useMemo<Message>(() => {
    if (!user.dateOfBirthV2) return '生年月日を選択してください';
    if (isValidDateString(user.dateOfBirthV2, 'yyyy/MM/dd')) return EMPTY;
    if (isValidDateString(user.dateOfBirthV2, 'yyyy-MM-dd')) return EMPTY;
    return '正しい日付を入力してください';
  }, [user]);

  const errorMessagePhone = useMemo<Message>(() => {
    return user.phone ? EMPTY : '電話番号を選択してください';
  }, [user]);

  const errorMessagePrefecture = useMemo<Message>(() => {
    return user.prefecture ? EMPTY : '都道府県を選択してください';
  }, [user]);

  const errorMessageCity = useMemo<Message>(() => {
    return !user.prefecture || user.city ? EMPTY : '市区町村を選択してください';
  }, [user]);

  const errorMessageUserDivOther = useMemo<Message>(() => {
    if (!user.userDiv?.userDivDef?.hasOther) return EMPTY;
    return user.userDivOther ? EMPTY : 'その他を入力してください';
  }, [user]);

  const errorMessageCompanyName = useMemo<Message>(() => {
    if (!user.userDiv?.userDivDef?.hasCompanyName) return EMPTY;
    return user.companyName ? EMPTY : 'お勤め先を入力してください';
  }, [user]);

  const errorMessageStudentDiv = useMemo<Message>(() => {
    if (!user.userDiv?.userDivDef?.isStudent) return EMPTY;
    return user.userStudentDiv ? EMPTY : '学年を入力してください';
  }, [user]);

  const errorMessageSchoolName = useMemo<Message>(() => {
    if (!user.userStudentDiv?.userStudentDivDef?.hasSchoolName) return EMPTY;
    return user.schoolName ? EMPTY : '学校を入力してください';
  }, [user]);

  const errorMessageStudentDivOther = useMemo<Message>(() => {
    if (!user.userDiv?.userDivDef?.isStudent || !user.userStudentDiv?.userStudentDivDef?.hasOther) return EMPTY;
    return user.userStudentDivOther ? EMPTY : 'その他を入力してください';
  }, [user]);

  const errorMessageUserInflowSource = useMemo<Message>(() => {
    return user.userInflowSource?.userInflowSourceId ? EMPTY : 'ご利用のきっかけを選択してください';
  }, [user]);

  const errorMessageUserInflowSourceDivOther = useMemo<Message>(() => {
    if (!user.userInflowSource?.userInflowSourceDef?.hasOther) return EMPTY;
    return user.userInflowSourceOther ? EMPTY : 'その他を入力してください';
  }, [user]);

  const updateUser = useSafeCallback(
    (userPart: Partial<User>): void => setUser(partialBuilder(user, userPart)),
    [setUser, user]
  );

  const handleUserPhotoChanged = useSafeCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.files?.length === ZERO) return;
      const compressedImageFile = await ImageService.getCompressedImage((event.target.files as FileList)[ZERO], uuid());
      const photoDataUrl = await ImageService.getImageDataUrl(compressedImageFile);
      setUserPhotoFileToSave(compressedImageFile);
      setTempPhotoDataUrl(photoDataUrl);
    },
    [setUserPhotoFileToSave, setTempPhotoDataUrl]
  );

  const handleUserDivChanged = useSafeCallback(
    (userDivId: UserDivId): void => {
      const userDiv = userDivs.find(userDiv => userDiv.userDivId === userDivId);
      const updateTargets: Partial<User> = {
        userDiv,
        companyName: userDiv?.userDivDef?.hasCompanyName ? user.companyName : EMPTY,
        userStudentDiv: userDiv?.userDivDef?.isStudent ? user.userStudentDiv : undefined,
        userStudentDivOther: userDiv?.userDivDef?.isStudent ? user.userStudentDivOther : EMPTY
      };

      updateUser(updateTargets);
    },
    [updateUser, user.companyName, user.userStudentDiv, user.userStudentDivOther, userDivs]
  );

  const handleUserStudentDivChanged = useSafeCallback(
    (userStudentDivId: UserStudentDivId): void => {
      const userStudentDiv = userStudentDivs.find(userDiv => userDiv.userStudentDivId === userStudentDivId);
      updateUser({ userStudentDiv, userStudentDivOther: undefined });
    },
    [updateUser, userStudentDivs]
  );

  const handleUserInflowSourceChanged = useSafeCallback(
    (userInflowSourceId: UserInflowSourceId): void => {
      const userInflowSource = userInflowSources.find(src => src.userInflowSourceId === userInflowSourceId);
      updateUser({ userInflowSource, userInflowSourceOther: undefined });
    },
    [updateUser, userInflowSources]
  );

  const handlePasswordResetButtonClicked = useSafeCallback(() => {
    setIsPasswordResetDialogOpen(true);
  }, [setIsPasswordResetDialogOpen]);

  const isUserDivOtherShown = useMemo<boolean>(() => !!user.userDiv?.userDivDef?.hasOther, [user]);

  const isUserStudentDivOtherShown = useMemo<boolean>(() => !!user.userStudentDiv?.userStudentDivDef?.hasOther, [user]);

  const isUserInflowSourceOtherShown = useMemo<boolean>(
    () => !!user.userInflowSource?.userInflowSourceDef?.hasOther,
    [user]
  );

  const handleFacePhotoChanged = useSafeCallback(
    async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
      if (!event.target.files?.length) return;
      const file = event.target.files[ZERO];
      const compressedFile = await compress(file, COMPRESSTION_OPTIONS);
      const arrayBuffer = await compressedFile.arrayBuffer();
      const faceRecognitionPhotoDataURL = await ImageService.getImageDataUrl(compressedFile);
      const imageData = Buffer.from(arrayBuffer).toString('base64');

      try {
        const request = builder<CreateQFaceStrRequest>().imageData(imageData).build();
        const response = await CommonRequest.call<CreateQFaceStrRequest, CreateQFaceStrResponse>(
          CREATE_QFACE_STR,
          request
        );

        if (!response?.result) {
          setHasFaceFeatureError(true);
          setFaceFeatureMessage(NO_FACE_RECOGNITION_ERROR_MESSAGE);
          return;
        }

        setHasFaceFeatureError(false);
        setFaceFeatureMessage(FACE_FEATURE_GENERATED_MESSAGE);
        updateUser({ faceFeatureStr: response.data! });
        setFacePhotoFileToSave(compressedFile);
        setTempFacePhotoDataUrl(faceRecognitionPhotoDataURL);
        return;
      } catch (e) {
        setHasFaceFeatureError(true);
        setFaceFeatureMessage(QFACE_SERVER_ERROR_MESSAGE);
        return;
      }
    },
    [setHasFaceFeatureError, setFaceFeatureMessage, updateUser, setFacePhotoFileToSave, setTempFacePhotoDataUrl]
  );

  const handlePasswordResetDialogClose = useSafeCallback(() => {
    setIsPasswordResetDialogOpen(false);
    setPasswordResetMailSendStatus(PASSWORD_RESET_MAIL_SEND_STATUS.UNSENT);
  }, [setIsPasswordResetDialogOpen, setPasswordResetMailSendStatus]);

  const handlePasswordResetDialogSendButtonClicked = useSafeCallback(async () => {
    setIsPasswordResetMailSending(true);

    const redirectUrl = `https://${DOMAINS[env]}${embedIdInPath(Path.SIGN_IN, [BASE_CODE], [base.baseCode])}`;
    const settings: firebase.auth.ActionCodeSettings = {
      handleCodeInApp: true,
      url: redirectUrl
    };

    await auth.sendPasswordResetEmail(user.email, settings).catch(() => {
      setIsPasswordResetMailSending(false);
      // TODO: エラー発生時のユーザーへの通知（スナックバーなど）
    });
    setPasswordResetMailSendStatus(PASSWORD_RESET_MAIL_SEND_STATUS.SENT);
    setIsPasswordResetMailSending(false);
  }, [base.baseCode, setIsPasswordResetMailSending, setPasswordResetMailSendStatus, user.email]);

  const passwordResetDialogActions = useMemo<ReactNode[]>(() => {
    switch (passwordResetMailSendStatus) {
      case PASSWORD_RESET_MAIL_SEND_STATUS.UNSENT:
        return [
          <ButtonV2
            key='cancel'
            label='キャンセル'
            disabled={isPasswordResetMailSending}
            onClick={handlePasswordResetDialogClose}
          />,
          <ButtonV2
            type='primary'
            key='password-reset'
            label='送信'
            disabled={isPasswordResetMailSending}
            onClick={handlePasswordResetDialogSendButtonClicked}
          />
        ];
      case PASSWORD_RESET_MAIL_SEND_STATUS.SENT:
        return [
          <ButtonV2 type='primary' key='succeed-password-reset' label='OK' onClick={handlePasswordResetDialogClose} />
        ];
    }
  }, [
    passwordResetMailSendStatus,
    isPasswordResetMailSending,
    handlePasswordResetDialogClose,
    handlePasswordResetDialogSendButtonClicked
  ]);

  useEffect(() => {
    const disabled = !!(
      (
        errorMessageFamilyName ||
        errorMessageFirstName ||
        errorMessageEmail ||
        errorMessageUserDiv ||
        errorMessageDateOfBirth ||
        errorMessagePhone ||
        errorMessagePrefecture ||
        errorMessageCity ||
        errorMessageUserDivOther ||
        errorMessageCompanyName ||
        errorMessageStudentDiv ||
        errorMessageStudentDivOther ||
        errorMessageSchoolName ||
        errorMessageUserInflowSource ||
        errorMessageUserInflowSourceDivOther
      )
    );
    setSaveButtonDisabled(disabled);
  }, [
    setSaveButtonDisabled,
    errorMessageFamilyName,
    errorMessageFirstName,
    errorMessageEmail,
    errorMessageUserDiv,
    errorMessageDateOfBirth,
    errorMessagePhone,
    errorMessagePrefecture,
    errorMessageCity,
    errorMessageUserDivOther,
    errorMessageCompanyName,
    errorMessageStudentDiv,
    errorMessageStudentDivOther,
    errorMessageSchoolName,
    errorMessageUserInflowSource,
    errorMessageUserInflowSourceDivOther
  ]);

  const isEnabledProfileV3 = useMemo((): boolean => {
    return isBaseFunctionToggleEnabled(base, BaseFunctionToggleCode.FUNCTION_USE_PROFILE_V3);
  }, [base]);

  return (
    <Container isColumn={isEnabledProfileV3 ? true : isMobile}>
      {/* UserStudentDivの項目修正に伴い既存の項目を設定している人に対してエラーメッセージを表示する */}
      {(user.userStudentDiv?.isHidden && user.userDiv?.isStudent) && (
        <ErrorMessageArea>
          <StyledInfoOutlinedIcon />
          <div>
            学年情報の再登録をお願いします
          </div>
        </ErrorMessageArea>
      )}
      {!isEnabledProfileV3 && (
        <ImageWrapper>
          <ImageInputPane onChange={handleUserPhotoChanged} />
          <Image src={tempPhotoDataUrl || user.photoURL || mojaco} />
          <ImageIconBack />
          <ImageCameraIcon src={camera} />
        </ImageWrapper>
      )}

      {isEnabledProfileV3 && (
        <ImageItem>
          <LabelV2 text='プロフィール画像' />
          <ImageWrapper>
            <ImageInputPane onChange={handleUserPhotoChanged} />
            <Image src={tempPhotoDataUrl || user.photoURL || mojaco} />
            <ImageIconBack />
            <ImageCameraIcon src={camera} />
          </ImageWrapper>
        </ImageItem>
      )}

      <InformationWrapper isFullWidth={isEnabledProfileV3 ? true : isMobile}>
        <InformationRowWrapper isMobile={isMobile}>
          <InputWithLabelV2
            text={'姓'}
            value={user.familyName ?? EMPTY}
            onChange={familyName => updateUser({ familyName })}
            required
            errorMessage={errorMessageFamilyName}
          />
          <InputWithLabelV2
            text={'名'}
            value={user.firstName ?? EMPTY}
            onChange={firstName => updateUser({ firstName })}
            required
            errorMessage={errorMessageFirstName}
          />
        </InformationRowWrapper>
        <InformationRowWrapper isHalf={!isMobile} isMobile={isMobile}>
          <InputWithLabelV2
            text={'メールアドレス'}
            value={user.email ?? EMPTY}
            onChange={email => updateUser({ email })}
            readonly
            errorMessage={errorMessageEmail}
          />
        </InformationRowWrapper>
        <InformationRowWrapper isMobile={isMobile}>
          <InputWithLabelV2 text='パスワード' value={MASKED_PASSWORD} readonly />
          <FullWidth isFlex alignSelf='flex-end'>
            <LinkButton label='パスワード再設定メールを送る' onClick={handlePasswordResetButtonClicked} />
          </FullWidth>
        </InformationRowWrapper>
        <InformationRowWrapper isHalf={!isMobile} isMobile={isMobile}>
          <InputDateWithLabelV2
            required
            text='生年月日'
            placeholder='1990-01-01'
            errorMessage={errorMessageDateOfBirth}
            value={new Date(user.dateOfBirthV2 ? user.dateOfBirthV2 : '2000/01/01')}
            onChange={dateOfBirthV2 => updateUser({ dateOfBirthV2: format(dateOfBirthV2, 'yyyy-MM-dd') })}
          />
        </InformationRowWrapper>

        <InformationRowWrapper isHalf={!isMobile} isMobile={isMobile}>
          <InputWithLabelV2
            text='電話番号'
            value={user.phone ?? EMPTY}
            onChange={phone => updateUser({ phone })}
            required
            errorMessage={errorMessagePhone}
            inputType='number'
          />
        </InformationRowWrapper>

        <InformationRowWrapper isHalf={!isMobile}>
          <FormGroupRadio
            required
            title='性別'
            options={Object.values(Gender)}
            labels={USER_GENDER_LABELS}
            value={user.gender}
            onChange={gender => updateUser({ gender })}
          />
        </InformationRowWrapper>

        <InformationRowWrapper isMobile={isMobile}>
          <FullWidth>
            <FormGroupSelect
              required
              title='都道府県'
              placeholder='-- 選択してください --'
              options={Object.values(Prefecture)}
              labels={PREFECTURE_NAMES}
              value={user.prefecture || false}
              errorMessage={errorMessagePrefecture}
              onChange={prefecture => updateUser({ prefecture, city: undefined })}
            />
          </FullWidth>

          <FullWidth>
            <FormGroupSelect
              required
              title='市区町村'
              readonly={!user.prefecture}
              placeholder={user.prefecture ? '-- 選択してください --' : '-- 都道府県を選択してください --'}
              options={cities}
              labels={toCityLabels(cities)}
              value={user.city || false}
              errorMessage={errorMessageCity}
              onChange={city => updateUser({ city })}
            />
          </FullWidth>
        </InformationRowWrapper>
        {hasLength(userDivs) && (
          <InformationRowWrapper isMobile={isMobile}>
            <RadioWrapper>
              <FormGroupRadio
                required
                title='職業'
                options={toUserDivOptions(userDivs)}
                labels={toUserDivLabels(userDivs)}
                value={user.userDiv?.userDivId}
                onChange={handleUserDivChanged}
                errorMessage={errorMessageUserDiv}
              />
            </RadioWrapper>
          </InformationRowWrapper>
        )}

        {isUserDivOtherShown && (
          <InformationRowWrapper>
            <InputWithLabelV2
              text='その他'
              value={user.userDivOther ?? EMPTY}
              onChange={userDivOther => updateUser({ userDivOther })}
              required
              errorMessage={errorMessageUserDivOther}
            />
          </InformationRowWrapper>
        )}

        {user.userDiv?.userDivDef?.hasCompanyName && (
          <InformationRowWrapper isHalf={!isMobile}>
            <InputWithLabelV2
              text={'お勤め先'}
              value={user.companyName ?? EMPTY}
              onChange={companyName => updateUser({ companyName })}
              required
              errorMessage={errorMessageCompanyName}
            />
          </InformationRowWrapper>
        )}

        {user.userDiv?.userDivDef?.isStudent && hasLength(userStudentDivs) && (
          <InformationRowWrapper isMobile={isMobile}>
            <RadioWrapper>
              <FormGroupRadio
                required
                title='学年'
                options={toUserStudentDivOptions(userStudentDivs)}
                labels={toUserStudentDivLabels(userStudentDivs)}
                value={user.userStudentDiv?.userStudentDivId}
                onChange={handleUserStudentDivChanged}
                errorMessage={errorMessageStudentDiv}
              />
            </RadioWrapper>
          </InformationRowWrapper>
        )}

        {user.userStudentDiv?.userStudentDivDef?.hasSchoolName && (
          <InformationRowWrapper isHalf={!isMobile}>
            <InputWithLabelV2
              required={user.userStudentDiv?.userStudentDivDef?.hasSchoolName}
              text={'学校'}
              value={user.schoolName ?? EMPTY}
              onChange={schoolName => updateUser({ schoolName })}
              errorMessage={errorMessageSchoolName}
            />
          </InformationRowWrapper>
        )}

        {user.userStudentDiv?.userStudentDivDef?.hasSchoolName && (
          <InformationRowWrapper isHalf={!isMobile}>
            <InputWithLabelV2
              text={'学部'}
              value={user.schoolDepartmentName ?? EMPTY}
              onChange={schoolDepartmentName => updateUser({ schoolDepartmentName })}
            />
          </InformationRowWrapper>
        )}

        {isUserStudentDivOtherShown && (
          <InformationRowWrapper>
            <InputWithLabelV2
              text='その他'
              value={user.userStudentDivOther ?? EMPTY}
              onChange={userStudentDivOther => updateUser({ userStudentDivOther })}
              required
              errorMessage={errorMessageStudentDivOther}
            />
          </InformationRowWrapper>
        )}

        {hasLength(userInflowSources) && (
          <InformationRowWrapper>
            <FormGroupRadio
              required
              title='ご利用のきっかけ'
              options={toInflowSourceOptions(userInflowSources)}
              labels={toInflowSourceLabels(userInflowSources)}
              value={user.userInflowSource?.userInflowSourceId}
              onChange={handleUserInflowSourceChanged}
              errorMessage={errorMessageUserInflowSource}
            />
          </InformationRowWrapper>
        )}
        {isUserInflowSourceOtherShown && (
          <InformationRowWrapper>
            <InputWithLabelV2
              text='その他'
              value={user.userInflowSourceOther ?? EMPTY}
              onChange={userInflowSourceOther => updateUser({ userInflowSourceOther })}
              required
              errorMessage={errorMessageUserInflowSourceDivOther}
            />
          </InformationRowWrapper>
        )}

        {useFaceAccess && (
          <InformationRowWrapper isHalf={!isMobile} isMobile={isMobile}>
            <FacePhotoAndTitleWrapper>
              <FacePhotoTitleWrapper>
                <FacePhotoTitle>顔認証用写真</FacePhotoTitle>
              </FacePhotoTitleWrapper>
              <ImageWrapper borderRadius={themeV2.mixins.v2.spacing}>
                <ImageInputPane onChange={handleFacePhotoChanged} />
                {tempFacePhotoDataUrl || facePhotoDownloadURL ? (
                  <FacePhotoImage src={tempFacePhotoDataUrl || facePhotoDownloadURL} />
                ) : (
                  <FacePhotoImageUndefined>顔写真未登録</FacePhotoImageUndefined>
                )}
                <ImageIconBack />
                <ImageCameraIcon src={camera} />
              </ImageWrapper>
              <FacePhotoMessage hasError={hasFaceFeatureError}>{faceFeatureMessage}</FacePhotoMessage>
            </FacePhotoAndTitleWrapper>
          </InformationRowWrapper>
        )}
      </InformationWrapper>
      <DialogV2
        width={680}
        isOpen={isPasswordResetDialogOpen}
        headerLabel='パスワードを再設定'
        buttonsOnTheRight={passwordResetDialogActions}
        onClose={handlePasswordResetDialogClose}
      >
        <PasswordResetDialogContent>
          {passwordResetMailSendStatus === PASSWORD_RESET_MAIL_SEND_STATUS.UNSENT
            ? '登録されているメールアドレスにパスワード再設定メールを送信します。 \nよろしいですか？'
            : '新しいメールアドレス宛にパスワード再設定メールを送信しました。\nメールに記載されている手順に沿ってパスワードを再設定してください。'}
        </PasswordResetDialogContent>
      </DialogV2>
    </Container>
  );
});

AccountProfileCardEdit.displayName = 'AccountProfileCardEdit';
export default AccountProfileCardEdit;

const Container = styled.div<{ isColumn: boolean }>`
  display: flex;
  width: 100%;
  flex-direction: ${({ isColumn }) => (isColumn ? 'column' : 'row')};
  align-items: flex-start;
  gap: ${themeV2.mixins.v2.spacing * 2}px;
`;

const ImageWrapper = styled.div<{ borderRadius?: number }>`
  width: 120px;
  height: 120px;
  text-align: center;
  border: 1px solid ${themeV2.mixins.v2.color.border.gray};
  border-radius: ${({ borderRadius }) => borderRadius ?? 60}px;
  overflow: hidden;
  position: relative;
`;

const InformationWrapper = styled.div<{ isFullWidth: boolean }>`
  display: flex;
  flex-direction: column;
  ${({ isFullWidth }) => (isFullWidth ? EMPTY : 'flex-grow: 1')};
  align-items: flex-start;
  gap: ${themeV2.mixins.v2.spacing * 3}px;
  width: ${({ isFullWidth }) => (isFullWidth ? '100%' : 'calc(100% - 120px)')};
`;

const InformationRowWrapper = styled.div<{ isHalf?: boolean; isMobile?: boolean }>`
  display: flex;
  align-items: flex-start;
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
  gap: ${themeV2.mixins.v2.spacing * 3}px;
  width: ${({ isHalf }) => (isHalf ? 'calc(50% - ' + Math.floor((themeV2.mixins.v2.spacing * 3) / 2) + 'px)' : '100%')};
`;

const ImageItem = styled.div`
  width: 100%;
  min-height: 24px;
  display: flex;
  flex-direction: column;
  gap: ${themeV2.mixins.v2.spacing / 2}px;
`;

const Image = styled.img`
  width: 120px;
  height: 120px;
  object-fit: cover;
`;

const ImageInputPane = styled.input.attrs({ type: 'file', accept: 'image/*' })`
  width: 120px;
  height: 120px;
  border-radius: 60px;
  opacity: 0;
  appearance: none;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  &:hover {
    cursor: pointer;
  }
`;

const ImageIconBack = styled.div`
  background-color: ${themeV2.mixins.v2.color.background.black};
  opacity: 0.5;
  height: 32px;
  width: 120px;
  position: absolute;
  top: 88px;
  left: 0;
`;
const ImageCameraIcon = styled.img`
  width: 24px;
  height: 24px;
  position: absolute;
  top: 92px;
  left: calc(50% - 12px);
`;

const FullWidth = styled.div<{ isFlex?: boolean; alignSelf?: CSSProperties['alignSelf'] }>`
  width: 100%;
  display: ${({ isFlex }) => (isFlex ? 'flex' : 'block')};
  ${({ alignSelf }) => (alignSelf ? `align-self: ${alignSelf}` : EMPTY)}
`;

const RadioWrapper = styled.div`
  flex-direction: column;
  width: 100%;
`;
const FacePhotoAndTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const FacePhotoTitleWrapper = styled.div`
  width: 100%;
  min-height: 24px;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const FacePhotoTitle = styled.div`
  font-size: 14px;
  font-weight: 700;
  font-family: 'Noto Sans JP';
  letter-spacing: 0.5px;
  color: #666666;
`;

const FacePhotoImage = styled.img`
  width: 120px;
  height: 120px;
  object-fit: cover;
  border-radius: 8px;
`;

const FacePhotoImageUndefined = styled.div`
  width: 120px;
  height: 120px;
  line-height: 120px;
  border-radius: 8px;
  background-color: ${themeV2.mixins.v2.color.border.gray};
  text-align: center;
  vertical-align: middle;
  font-size: 14px;
  font-weight: 700;
  font-family: 'Noto Sans JP';
  letter-spacing: 0.5px;
  color: #666666;
`;

const FacePhotoMessage = styled.div<{ hasError?: boolean }>`
  font-size: 14px;
  font-weight: 500;
  font-family: 'Noto Sans JP';
  letter-spacing: 0.5px;
  color: ${({ hasError }) => (hasError ? themeV2.mixins.v2.color.font.pink : themeV2.mixins.v2.color.font.black)};

  white-space: pre-wrap;
`;

const PasswordResetDialogContent = styled.div`
  display: flex;
  padding: ${themeV2.mixins.v2.spacing * 2}px ${themeV2.mixins.v2.spacing * 3}px;
  align-items: flex-start;
  gap: ${themeV2.mixins.v2.spacing}px;
  align-self: stretch;
  white-space: pre-wrap;
  ${themeV2.mixins.v2.typography.body.large};
  color: ${themeV2.mixins.v2.color.font.black};
`;

const ErrorMessageArea = styled.div`
  ${themeV2.mixins.v2.typography.body.medium};
  display: flex;
  align-items: center;
  gap: ${themeV2.mixins.v2.spacing * 2}px;
  padding: ${themeV2.mixins.v2.spacing * 2}px;
  color: ${themeV3.mixins.v3.color.container.action.errorDark};
  background: ${themeV3.mixins.v3.color.container.action.errorPale};
  border-radius: 8px;
  white-space: pre-wrap;
`;

const StyledInfoOutlinedIcon = styled(InfoOutlinedIcon)`
  color: ${themeV3.mixins.v3.color.container.action.error};
`;