import {
  Button,
  CommentBox,
  FormGroupDate,
  FormGroupRadio,
  FormGroupSelect,
  FormGroupText,
  HEADER_HEIGHT,
  HeaderV2,
  ImageInputBox,
  MOBILE_MAX_WIDTH,
  MOBILE_MIN_WIDTH,
  ScreenLoaderV2,
  Scrollable,
  customBreakpoints,
  theme,
  themeV2,
  themeV3,
  useMobile,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import {
  AUTHORITY_CODE,
  AuthorityDefCodeEnum,
  BaseAuthority,
  BaseDto,
  CATCH_FRONTEND_ERROR,
  CREATE_QFACE_STR,
  CatchFrontendErrorRequest,
  CatchFrontendErrorResponse,
  CreateQFaceStrRequest,
  CreateQFaceStrResponse,
  END_DATE,
  ErrorCode,
  FETCH_CITIES,
  FETCH_USER_DIVS,
  FETCH_USER_INFLOW_SOURCES,
  FETCH_USER_STUDENT_DIVS,
  FetchCitiesRequest,
  FetchCitiesResponse,
  FetchUserDivsRequest,
  FetchUserDivsResponse,
  FetchUserInflowSourcesRequest,
  FetchUserInflowSourcesResponse,
  FetchUserStudentDivsRequest,
  FetchUserStudentDivsResponse,
  Gender,
  PrefectureWithCities,
  SAVE_FIREBASE,
  START_DATE,
  SaveFirebaseRequest,
  SaveFirebaseResponse,
  User,
  UserDiv,
  UserDivId,
  UserInflowSource,
  UserInflowSourceId,
  UserStudentDiv,
  UserStudentDivId
} from '@atomica.co/irori';
import { City, DateStr, Email, Input, Message, Name, Password, Phone, URL } from '@atomica.co/types';
import {
  EMAIL,
  EMPTY,
  MINUS_ONE,
  ONE,
  PREFECTURE_NAMES,
  Prefecture,
  ZERO,
  builder,
  hasLength,
  noop,
  toEndOfDay
} from '@atomica.co/utils';
import { toBeginningOfDay } from '@atomica.co/utils/build/utils';
import { Typography } from '@material-ui/core';
import compress from 'browser-image-compression';
import { format } from 'date-fns';
import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { SaveUserBody } from '../../__generated/model';
import { getBsUser } from '../../__generated/user/bs-user/bs-user';
import logo_knot_place from '../../assets/logo/logo_knot_place_v2.png';
import Screen from '../../components/screen/Screen';
import { PRIVACY_POLICY_FOR_KNOT_PLACE, TERMS_OF_USE_FOR_KNOT_PLACE } from '../../constants/account-const';
import { COMPRESSTION_OPTIONS } from '../../constants/denso-const';
import { CACHED_URL } from '../../constants/url-const';
import useCachedInitialAuthorityCode from '../../redux/hooks/useCachedInitialAuthorityCode';
import useCachedJoinURL from '../../redux/hooks/useCachedJoinURL';
import useCachedURL from '../../redux/hooks/useCachedURL';
import useCommonRequest from '../../redux/hooks/useCommonRequest';
import usePath from '../../redux/hooks/usePath';
import useUser from '../../redux/hooks/useUser';
import CommonRequest from '../../requests/common-request';
import { Path } from '../../router/Routes';
import { AuthService } from '../../services/auth-service';
import { ImageService } from '../../services/image-service';
import { ERROR_MESSAGES } from '../../texts/error-text';
import {
  DEFAULT_FACE_FEATURE_NOTICE,
  FACE_FEATURE_GENERATED_MESSAGE,
  NO_FACE_RECOGNITION_ERROR_MESSAGE,
  QFACE_SERVER_ERROR_MESSAGE
} from '../../texts/face-feature-text';
import { USER_GENDER_LABELS } from '../../texts/user-text';
import { getBaseConsumerHomePathAndName } from '../../utils/path-util';
import { findSpecificCities } from '../../utils/prefecture-city-utils';
import {
  toCityLabels,
  toInflowSourceLabels,
  toInflowSourceOptions,
  toUserDivLabels,
  toUserDivOptions,
  toUserStudentDivLabels,
  toUserStudentDivOptions
} from '../../utils/user-util';
import mojaco_greeting from './../../assets/mojaco/mojaco_greeting.png';
import UserPolicy from './user-policy/UserPolicy';
import { PASSWORD_LENGTH_MAX, PASSWORD_LENGTH_MIN } from '../../constants/auth-const';

const AUTHORITY_ERROR_MSG = '権限設定が不足しています\r\n管理者にお問い合わせください';

interface P {
  base: BaseDto;
  user: User | undefined;
}

const openTermOfUse = (url: URL): void => {
  window.open(url, 'termsOfUse');
};

const openPrivacyPolicy = (url: URL): void => {
  window.open(url, 'privacyPolicy');
};

const TERM_OF_USE_NOT_CHECKED_ERROR_MSG = '同意のチェックを入れてください';

const RegisterAccountScreen: React.FC<P> = React.memo(props => {
  const { base } = props;
  const { loaded: userLoaded, user: kpUser, getFirebaseEmail, getProviders } = useUser();

  const { queryParams, openBasePath } = usePath();
  const { saveCachedURL, hasCachedURL, openCachedURL } = useCachedURL();
  const { openCachedJoinURL, hasCachedJoinURL } = useCachedJoinURL();
  const { cachedInitialAuthorityCode, clearCachedInitialAuthorityCode } = useCachedInitialAuthorityCode();
  const { commonRequest } = useCommonRequest();

  const unmountRef = useUnmountRef();
  const [loaded, setLoaded] = useSafeState<boolean>(unmountRef, false);
  const [prefectureWithCities, setPrefectureWithCities] = useSafeState<PrefectureWithCities[]>(unmountRef, []);
  const [userDivs, setUserDivs] = useSafeState<UserDiv[]>(unmountRef, []);
  const [userStudentDivs, setUserStudentDivs] = useSafeState<UserStudentDiv[]>(unmountRef, []);
  const [userInflowSources, setUserInflowSources] = useSafeState<UserInflowSource[]>(unmountRef, []);
  // const [userUsagePurposes, setUserUsagePurposes] = useSafeState<UserUsagePurpose[]>(unmountRef, []);
  // const [userFamilyMembers, setUserFamilyMembers] = useSafeState<UserFamilyMember[]>(unmountRef, []);
  const [saving, setSaving] = useSafeState<boolean>(unmountRef, false);
  const [isKnotPlaceTermOfUseAgreed, setIsKnotPlaceTermOfUseAgreed] = useSafeState<boolean>(unmountRef, false);
  const [isAdditionalTermOfUseAgreed, setIsAdditionalTermOfUseAgreed] = useSafeState<boolean>(unmountRef, false);
  const [familyNameToSave, setFamilyNameToSave] = useSafeState<Name>(unmountRef, EMPTY);
  const [firstNameToSave, setFirstNameToSave] = useSafeState<Name>(unmountRef, EMPTY);
  const [genderToSave, setGenderToSave] = useSafeState<Gender | undefined>(unmountRef);
  const [companyNameToSave, setCompanyNameToSave] = useSafeState<Name>(unmountRef, EMPTY);
  const [schoolNameToSave, setSchoolNameToSave] = useSafeState<Name>(unmountRef, EMPTY);
  const [schoolDepartmentNameToSave, setSchoolDepartmentNameToSave] = useSafeState<Name>(unmountRef, EMPTY);
  const [passwordToSave, setPasswordToSave] = useSafeState<Password>(unmountRef, EMPTY);
  const [dateOfBirthV2ToSave, setBirthDateToSave] = useSafeState<DateStr | undefined>(unmountRef);
  const [phoneToSave, setPhoneToSave] = useSafeState<Phone>(unmountRef, EMPTY);
  const [prefectureToSave, setPrefectureToSave] = useSafeState<Prefecture | undefined>(unmountRef);
  const [cityToSave, setCityToSave] = useSafeState<City>(unmountRef, EMPTY);
  const [userDivToSave, setUserDivToSave] = useSafeState<UserDiv | undefined>(unmountRef);
  const [userDivOtherToSave, setUserDivOtherToSave] = useSafeState<Name>(unmountRef, EMPTY);
  const [userStudentDivToSave, setUserStudentDivToSave] = useSafeState<UserStudentDiv | undefined>(unmountRef);
  const [userStudentDivOtherToSave, setUserStudentDivOtherToSave] = useSafeState<Name>(unmountRef, EMPTY);
  const [userInflowSourceToSave, setUserInflowSourceToSave] = useSafeState<UserInflowSource | undefined>(unmountRef);
  const [userInflowSourceOtherToSave, setUserInflowSourceOtherToSave] = useSafeState<Name>(unmountRef, EMPTY);
  // const [userUsagePurposeToSave, setUserUsagePurposeToSave] = useSafeState<UserUsagePurpose | undefined>(unmountRef);
  // const [userUsagePurposeOtherToSave, setUserUsagePurposeOtherToSave] = useSafeState<Name>(unmountRef, EMPTY);
  // const [userFamilyMembersToSave, setUserFamilyMembersToSave] = useSafeState<UserFamilyMember[]>(unmountRef, []);
  // const [userFamilyMemberOtherToSave, setUserFamilyMemberOtherToSave] = useSafeState<Name>(unmountRef, EMPTY);
  const [facePhoto, setFacePhoto] = useSafeState<File>(unmountRef);
  const [faceFeatureStrToSave, setFaceFeatureStrToSave] = useSafeState<string>(unmountRef);
  const [errorMessage, setErrorMessage] = useSafeState<Message>(unmountRef, EMPTY);
  const [hasFaceFeatureError, setHasFaceFeatureError] = useSafeState<boolean>(unmountRef, false);
  const [faceFeatureMessage, setFaceFeatureMessage] = useSafeState<Message>(unmountRef, DEFAULT_FACE_FEATURE_NOTICE);
  const [isSaveNewUserButtonClicked, setIsSaveNewUserButtonClicked] = useSafeState<boolean>(unmountRef, false);
  const [knotPlaceAgreeErrorMessage, setKnotPlaceAgreeErrorMessage] = useSafeState<Message>(unmountRef, EMPTY);
  const [additionalAgreeErrorMessage, setAdditionalAgreeErrorMessage] = useSafeState<Message>(unmountRef, EMPTY);

  const hasAdditonalUserPolicy = useMemo<boolean>(() => {
    return base.baseCode === 'bloomingcamp';
  }, [base]);

  const isInitialState = useSafeCallback(
    (value: Input | Date | undefined): boolean => {
      return (
        (isSaveNewUserButtonClicked === false && value === EMPTY) ||
        (isSaveNewUserButtonClicked === false && value === undefined)
      );
    },
    [isSaveNewUserButtonClicked]
  );

  const errorMessageFamilyName = useMemo<Message>(() => {
    return familyNameToSave || isInitialState(familyNameToSave) ? EMPTY : '姓を入力してください';
  }, [familyNameToSave, isInitialState]);

  const errorMessageFirstName = useMemo<Message>(() => {
    return firstNameToSave || isInitialState(firstNameToSave) ? EMPTY : '名を入力してください';
  }, [firstNameToSave, isInitialState]);

  const errorMessageGender = useMemo<Message>(() => {
    return genderToSave || isInitialState(genderToSave) ? EMPTY : '性別を選択してください';
  }, [genderToSave, isInitialState]);

  const errorMessagePassword = useMemo<Message>(() => {
    return (passwordToSave && passwordToSave.length >= PASSWORD_LENGTH_MIN) || isInitialState(passwordToSave)
      ? EMPTY
      : `パスワード（${PASSWORD_LENGTH_MIN}文字以上${PASSWORD_LENGTH_MAX}文字以下）を入力してください`;
  }, [passwordToSave, isInitialState]);

  const errorMessageDateOfBirth = useMemo<Message>(() => {
    return dateOfBirthV2ToSave || isInitialState(dateOfBirthV2ToSave) ? EMPTY : '生年月日を入力してください';
  }, [dateOfBirthV2ToSave, isInitialState]);

  const errorMessagePhone = useMemo<Message>(() => {
    return phoneToSave || isInitialState(phoneToSave) ? EMPTY : '電話番号を入力してください';
  }, [phoneToSave, isInitialState]);

  const errorMessagePrefecture = useMemo<Message>(() => {
    return prefectureToSave || isInitialState(prefectureToSave) ? EMPTY : '都道府県を選択してください';
  }, [prefectureToSave, isInitialState]);

  const errorMessageCity = useMemo<Message>(() => {
    return cityToSave || isInitialState(cityToSave) ? EMPTY : '市区町村を選択してください';
  }, [cityToSave, isInitialState]);

  const errorMessageUserDiv = useMemo<Message>(() => {
    if (!hasLength(userDivs)) return EMPTY;
    return userDivToSave?.userDivId || isInitialState(userDivToSave?.userDivId) ? EMPTY : '職業を選択してください';
  }, [userDivs, userDivToSave, isInitialState]);

  const isCompanyNameShown = useMemo((): boolean => {
    return userDivToSave?.userDivDef?.hasCompanyName || false;
  }, [userDivToSave]);

  const errorMessageCompanyName = useMemo<Message>(() => {
    if (!isCompanyNameShown) return EMPTY;
    return companyNameToSave || isInitialState(companyNameToSave) ? EMPTY : 'お勤め先を入力してください';
  }, [isCompanyNameShown, companyNameToSave, isInitialState]);

  const errorMessageUserInflowSource = useMemo<Message>(() => {
    if (!hasLength(userInflowSources)) return EMPTY;
    return userInflowSourceToSave?.userInflowSourceId || isInitialState(userInflowSourceToSave?.userInflowSourceId)
      ? EMPTY
      : 'ご利用のきっかけを選択してください';
  }, [userInflowSources, userInflowSourceToSave, isInitialState]);

  const startDateToSave = useMemo<Date>(() => {
    const startDate = queryParams[START_DATE];
    return startDate ? new Date(startDate) : new Date();
  }, [queryParams]);

  const endDateToSave = useMemo<Date | undefined>(() => {
    const endDate = queryParams[END_DATE];
    return endDate ? new Date(endDate) : undefined;
  }, [queryParams]);

  const email = useMemo<Email | undefined>(() => {
    return queryParams[EMAIL];
  }, [queryParams]);

  const cachedURL = useMemo((): URL => {
    return queryParams[CACHED_URL];
  }, [queryParams]);

  const authorities = useMemo<BaseAuthority[]>(() => {
    return base.authorities ?? [];
  }, [base]);

  const isFacePhotoRequired = useMemo<boolean>(() => {
    const authorityCode = queryParams[AUTHORITY_CODE] || cachedInitialAuthorityCode;
    if (!authorityCode || !hasLength(authorities)) return false;

    const authority = authorities.find(auth => auth.authorityCode === authorityCode);
    if (!authority) return false;

    const authorityDef = authority.authorityDef;
    return !!authorityDef && authorityDef.authorityDefCode === AuthorityDefCodeEnum.FACE_ACCESS;
  }, [authorities, cachedInitialAuthorityCode, queryParams]);

  const isUserDivOtherShown = useMemo((): boolean => {
    return userDivToSave?.userDivDef?.hasOther || false;
  }, [userDivToSave]);

  const errorMessageUserDivOtherToSave = useMemo<Message>(() => {
    if (!isUserDivOtherShown) return EMPTY;
    return userDivOtherToSave || isInitialState(userDivOtherToSave)
      ? EMPTY
      : '「その他」の内容を具体的に記述してください';
  }, [isUserDivOtherShown, userDivOtherToSave, isInitialState]);

  const isUserStudentDivShown = useMemo((): boolean => {
    return (userDivToSave?.userDivDef?.isStudent || false) && hasLength(userStudentDivs);
  }, [userDivToSave, userStudentDivs]);

  const errorMessageUserStudentDivToSave = useMemo<Message>(() => {
    if (!isUserStudentDivShown) return EMPTY;
    return userStudentDivToSave?.userStudentDivId || isInitialState(userStudentDivToSave?.userStudentDivId)
      ? EMPTY
      : '「学年」を選択してください';
  }, [isUserStudentDivShown, userStudentDivToSave?.userStudentDivId, isInitialState]);

  const errorMessageSchoolNameToSave = useMemo<Message>(() => {
    if (!userStudentDivToSave?.userStudentDivDef?.hasSchoolName) return EMPTY;
    return schoolNameToSave || isInitialState(schoolNameToSave) ? EMPTY : '「学校」を記入してください';
  }, [userStudentDivToSave?.userStudentDivDef?.hasSchoolName, schoolNameToSave, isInitialState]);

  const isUserStudentDivOtherShown = useMemo((): boolean => {
    return isUserStudentDivShown && (userStudentDivToSave?.userStudentDivDef?.hasOther || false);
  }, [isUserStudentDivShown, userStudentDivToSave]);

  const errorUserStudentDivOtherToSave = useMemo<Message>(() => {
    if (!isUserStudentDivOtherShown) return EMPTY;
    return userStudentDivOtherToSave || isInitialState(userStudentDivOtherToSave)
      ? EMPTY
      : '「その他」の内容を具体的に記述してください';
  }, [isUserStudentDivOtherShown, userStudentDivOtherToSave, isInitialState]);

  const isUserInflowSourceOtherShown = useMemo((): boolean => {
    return userInflowSourceToSave?.userInflowSourceDef?.hasOther || false;
  }, [userInflowSourceToSave]);

  const errorMessageUserInflowSourceOtherToSave = useMemo<Message>(() => {
    if (!userInflowSourceToSave) return EMPTY;
    return userInflowSourceOtherToSave || isInitialState(userInflowSourceOtherToSave)
      ? EMPTY
      : '「その他」の内容を具体的に記述してください';
  }, [userInflowSourceToSave, userInflowSourceOtherToSave, isInitialState]);

  // const isUserUsagePurposeOtherShown = useMemo((): boolean => {
  //   return userUsagePurposeToSave?.userUsagePurposeDef?.hasOther || false;
  // }, [userUsagePurposeToSave]);

  // const isUserFamilyMemberOtherShown = useMemo((): boolean => {
  //   return userFamilyMembersToSave.some(member => member.userFamilyMemberDef?.hasOther);
  // }, [userFamilyMembersToSave]);

  const initialize = useSafeCallback(async (): Promise<void> => {
    const userDivRequest = builder<FetchUserDivsRequest>().baseCode(base.baseCode).build();
    const userStudentRequest = builder<FetchUserStudentDivsRequest>().baseCode(base.baseCode).build();
    const userInflowSourceRequest = builder<FetchUserInflowSourcesRequest>().baseCode(base.baseCode).build();
    // const userUsagePurposesRequest = builder<FetchUserUsagePurposesRequest>().baseCode(base.baseCode).build();
    // const userFamilyMembersRequest = builder<FetchUserFamilyMembersRequest>().baseCode(base.baseCode).build();

    const [
      userDivResponse,
      userStudentDivResponse,
      userInflowSourceResponse,
      // userUsagePurposesResponse,
      // userFamilyMembersResponse,
      citiesResponse
    ] = await Promise.all([
      commonRequest<FetchUserDivsRequest, FetchUserDivsResponse>(FETCH_USER_DIVS, userDivRequest),
      commonRequest<FetchUserStudentDivsRequest, FetchUserStudentDivsResponse>(
        FETCH_USER_STUDENT_DIVS,
        userStudentRequest
      ),
      commonRequest<FetchUserInflowSourcesRequest, FetchUserInflowSourcesResponse>(
        FETCH_USER_INFLOW_SOURCES,
        userInflowSourceRequest
      ),
      // commonRequest<FetchUserUsagePurposesRequest, FetchUserUsagePurposesResponse>(
      //   FETCH_USER_USAGE_PURPOSES,
      //   userUsagePurposesRequest
      // ),
      // commonRequest<FetchUserFamilyMembersRequest, FetchUserFamilyMembersResponse>(
      //   FETCH_USER_FAMILY_MEMBERS,
      //   userFamilyMembersRequest
      // ),
      commonRequest<FetchCitiesRequest, FetchCitiesResponse>(FETCH_CITIES, {})
    ]);

    setUserDivs(userDivResponse.userDivs);
    setUserStudentDivs(userStudentDivResponse.userStudentDivs);
    setUserInflowSources(userInflowSourceResponse.userInflowSources);
    // setUserUsagePurposes(userUsagePurposesResponse.userUsagePurposes);
    // setUserFamilyMembers(userFamilyMembersResponse.userFamilyMembers);
    setPrefectureWithCities(citiesResponse.prefectures);

    !!cachedURL && saveCachedURL(cachedURL);
    setLoaded(true);
  }, [
    base,
    setUserDivs,
    setUserStudentDivs,
    setUserInflowSources,
    // setUserUsagePurposes,
    // setUserFamilyMembers,
    setPrefectureWithCities,
    cachedURL,
    saveCachedURL,
    setLoaded,
    commonRequest
  ]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  const isValidateFailed = useSafeCallback((): boolean => {
    const isDataNotEnough =
      saving ||
      !familyNameToSave ||
      !firstNameToSave ||
      !genderToSave ||
      !phoneToSave ||
      !dateOfBirthV2ToSave ||
      !!errorMessageDateOfBirth ||
      !prefectureToSave ||
      !cityToSave ||
      (hasLength(userDivs) && !userDivToSave) ||
      (isCompanyNameShown && !companyNameToSave) ||
      (isUserDivOtherShown ? !userDivOtherToSave : false) ||
      (isUserStudentDivShown && !userStudentDivToSave) ||
      (isUserStudentDivOtherShown ? !userStudentDivOtherToSave : false) ||
      (hasLength(userInflowSources) && !userInflowSourceToSave) ||
      (isUserInflowSourceOtherShown ? !userInflowSourceOtherToSave : false) ||
      (!!email && !passwordToSave) ||
      (!!email && passwordToSave && passwordToSave.length < PASSWORD_LENGTH_MIN) ||
      (userStudentDivToSave?.userStudentDivDef?.hasSchoolName && !schoolNameToSave) ||
      (isFacePhotoRequired && !faceFeatureStrToSave);
    return isDataNotEnough;
  }, [
    saving,
    phoneToSave,
    errorMessageDateOfBirth,
    familyNameToSave,
    firstNameToSave,
    genderToSave,
    dateOfBirthV2ToSave,
    prefectureToSave,
    cityToSave,
    userDivs,
    userDivToSave,
    isCompanyNameShown,
    companyNameToSave,
    isUserDivOtherShown,
    userDivOtherToSave,
    isUserStudentDivShown,
    userStudentDivToSave,
    isUserStudentDivOtherShown,
    userStudentDivOtherToSave,
    userInflowSources,
    userInflowSourceToSave,
    isUserInflowSourceOtherShown,
    userInflowSourceOtherToSave,
    email,
    passwordToSave,
    isFacePhotoRequired,
    faceFeatureStrToSave,
    schoolNameToSave
  ]);

  const handleFacePhotoChanged = useSafeCallback(
    async (file: File): Promise<boolean> => {
      const compressedFile = await compress(file, COMPRESSTION_OPTIONS);
      const arrayBuffer = await compressedFile.arrayBuffer();
      const imageData = Buffer.from(arrayBuffer).toString('base64');

      try {
        const request = builder<CreateQFaceStrRequest>().imageData(imageData).build();
        const response = await CommonRequest.call<CreateQFaceStrRequest, CreateQFaceStrResponse>(
          CREATE_QFACE_STR,
          request
        );

        if (!response?.result) {
          setHasFaceFeatureError(true);
          setFaceFeatureMessage(NO_FACE_RECOGNITION_ERROR_MESSAGE);
          setFaceFeatureStrToSave(EMPTY);
          return false;
        }

        setHasFaceFeatureError(false);
        setFaceFeatureMessage(FACE_FEATURE_GENERATED_MESSAGE);
        setFaceFeatureStrToSave(response.data!);
        setFacePhoto(compressedFile);
        return true;
      } catch (e) {
        setHasFaceFeatureError(true);
        setFaceFeatureMessage(QFACE_SERVER_ERROR_MESSAGE);
        setFaceFeatureStrToSave(EMPTY);
        return false;
      }
    },
    [setHasFaceFeatureError, setFaceFeatureMessage, setFaceFeatureStrToSave, setFacePhoto]
  );

  const openMyAccountScreen = useSafeCallback((): void => {
    if (!base.isPublic) {
      openBasePath(Path.REGISTERED);
      return;
    }

    if (hasCachedJoinURL()) {
      openCachedJoinURL();
      return;
    }

    if (hasCachedURL()) {
      openCachedURL();
      return;
    }

    const [path] = getBaseConsumerHomePathAndName(base);
    openBasePath(path);
  }, [base, hasCachedJoinURL, hasCachedURL, openBasePath, openCachedJoinURL, openCachedURL]);

  const constructErrorMessage = useSafeCallback(
    (code: ErrorCode): void => {
      if (!code) return;

      Object.entries(ERROR_MESSAGES)
        .filter(message => code.toString().indexOf(message[ZERO]) !== MINUS_ONE)
        .forEach(message => setErrorMessage(message[ONE]));
    },
    [setErrorMessage]
  );

  const saveNewUser = useSafeCallback(async (): Promise<void> => {
    try {
      setErrorMessage(EMPTY);
      setIsSaveNewUserButtonClicked(true);
      if (!isKnotPlaceTermOfUseAgreed) {
        setKnotPlaceAgreeErrorMessage(TERM_OF_USE_NOT_CHECKED_ERROR_MSG);
      }
      if (hasAdditonalUserPolicy && !isAdditionalTermOfUseAgreed) {
        setAdditionalAgreeErrorMessage(TERM_OF_USE_NOT_CHECKED_ERROR_MSG);
      }
      if (isValidateFailed() || !isKnotPlaceTermOfUseAgreed || (hasAdditonalUserPolicy && !isAdditionalTermOfUseAgreed))
        return;
      setSaving(true);

      const authorityCode = queryParams[AUTHORITY_CODE] || cachedInitialAuthorityCode;

      if (!authorities.find(auth => auth.authorityDef?.authorityDefCode === AuthorityDefCodeEnum.VISITOR)) {
        setErrorMessage(AUTHORITY_ERROR_MSG);
        return;
      }

      if (email) {
        const request = builder<SaveFirebaseRequest>().email(email).password(passwordToSave).build();
        // const { data: response } = await getAuth().saveFirebase(request);
        const response = await commonRequest<SaveFirebaseRequest, SaveFirebaseResponse>(SAVE_FIREBASE, request);
        const customToken = response.customToken;
        const { user: firebaseUser } = await AuthService.signInWithCustomToken(customToken!);
        !!firebaseUser && (await firebaseUser.updateEmail(email).catch(noop));
      }

      const [firebaseEmail, providers, faceRecognitionPhotoURL] = await Promise.all([
        getFirebaseEmail(),
        getProviders(base),
        isFacePhotoRequired && facePhoto ? ImageService.putImageFileToStorage(facePhoto) : EMPTY
      ]);

      const userRequest = builder<SaveUserBody>()
        .userId(kpUser?.userId || EMPTY)
        .email(email || kpUser?.email || firebaseEmail)
        .isActivated(true)
        .startDate(toBeginningOfDay(startDateToSave)!)
        .endDate(toEndOfDay(endDateToSave)!)
        .familyName(familyNameToSave)
        .firstName(firstNameToSave)
        .gender(genderToSave)
        .campanyName(companyNameToSave)
        .schoolName(schoolNameToSave)
        .schoolDepartmentName(schoolDepartmentNameToSave)
        .dateOfBirthV2(format(new Date(dateOfBirthV2ToSave!), 'yyyy-MM-dd'))
        .phone(phoneToSave)
        .photoURL(kpUser?.photoURL || EMPTY) // メアド認証の場合nullのままなので
        .faceRecognitionPhotoURL(faceRecognitionPhotoURL)
        .faceFeatureStr(isFacePhotoRequired ? faceFeatureStrToSave : EMPTY)
        .prefecture(prefectureToSave!)
        .city(cityToSave)
        .userDiv(userDivToSave!)
        .userDivOther(userDivOtherToSave)
        .userStudentDiv(userStudentDivToSave!)
        .userStudentDivOther(userStudentDivOtherToSave)
        .userInflowSource(userInflowSourceToSave!)
        .userInflowSourceOther(userInflowSourceOtherToSave)
        // .userUsagePurpose(userUsagePurposeToSave!)
        // .userUsagePurposeOther(userUsagePurposeOtherToSave)
        // .userFamilyMembers(userFamilyMembersToSave)
        // .userFamilyMemberOther(userFamilyMemberOtherToSave)
        .dateOfAgreement(new Date())
        .providers(providers)
        .authorityCode(authorityCode)
        .baseId(base.baseId)
        .build();

      await getBsUser().saveUser(userRequest);

      clearCachedInitialAuthorityCode();
      openMyAccountScreen();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      constructErrorMessage(err.code);

      // 調査用にエラーログ送信
      const stack = err.stack || EMPTY;
      const name = err.name || EMPTY;
      const message = err.message || EMPTY;
      const cause = err.cause || EMPTY;
      const lineNumber = err.lineNumber || EMPTY;
      const request = builder<CatchFrontendErrorRequest>()
        .stack(
          '\nエラー発生箇所: ' +
            window.location.href +
            '\n\nstack: ' +
            stack +
            '\n\nname: ' +
            name +
            '\n\nmessage: ' +
            message +
            '\n\ncause: ' +
            cause +
            '\n\nlineNumber: ' +
            lineNumber
        )
        .build();
      await CommonRequest.call<CatchFrontendErrorRequest, CatchFrontendErrorResponse>(CATCH_FRONTEND_ERROR, request);

      setSaving(false);
    }
  }, [
    setErrorMessage,
    setIsSaveNewUserButtonClicked,
    isKnotPlaceTermOfUseAgreed,
    hasAdditonalUserPolicy,
    isAdditionalTermOfUseAgreed,
    isValidateFailed,
    setSaving,
    queryParams,
    cachedInitialAuthorityCode,
    authorities,
    email,
    getFirebaseEmail,
    getProviders,
    base,
    isFacePhotoRequired,
    facePhoto,
    kpUser?.userId,
    kpUser?.email,
    kpUser?.photoURL,
    startDateToSave,
    endDateToSave,
    familyNameToSave,
    firstNameToSave,
    genderToSave,
    companyNameToSave,
    schoolNameToSave,
    schoolDepartmentNameToSave,
    dateOfBirthV2ToSave,
    phoneToSave,
    faceFeatureStrToSave,
    prefectureToSave,
    cityToSave,
    userDivToSave,
    userDivOtherToSave,
    userStudentDivToSave,
    userStudentDivOtherToSave,
    userInflowSourceToSave,
    userInflowSourceOtherToSave,
    clearCachedInitialAuthorityCode,
    openMyAccountScreen,
    setKnotPlaceAgreeErrorMessage,
    setAdditionalAgreeErrorMessage,
    passwordToSave,
    commonRequest,
    constructErrorMessage
  ]);

  const cities = useMemo<City[]>(
    () => findSpecificCities(prefectureWithCities, prefectureToSave),
    [prefectureToSave, prefectureWithCities]
  );

  const handlePrefectureChanged = useSafeCallback(
    async (prefectureId: Prefecture): Promise<void> => {
      setPrefectureToSave(prefectureId);

      setCityToSave(EMPTY);
    },
    [setPrefectureToSave, setCityToSave]
  );

  const handleCityChanged = useSafeCallback(
    async (city: City): Promise<void> => {
      setCityToSave(city);
    },
    [setCityToSave]
  );

  const handleUserDivChanged = useSafeCallback(
    (userDivId: UserDivId): void => {
      const userDiv = userDivs.find(div => div.userDivId === userDivId);
      !!userDiv && setUserDivToSave(userDiv);
      !userDiv?.userDivDef?.isStudent && setUserStudentDivToSave(undefined);
      !userDiv?.userDivDef?.isStudent && setUserStudentDivOtherToSave(EMPTY);
      !userDiv?.userDivDef?.hasCompanyName && setCompanyNameToSave(EMPTY);
      !userDiv?.userDivDef?.hasOther && setUserDivOtherToSave(EMPTY);
    },
    [
      userDivs,
      setUserDivToSave,
      setUserStudentDivToSave,
      setUserStudentDivOtherToSave,
      setCompanyNameToSave,
      setUserDivOtherToSave
    ]
  );

  const handleUserStudentDivChanged = useSafeCallback(
    (userStudentDivId: UserStudentDivId): void => {
      const userStudentDiv = userStudentDivs.find(div => div.userStudentDivId === userStudentDivId);
      !!userStudentDiv && setUserStudentDivToSave(userStudentDiv);
      !userStudentDiv?.userStudentDivDef?.hasSchoolName &&
        (setSchoolNameToSave(EMPTY), setSchoolDepartmentNameToSave(EMPTY));
      !userStudentDiv?.userStudentDivDef?.hasOther && setUserStudentDivOtherToSave(EMPTY);
    },
    [
      userStudentDivs,
      setUserStudentDivToSave,
      setSchoolNameToSave,
      setSchoolDepartmentNameToSave,
      setUserStudentDivOtherToSave
    ]
  );

  const handleUserInflowSourceChanged = useSafeCallback(
    (userInflowSourceId: UserInflowSourceId): void => {
      const userInflowSource = userInflowSources.find(src => src.userInflowSourceId === userInflowSourceId);
      !!userInflowSource && setUserInflowSourceToSave(userInflowSource);
      !userInflowSource?.userInflowSourceDef?.hasOther && setUserInflowSourceOtherToSave(EMPTY);
    },
    [userInflowSources, setUserInflowSourceToSave, setUserInflowSourceOtherToSave]
  );

  // const handleUserUsagePurposeChanged = useSafeCallback(
  //   (userUsagePurposeId: UserUsagePurposeId): void => {
  //     const userUsagePurpose = userUsagePurposes.find(purpose => purpose.userUsagePurposeId === userUsagePurposeId);
  //     !!userUsagePurpose && setUserUsagePurposeToSave(userUsagePurpose);
  //     !userUsagePurpose && setUserUsagePurposeOtherToSave(EMPTY);
  //   },
  //   [userUsagePurposes, setUserUsagePurposeToSave, setUserUsagePurposeOtherToSave]
  // );

  // const handleUserFamilyMemberChanged = useSafeCallback(
  //   (options: MultiCheckBoxOption[]): void => {
  //     const members: UserFamilyMember[] = options
  //       .filter(option => option.checked)
  //       .map(option => userFamilyMembers.find(member => member.userFamilyMemberId === option.id)!);
  //     setUserFamilyMembersToSave(members);
  //     !isUserFamilyMemberOtherShown && setUserFamilyMemberOtherToSave(EMPTY);
  //   },
  //   [userFamilyMembers, setUserFamilyMembersToSave, isUserFamilyMemberOtherShown, setUserFamilyMemberOtherToSave]
  // );

  const handleTermOfUseAgreedChanged = useSafeCallback((): void => {
    setIsKnotPlaceTermOfUseAgreed(!isKnotPlaceTermOfUseAgreed);
    setKnotPlaceAgreeErrorMessage(EMPTY);
  }, [setIsKnotPlaceTermOfUseAgreed, isKnotPlaceTermOfUseAgreed, setKnotPlaceAgreeErrorMessage]);
  const handleAdditionalTermOfUseAgreedChanged = useSafeCallback((): void => {
    setIsAdditionalTermOfUseAgreed(!isAdditionalTermOfUseAgreed);
    setAdditionalAgreeErrorMessage(EMPTY);
  }, [setIsAdditionalTermOfUseAgreed, isAdditionalTermOfUseAgreed, setAdditionalAgreeErrorMessage]);

  const isMobile = useMobile();

  useEffect(() => {
    if (!userLoaded || email) return;
    if (!kpUser) {
      openBasePath(Path.SIGN_IN);
      return;
    }
    if (kpUser.isActivated) {
      openBasePath(Path.ACCOUNT_HOME);
    }
  }, [email, kpUser, openBasePath, userLoaded]);

  return (
    <Screen loading={!loaded} className='register-account-screen'>
      <Scrollable>
        <Container data-testid='register-account-screen-v3'>
          <HeaderV2 logo={logo_knot_place} showIcon={false} zIndex={1} />
          <Content isMobile={isMobile}>
            <MojacoWrapper>
              <CommentBox animation photoURL={mojaco_greeting}>
                <MojacoGreeting>
                  はじめまして！お得な情報をお送りするので、まずはアカウント登録をよろしくね！登録自体は1分で終わるよ！
                </MojacoGreeting>
              </CommentBox>
            </MojacoWrapper>
            <FormWrapper>
              <NameWrapper>
                <PartialNameWrapper data-testid='input-field-family-name'>
                  <FormGroupText
                    required
                    maxLength={15}
                    title='姓'
                    value={familyNameToSave ?? EMPTY}
                    onChange={setFamilyNameToSave}
                    errorMessage={errorMessageFamilyName}
                  ></FormGroupText>
                </PartialNameWrapper>

                <PartialNameWrapper data-testid='input-field-first-name'>
                  <FormGroupText
                    required
                    maxLength={15}
                    title='名'
                    value={firstNameToSave ?? EMPTY}
                    onChange={setFirstNameToSave}
                    errorMessage={errorMessageFirstName}
                  ></FormGroupText>
                </PartialNameWrapper>
              </NameWrapper>
              <RadioWrapper data-testid='input-field-gender'>
                <FormGroupRadio
                  required
                  title='性別'
                  options={Object.values(Gender)}
                  labels={USER_GENDER_LABELS}
                  value={genderToSave}
                  onChange={setGenderToSave}
                  errorMessage={errorMessageGender}
                />
              </RadioWrapper>
              {!!email && (
                <InputWrapper>
                  <FormGroupText
                    required
                    maxLength={12}
                    title={`パスワード`}
                    inputType='password'
                    value={passwordToSave ?? EMPTY}
                    onChange={setPasswordToSave}
                    errorMessage={errorMessagePassword}
                  />
                </InputWrapper>
              )}
              <PhoneNumberWrapper data-testid='input-field-tel'>
                <FormGroupText
                  required
                  maxLength={15}
                  inputType='number'
                  title='電話番号'
                  value={phoneToSave ?? EMPTY}
                  onChange={setPhoneToSave}
                  errorMessage={errorMessagePhone}
                />
              </PhoneNumberWrapper>
              <DateWrapper data-testid='input-field-date-of-birth'>
                <FormGroupDate
                  required
                  title='生年月日'
                  placeholder='1990-01-01'
                  value={dateOfBirthV2ToSave!}
                  onChange={setBirthDateToSave}
                  errorMessage={errorMessageDateOfBirth}
                />
              </DateWrapper>
              <PrefCityWrapper isMobile={isMobile}>
                <PrefCityInputWrapper shrinkRight={!isMobile} data-testid='input-pull-down-pref'>
                  <FormGroupSelect
                    required
                    title='お住まいの都道府県'
                    placeholder='-- 選択してください --'
                    options={Object.values(Prefecture)}
                    labels={PREFECTURE_NAMES}
                    value={prefectureToSave || false}
                    onChange={handlePrefectureChanged}
                    errorMessage={errorMessagePrefecture}
                  />
                </PrefCityInputWrapper>

                <PrefCityInputWrapper shrinkLeft={!isMobile} data-testid='input-pull-down-city'>
                  <FormGroupSelect
                    required
                    title='市区町村'
                    readonly={!prefectureToSave}
                    placeholder={prefectureToSave ? '-- 選択してください --' : '-- 都道府県を選択してください --'}
                    options={cities}
                    labels={toCityLabels(cities)}
                    value={cityToSave || false}
                    onChange={handleCityChanged}
                    errorMessage={errorMessageCity}
                  />
                </PrefCityInputWrapper>
              </PrefCityWrapper>
              {hasLength(userDivs) && (
                <RadioWrapper data-testid='radio-user-div'>
                  <FormGroupRadio
                    required
                    title='職業'
                    options={toUserDivOptions(userDivs)}
                    labels={toUserDivLabels(userDivs)}
                    value={userDivToSave?.userDivId ?? EMPTY}
                    onChange={handleUserDivChanged}
                    errorMessage={errorMessageUserDiv}
                  />
                </RadioWrapper>
              )}
              {isCompanyNameShown && (
                <InputWrapper>
                  <FormGroupText
                    required
                    maxLength={255}
                    title='お勤め先'
                    value={companyNameToSave ?? EMPTY}
                    onChange={setCompanyNameToSave}
                    errorMessage={errorMessageCompanyName}
                  />
                </InputWrapper>
              )}
              {isUserDivOtherShown && (
                <InputWrapper>
                  <FormGroupText
                    required
                    maxLength={255}
                    title='職業：「その他」の内容'
                    value={userDivOtherToSave ?? EMPTY}
                    onChange={setUserDivOtherToSave}
                    errorMessage={errorMessageUserDivOtherToSave}
                  />
                </InputWrapper>
              )}
              {isUserStudentDivShown && (
                <RadioWrapper>
                  <FormGroupRadio
                    required
                    title='学年'
                    options={toUserStudentDivOptions(userStudentDivs)}
                    labels={toUserStudentDivLabels(userStudentDivs)}
                    value={userStudentDivToSave?.userStudentDivId ?? EMPTY}
                    onChange={handleUserStudentDivChanged}
                    errorMessage={errorMessageUserStudentDivToSave}
                  />
                </RadioWrapper>
              )}
              {userStudentDivToSave?.userStudentDivDef?.hasSchoolName && (
                <div>
                  <InputWrapper>
                    <FormGroupText
                      required={userStudentDivToSave?.userStudentDivDef?.hasSchoolName}
                      maxLength={255}
                      title='学校'
                      value={schoolNameToSave ?? EMPTY}
                      onChange={setSchoolNameToSave}
                      errorMessage={errorMessageSchoolNameToSave}
                    />
                  </InputWrapper>
                  <InputWrapper>
                    <FormGroupText
                      maxLength={255}
                      title='学部'
                      value={schoolDepartmentNameToSave ?? EMPTY}
                      onChange={setSchoolDepartmentNameToSave}
                    />
                  </InputWrapper>
                </div>
              )}
              {isUserStudentDivOtherShown && (
                <InputWrapper>
                  <FormGroupText
                    required
                    title='学年：「その他」の内容'
                    value={userStudentDivOtherToSave ?? EMPTY}
                    onChange={setUserStudentDivOtherToSave}
                    errorMessage={errorUserStudentDivOtherToSave}
                  />
                </InputWrapper>
              )}
              {hasLength(userInflowSources) && (
                <RadioWrapper>
                  <FormGroupRadio
                    required
                    title='ご利用のきっかけ'
                    options={toInflowSourceOptions(userInflowSources)}
                    labels={toInflowSourceLabels(userInflowSources)}
                    value={userInflowSourceToSave?.userInflowSourceId ?? EMPTY}
                    onChange={handleUserInflowSourceChanged}
                    errorMessage={errorMessageUserInflowSource}
                  />
                </RadioWrapper>
              )}
              {isUserInflowSourceOtherShown && (
                <InputWrapper data-testid='radio-user-inflow'>
                  <FormGroupText
                    required
                    maxLength={255}
                    title='ご利用のきっかけ：「その他」の内容'
                    value={userInflowSourceOtherToSave ?? EMPTY}
                    onChange={setUserInflowSourceOtherToSave}
                    errorMessage={errorMessageUserInflowSourceOtherToSave}
                  />
                </InputWrapper>
              )}
              {/* {hasLength(userUsagePurposes) && (
              <RadioWrapper>
                <RadioBoxV2
                  withBorder
                  gap={themeV2.mixins.v2.spacing}
                  placeholder='ご利用の目的'
                  options={toUsagePurposeOptions(userUsagePurposes)}
                  labels={toUsagePurposeLabels(userUsagePurposes)}
                  value={userUsagePurposeToSave?.userUsagePurposeId}
                  onChange={handleUserUsagePurposeChanged}
                />
              </RadioWrapper>
            )} */}
              {/* {isUserUsagePurposeOtherShown && (
              <InputWrapper>
                <InputBox
                  type='text'
                  label='「その他」の内容を具体的に記述してください'
                  text={userUsagePurposeOtherToSave}
                  onChange={setUserUsagePurposeOtherToSave}
                />
              </InputWrapper>
            )} */}
              {/* <RadioWrapper>
              <MultiCheckBoxV2
                title='同居家族'
                options={toFamilyMemberOptions(userFamilyMembers, userFamilyMembersToSave)}
                onChange={handleUserFamilyMemberChanged}
              />
            </RadioWrapper> */}
              {/* {isUserFamilyMemberOtherShown && (
              <InputWrapper>
                <InputBox
                  type='text'
                  label='「その他」の内容を具体的に記述してください'
                  text={userFamilyMemberOtherToSave}
                  onChange={setUserFamilyMemberOtherToSave}
                />
              </InputWrapper>
            )} */}
              {isFacePhotoRequired && (
                <InputWrapper>
                  <ImageInputBox
                    required
                    hasError={hasFaceFeatureError}
                    title='顔認証用の写真をアップロード'
                    message={faceFeatureMessage}
                    onChange={handleFacePhotoChanged}
                    isMobile={isMobile}
                  />
                </InputWrapper>
              )}
            </FormWrapper>
            <WrapperAgreement>
              <Agreement data-testid='input-checkbox-user-policy-knotplace'>
                <UserPolicy
                  ownerName='knotPLACE'
                  checked={isKnotPlaceTermOfUseAgreed}
                  onChange={handleTermOfUseAgreedChanged}
                  handleUseOfTermClicked={() => openTermOfUse(TERMS_OF_USE_FOR_KNOT_PLACE)}
                  handlePrivacyPolicyClicked={() => openPrivacyPolicy(PRIVACY_POLICY_FOR_KNOT_PLACE)}
                  errorMessage={knotPlaceAgreeErrorMessage}
                />
              </Agreement>
              {hasAdditonalUserPolicy && (
                <Agreement data-testid='input-checkbox-user-policy-additional'>
                  <UserPolicy
                    ownerName={base.baseName}
                    checked={isAdditionalTermOfUseAgreed}
                    onChange={handleAdditionalTermOfUseAgreedChanged}
                    handleUseOfTermClicked={() => openTermOfUse(TERMS_OF_USE_FOR_KNOT_PLACE)}
                    handlePrivacyPolicyClicked={() => openPrivacyPolicy(PRIVACY_POLICY_FOR_KNOT_PLACE)}
                    errorMessage={additionalAgreeErrorMessage}
                  />
                  <AnnotationMessage>
                    利用施設、施設運営元、および株式会社ATOMicaからのお知らせメールの配信にも同意されたものとみなされます。
                  </AnnotationMessage>
                </Agreement>
              )}
            </WrapperAgreement>
            {!!errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
            <ButtonWrapper>
              <Button type='primary' onClick={saveNewUser}>
                <Label>アカウント登録</Label>
              </Button>
            </ButtonWrapper>
            <BottomArea />
          </Content>
        </Container>
      </Scrollable>
      <ScreenLoaderV2 loading={saving} />
    </Screen>
  );
});

RegisterAccountScreen.displayName = 'RegisterAccountScreen';
export default RegisterAccountScreen;

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;

  @supports (height: 1dvh) {
    height: 100dvh;
  }
`;

const Content = styled.div<{ isMobile: boolean }>`
  width: 100vw;
  min-width: ${MOBILE_MIN_WIDTH}px;
  max-width: ${props => (props.isMobile ? `${MOBILE_MAX_WIDTH}px` : customBreakpoints.medium)};
  height: 100%;
  padding: 0 ${theme.mixins.spacing * 2}px;
  margin-top: ${HEADER_HEIGHT}px !important;
  z-index: 0;
`;

const MojacoWrapper = styled.div`
  padding: ${theme.mixins.spacing * 4}px 0 ${theme.mixins.spacing * 2}px;
`;

const MojacoGreeting = styled(Typography)`
  width: calc(100% - ${theme.mixins.spacing * 3}px);
  height: auto;
  color: ${theme.mixins.typography.fontColor.gray};
  font-size: ${theme.mixins.typography.fontSize.sixteen}px;
  font-weight: ${theme.mixins.typography.fontWeight.sevenHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
  margin: ${theme.mixins.spacing}px ${theme.mixins.spacing}px ${theme.mixins.spacing}px ${theme.mixins.spacing * 2}px;
  ${theme.mixins.underline};
`;

const FormWrapper = styled.div`
  background-color: rgb(255, 255, 255);
  border-radius: 6px;
  padding: ${theme.mixins.spacing * 3}px 0;
`;

const NameWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  padding: 0 ${theme.mixins.spacing * 2}px;
`;

const PartialNameWrapper = styled.div`
  width: calc(50% - ${themeV2.mixins.v2.spacing * 1.5}px);
  height: auto;
`;

const RadioWrapper = styled.div`
  width: calc(100% - ${theme.mixins.spacing * 4}px);
  height: auto;
  background: ${themeV2.mixins.v2.color.background.white};
  border-radius: 16px;
  /* padding: ${theme.mixins.spacing}px ${theme.mixins.spacing * 2}px ${theme.mixins.spacing * 2}px
    ${theme.mixins.spacing * 2}px; */
  margin: ${theme.mixins.spacing * 3}px ${theme.mixins.spacing * 2}px 0;
`;

const DateWrapper = styled.div`
  width: 100%;
  height: auto;
  padding: ${theme.mixins.spacing * 3}px ${theme.mixins.spacing * 2}px 0;
`;

const PhoneNumberWrapper = styled.div`
  width: 100%;
  height: auto;
  padding: ${theme.mixins.spacing * 3}px ${theme.mixins.spacing * 2}px 0;
`;

const InputWrapper = styled.div`
  width: 100%;
  height: auto;
  padding: ${theme.mixins.spacing * 3}px ${theme.mixins.spacing * 2}px 0;
`;

const PrefCityInputWrapper = styled.div<{ shrinkLeft?: boolean; shrinkRight?: boolean }>`
  width: 100%;
  height: auto;
  padding: ${theme.mixins.spacing * 3}px ${props => theme.mixins.spacing * (props.shrinkRight ? 1 : 2)}px
    ${theme.mixins.spacing}px ${props => theme.mixins.spacing * (props.shrinkLeft ? 1 : 2)}px;
`;

const PrefCityWrapper = styled.div<{ isMobile: boolean }>`
  display: ${props => (props.isMobile ? 'block' : 'flex')};
  ${props => (props.isMobile ? '' : 'flex-direction: row')};
`;

const WrapperAgreement = styled.div`
  margin: ${themeV2.mixins.v2.spacing * 5}px 0;
  display: flex;
  flex-direction: column;
  gap: ${themeV2.mixins.v2.spacing * 1.5}px;
  justify-content: flex-start;
`;

const Agreement = styled.div`
  width: auto;
  height: auto;
`;

const ErrorMessage = styled.pre`
  width: 100%;
  height: auto;
  min-height: 18px;
  color: ${theme.mixins.typography.fontColor.pink};
  font-size: ${theme.mixins.typography.fontSize.twelve}px;
  font-weight: ${theme.mixins.typography.fontWeight.fourHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
  text-align: center;
  padding: ${theme.mixins.spacing}px;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  padding: ${theme.mixins.spacing}px ${theme.mixins.spacing * 2}px ${theme.mixins.spacing * 4}px;
`;

const Label = styled(Typography)`
  padding: 0px ${theme.mixins.spacing * 6}px;
`;

const BottomArea = styled.div`
  width: 100%;
  height: 120px;
`;

const AnnotationMessage = styled.div`
  ${themeV3.mixins.v3.typography.body.medium};
  margin-top: ${themeV3.mixins.v3.spacing * 3}px;
  color: ${themeV3.mixins.v3.color.object.gray};
`;
