import {
  ButtonV2,
  Component,
  LabelV2,
  PageHeaderV2,
  StatusV2,
  SwitchV2,
  themeV2,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import {
  BaseDto,
  BaseFunctionToggleCode,
  DELETE_EVENT_SCHEDULE_PARTICIPANT_V2,
  DOMAINS,
  DeleteEventScheduleParticipantV2Request,
  DeleteEventScheduleParticipantV2Response,
  EVENT_SCHEDULE_ID_V2,
  EventHoldingMethod,
  EventMembership,
  EventScheduleIdV2,
  EventScheduleParticipantId,
  EventScheduleParticipantV2,
  EventScheduleV2,
  EventV2,
  HTTPS,
  ParticipatingMembershipTypeEnum,
  Questionnaire,
  QuestionnaireId,
  QuestionnaireStatus,
  SAVE_EVENT_SCHEDULE_PARTICIPANTS_V2,
  SaveEventScheduleParticipantsV2Request,
  SaveEventScheduleParticipantsV2Response,
  Space,
  User,
  isBaseFunctionToggleEnabled
} from '@atomica.co/irori';
import { Count, Gap, Index, Label, URL, Width } from '@atomica.co/types';
import {
  EMPTY,
  SLASH,
  ZERO,
  builder,
  embedIdInPath,
  isNull,
  isTextOverflowing,
  isUndefined,
  isZero,
  toDatetimeFromRelativeFormat,
  toFormattedDateTimeDurationStr,
  uuid
} from '@atomica.co/utils';
import { Button } from '@material-ui/core';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { format, isAfter, isBefore } from 'date-fns';
import { ja } from 'date-fns/locale';
import React, { useEffect, useMemo, useRef } from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import mojaco from '../../../../assets/mojaco/mojaco_break.png';
import env from '../../../../env/env';
import useCachedURL from '../../../../redux/hooks/useCachedURL';
import usePath from '../../../../redux/hooks/usePath';
import useQuestionnaire from '../../../../redux/hooks/useQuestionnaire';
import CommonRequest from '../../../../requests/common-request';
import { PATH_IDS, Path } from '../../../../router/Routes';
import { EVENT_HOLDING_METHOD_LABELS } from '../../../../texts/event-text';
import { toZonedTimeStr } from '../../../../utils/date-util';
import { ParticipantsAndCountForDisplay } from '../AccountEventDetailScreen';

type PreOrPost = 'pre' | 'post';

const getQuestionnairePrefix = (preOrPost: PreOrPost): Label => {
  switch (preOrPost) {
    case 'pre':
      return '事前';
    case 'post':
      return '事後';
  }
};

interface P {
  base: BaseDto;
  user: User;
  spaces: Space[];
  event: EventV2;
  eventSchedule: EventScheduleV2;
  isValidMembership: boolean;
  isWithinCapacity: boolean;
  isAvailablePoint: boolean;
  appliedParticipantsAndCount: ParticipantsAndCountForDisplay;
  interestedParticipantsAndCount: ParticipantsAndCountForDisplay;
  setWidth: (width: Width) => void;
}

const getButtonLabelForHoldingMethod = (holdingMethod: EventHoldingMethod): Label => {
  switch (holdingMethod) {
    case EventHoldingMethod.ONLINE:
      return 'リンクを開く';
    // NOTE: マップが正常に表示されないことがあるため、コメント
    // case EventHoldingMethod.OFFLINE:
    //   return 'マップを開く';
    default:
      return EMPTY;
  }
};

const getQuestionnaireId = (questionnaireURL: URL): QuestionnaireId => {
  return questionnaireURL.split(SLASH).find((_, index, obj) => index === obj.indexOf('questionnaire') + 1) ?? EMPTY;
};

const GOOGLE_MAP_URL = 'https://www.google.co.jp/maps/search/';

const AccountEventDetail: React.FC<P> = React.memo(props => {
  const {
    base,
    user,
    spaces,
    event,
    eventSchedule,
    isValidMembership,
    isWithinCapacity,
    isAvailablePoint,
    appliedParticipantsAndCount,
    interestedParticipantsAndCount,
    setWidth
  } = props;
  const { participants: appliedParticipants } = appliedParticipantsAndCount;
  const { participants: interestedParticipants } = interestedParticipantsAndCount;

  const { params, openPath } = usePath();
  const { saveCurrentURL } = useCachedURL();
  const { fetchQuestionnaire } = useQuestionnaire();

  const unmountRef = useUnmountRef();
  const [isLongScheduleDescription, setIsLongScheduleDescription] = useSafeState<boolean>(unmountRef, false);
  const [isLongEventDescription, setIsLongEventDescription] = useSafeState<boolean>(unmountRef, false);
  const [isAllScheduleDescriptionShown, setIsAllScheduleDescriptionShown] = useSafeState<boolean>(unmountRef, false);
  const [isAllEventDescriptionShown, setIsAllEventDescriptionShown] = useSafeState<boolean>(unmountRef, false);
  const [isThumbnailExisting, setIsThumbnailExisting] = useSafeState<boolean>(unmountRef, true);
  const [participantId, setParticipantId] = useSafeState<EventScheduleParticipantId>(unmountRef);
  const [isInterested, setIsInterested] = useSafeState<boolean>(unmountRef, false);
  const [isApplied, setIsApplied] = useSafeState<boolean>(unmountRef, false);
  const [isAnsweredPreQuestionnaire, setIsAnsweredPreQuestionnaire] = useSafeState<boolean>(unmountRef, false);
  const [isAnsweredPostQuestionnaire, setIsAnsweredPostQuestionnaire] = useSafeState<boolean>(unmountRef, false);
  const [isInitialized, setIsInitialized] = useSafeState<boolean>(unmountRef, false);
  const [isSaving, setIsSaving] = useSafeState<boolean>(unmountRef, false);

  const [preQuestionnaire, setPreQuestionnaire] = useSafeState<Questionnaire>(unmountRef);
  const [postQuestionnaire, setPostQuestionnaire] = useSafeState<Questionnaire>(unmountRef);

  const scheduleDescriptionRef = useRef<HTMLDivElement>(null);
  const eventDescriptionRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const eventScheduleId = useMemo<EventScheduleIdV2>(() => params[EVENT_SCHEDULE_ID_V2], [params]);

  const urlForGoogleMap = useMemo<URL>(() => {
    if (!eventSchedule) return EMPTY;
    const isBase = spaces.some(space => eventSchedule.location === space.spaceName);
    return `${GOOGLE_MAP_URL}${isBase ? `${base.address1}+${base.address2}` : eventSchedule.location}`;
  }, [base, eventSchedule, spaces]);

  const deadlineDate = useMemo<Date>(() => {
    return (
      toDatetimeFromRelativeFormat(
        eventSchedule.startAtV2,
        eventSchedule.deadlineBeforeDate ?? null,
        eventSchedule.deadlineDateUnit ?? null,
        toZonedTimeStr(eventSchedule.deadlineTime || EMPTY, base.timezone, 'HH:mm')
      ) || eventSchedule.endAtV2
    );
  }, [base, eventSchedule]);

  const isHeld = useMemo<boolean>(() => eventSchedule && eventSchedule.endAtV2 < new Date(), [eventSchedule]);
  const hasPreQuestionnaire = useMemo<boolean>(
    () => eventSchedule && !!eventSchedule.preQuestionnaireURL,
    [eventSchedule]
  );
  const hasPostQuestionnaire = useMemo<boolean>(
    () => eventSchedule && !!eventSchedule.postQuestionnaireURL,
    [eventSchedule]
  );

  const preQuestionnaireId = useMemo<QuestionnaireId>(
    () => getQuestionnaireId(eventSchedule.preQuestionnaireURL ?? EMPTY),
    [eventSchedule]
  );

  const postQuestionnaireId = useMemo<QuestionnaireId>(
    () => getQuestionnaireId(eventSchedule.postQuestionnaireURL ?? EMPTY),
    [eventSchedule]
  );

  const isAnswered = useSafeCallback(
    (questionnaire: Questionnaire | undefined): boolean => {
      return questionnaire?.questionnaireAnswers?.some(answer => answer.answeredUser?.userId === user.userId) ?? false;
    },
    [user]
  );

  const isValidQuestionnaire = useSafeCallback(
    (questionnaire: Questionnaire | undefined): boolean => {
      return (
        !!questionnaire &&
        questionnaire.status !== QuestionnaireStatus.PRIVATE &&
        (!questionnaire.answerDueAt || isAfter(questionnaire.answerDueAt, new Date()) || isAnswered(questionnaire))
      );
    },
    [isAnswered]
  );

  const hasQuestionnaire = useMemo<boolean>(
    () => isValidQuestionnaire(preQuestionnaire) || (isHeld && isValidQuestionnaire(postQuestionnaire)),
    [isHeld, isValidQuestionnaire, postQuestionnaire, preQuestionnaire]
  );

  const saveParticipantForInterest = useSafeCallback(async (): Promise<void> => {
    setIsSaving(true);
    setIsInterested(true);

    const participantToSave = builder<EventScheduleParticipantV2>()
      .eventScheduleParticipantId(uuid())
      .isInterested(true)
      .isApplied(false)
      .eventSchedule(eventSchedule)
      .user(user)
      .build();

    const request = builder<SaveEventScheduleParticipantsV2Request>()
      .eventScheduleParticipantsV2([participantToSave])
      .build();

    const response = await CommonRequest.call<
      SaveEventScheduleParticipantsV2Request,
      SaveEventScheduleParticipantsV2Response
    >(SAVE_EVENT_SCHEDULE_PARTICIPANTS_V2, request);

    if (response.eventScheduleParticipantIds) {
      setParticipantId(response.eventScheduleParticipantIds[ZERO]);
    }
    setIsSaving(false);
  }, [eventSchedule, user, setIsInterested, setIsSaving, setParticipantId]);

  const deleteParticipant = useSafeCallback(async (): Promise<void> => {
    setIsSaving(true);
    setIsInterested(false);

    const request = builder<DeleteEventScheduleParticipantV2Request>()
      .eventScheduleParticipantId(participantId!)
      .build();
    await CommonRequest.call<DeleteEventScheduleParticipantV2Request, DeleteEventScheduleParticipantV2Response>(
      DELETE_EVENT_SCHEDULE_PARTICIPANT_V2,
      request
    );

    setIsSaving(false);
  }, [participantId, setIsInterested, setIsSaving]);

  useEffect(() => {
    if (!user || !eventSchedule || !eventSchedule.participants) {
      setIsInitialized(true);
      return;
    }
    const existingParticipant = eventSchedule.participants.find(
      participant => participant.user?.userId === user.userId
    );
    if (existingParticipant) {
      setParticipantId(existingParticipant.eventScheduleParticipantId);
      setIsInterested(existingParticipant.isInterested);
      setIsApplied(existingParticipant.isApplied);
      setIsAnsweredPreQuestionnaire(existingParticipant.isAnsweredPreQuestionnaire);
      setIsAnsweredPostQuestionnaire(existingParticipant.isAnsweredPostQuestionnaire);
    }
    setIsInitialized(true);
  }, [
    eventSchedule,
    user,
    setIsAnsweredPostQuestionnaire,
    setIsAnsweredPreQuestionnaire,
    setIsApplied,
    setIsInitialized,
    setIsInterested,
    setParticipantId
  ]);

  const isHoldingMethodButtonVisible = useMemo<boolean | undefined>(() => {
    const { holdingMethod, participants } = eventSchedule;

    if (holdingMethod === EventHoldingMethod.UNDECIDED) return false;
    // NOTE: マップボタンが正常に動かないため、一時的に分割している。
    if (holdingMethod === EventHoldingMethod.OFFLINE) return false;

    return participants?.some(participant => participant.user?.userId === user.userId);
  }, [eventSchedule, user]);

  const handleHoldingMethodButtonClicked = useSafeCallback(
    (holdingMethod: EventHoldingMethod): void => {
      switch (holdingMethod) {
        case EventHoldingMethod.ONLINE:
          window.open(eventSchedule.meetingURL);
          break;
        case EventHoldingMethod.OFFLINE:
          window.open(urlForGoogleMap);
          break;
      }
    },
    [eventSchedule, urlForGoogleMap]
  );

  const openAccountParticipantsScreen = useSafeCallback((): void => {
    openPath(embedIdInPath(Path.ACCOUNT_EVENT_PARTICIPANTS, PATH_IDS, [base.baseCode, eventScheduleId]));
  }, [base, eventScheduleId, openPath]);

  const openAccountEventEntryFormScreen = useSafeCallback((): void => {
    openPath(embedIdInPath(Path.ACCOUNT_EVENT_ENTRY_FORM, PATH_IDS, [base.baseCode, eventScheduleId]));
  }, [base, eventScheduleId, openPath]);

  const isQuestionnaireURLFromAtomica = useSafeCallback(
    (questionnaireURL: URL): boolean => {
      const questionnaireAnswerAbsUrl = `${HTTPS}://${DOMAINS[env]}${embedIdInPath(
        Path.QUESTIONNAIRE_ANSWER,
        PATH_IDS,
        [base.baseCode, getQuestionnaireId(questionnaireURL)]
      )}`;
      return questionnaireURL === questionnaireAnswerAbsUrl;
    },
    [base]
  );

  const createParticipantToSave = useSafeCallback((): EventScheduleParticipantV2 | undefined => {
    if (isHeld) {
      return builder<EventScheduleParticipantV2>()
        .eventScheduleParticipantId(participantId!)
        .isInterested(isInterested)
        .isApplied(isApplied)
        .isAnsweredPostQuestionnaire(!isQuestionnaireURLFromAtomica(eventSchedule.postQuestionnaireURL ?? EMPTY))
        .eventSchedule(eventSchedule)
        .user(user)
        .build();
    }
    return builder<EventScheduleParticipantV2>()
      .eventScheduleParticipantId(participantId!)
      .isInterested(isInterested)
      .isApplied(isApplied)
      .isAnsweredPreQuestionnaire(!isQuestionnaireURLFromAtomica(eventSchedule.preQuestionnaireURL ?? EMPTY))
      .eventSchedule(eventSchedule)
      .user(user)
      .build();
  }, [participantId, isHeld, isInterested, isApplied, isQuestionnaireURLFromAtomica, eventSchedule, user]);

  const saveParticipantForAnswer = useSafeCallback(async (): Promise<void> => {
    const participantToSave = createParticipantToSave();

    const request = builder<SaveEventScheduleParticipantsV2Request>()
      .eventScheduleParticipantsV2([participantToSave!])
      .build();

    const response = await CommonRequest.call<
      SaveEventScheduleParticipantsV2Request,
      SaveEventScheduleParticipantsV2Response
    >(SAVE_EVENT_SCHEDULE_PARTICIPANTS_V2, request);

    if (response.eventScheduleParticipantIds) {
      if (isHeld)
        setIsAnsweredPostQuestionnaire(!isQuestionnaireURLFromAtomica(eventSchedule.postQuestionnaireURL ?? EMPTY));
      if (!isHeld)
        setIsAnsweredPreQuestionnaire(!isQuestionnaireURLFromAtomica(eventSchedule.preQuestionnaireURL ?? EMPTY));
    }
  }, [
    eventSchedule,
    isHeld,
    user,
    isAnsweredPostQuestionnaire,
    isAnsweredPreQuestionnaire,
    createParticipantToSave,
    setIsAnsweredPostQuestionnaire,
    setIsAnsweredPreQuestionnaire,
    setIsInterested,
    setParticipantId
  ]);

  const openQuestionnaire = useSafeCallback(async (): Promise<void> => {
    const url = isHeld ? eventSchedule.postQuestionnaireURL : eventSchedule.preQuestionnaireURL;
    window.open(url);
    await saveParticipantForAnswer();
  }, [eventSchedule, isHeld, saveParticipantForAnswer]);

  const handleQuestionnaireClicked = useSafeCallback(
    (preOrPost: PreOrPost): void => {
      saveCurrentURL();
      switch (preOrPost) {
        case 'pre':
          window.open(eventSchedule.preQuestionnaireURL);
          break;
        case 'post':
          window.open(eventSchedule.postQuestionnaireURL);
          break;
      }
    },
    [eventSchedule, saveCurrentURL]
  );

  useEffect(() => {
    if (containerRef.current) setWidth(containerRef.current.clientWidth);
  }, [containerRef, setWidth]);

  useEffect(() => {
    setIsLongScheduleDescription(isTextOverflowing(scheduleDescriptionRef.current));
    setIsLongEventDescription(isTextOverflowing(eventDescriptionRef.current));
  }, [setIsLongScheduleDescription, setIsLongEventDescription]);

  const initialize = useSafeCallback(async (): Promise<void> => {
    const [preQuestionnaire, postQuestionnaire] = await Promise.all([
      fetchQuestionnaire(preQuestionnaireId, user.userId),
      fetchQuestionnaire(postQuestionnaireId, user.userId)
    ]);
    if (preQuestionnaire) setPreQuestionnaire(preQuestionnaire);
    if (postQuestionnaire) setPostQuestionnaire(postQuestionnaire);
  }, [fetchQuestionnaire, postQuestionnaireId, preQuestionnaireId, setPostQuestionnaire, setPreQuestionnaire]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  const ParticipantIcons = useSafeCallback(
    (participantsAndCount: ParticipantsAndCountForDisplay, label: Label): JSX.Element => {
      const { participants, filteredParticipants, numberOfHiddenParticipants } = participantsAndCount;
      return (
        <ParticipantsWrapper>
          <CardRow>
            <LabelWrapper>
              <LabelV2 text={label} fontSize='large' />
            </LabelWrapper>
            <Text>{participants.length}名</Text>
          </CardRow>
          <CardRow>
            <IconWrapper ref={containerRef}>
              {filteredParticipants.map((participant: EventScheduleParticipantV2, index: Index) => (
                <Icon key={`icon-${index}`} src={participant.user?.photoURL || mojaco} />
              ))}
            </IconWrapper>
            {!isZero(numberOfHiddenParticipants) && (
              <NumberOfHiddenParticipants>+{numberOfHiddenParticipants}名</NumberOfHiddenParticipants>
            )}
          </CardRow>
        </ParticipantsWrapper>
      );
    },
    [containerRef]
  );

  const QuestionnaireCard = useSafeCallback(
    (questionnaire: Questionnaire | undefined, preOrPost: PreOrPost): JSX.Element => {
      if (!isValidQuestionnaire(questionnaire)) return <></>;
      return (
        <Wrapper>
          <LabelV2 text={getQuestionnairePrefix(preOrPost) + 'アンケート'} />
          <QuestionnaireWrapper onClick={() => handleQuestionnaireClicked(preOrPost)}>
            <StatusV2
              status={isAnswered(questionnaire) ? 'success' : 'warning'}
              label={isAnswered(questionnaire) ? '回答済み' : '未回答'}
            />
            <Wrapper>
              <LargeText>{questionnaire?.name ?? EMPTY}</LargeText>
              {questionnaire?.answerDueAt && (
                <GrayText>回答期限: {format(questionnaire.answerDueAt, 'yyyy/MM/dd (EE)', { locale: ja })}</GrayText>
              )}
            </Wrapper>
          </QuestionnaireWrapper>
        </Wrapper>
      );
    },
    [handleQuestionnaireClicked, isAnswered, isValidQuestionnaire]
  );

  return (
    <Component style={styleForComponent} className='account-event-details'>
      <Container>
        <Contents>
          <Content>
            <ThumbnailWrapper>
              <Thumbnail
                src={isThumbnailExisting && eventSchedule.thumbnailURL ? eventSchedule.thumbnailURL : mojaco}
                onError={() => setIsThumbnailExisting(false)}
              />
            </ThumbnailWrapper>
            <TextWrapper>
              <HeaderWrapper>
                <PageHeaderV2 title={event.name} />
                {eventSchedule.name}
              </HeaderWrapper>
              <DescriptionWrapper>
                <Description ref={scheduleDescriptionRef} open={isAllScheduleDescriptionShown} rows={3}>
                  {eventSchedule.description}
                </Description>
                {isLongScheduleDescription && (
                  <DisplayButton onClick={() => setIsAllScheduleDescriptionShown(!isAllScheduleDescriptionShown)}>
                    {isAllScheduleDescriptionShown ? '閉じる' : 'すべて表示'}
                  </DisplayButton>
                )}
              </DescriptionWrapper>
            </TextWrapper>
          </Content>
          <CardWrapper>
            <CardRow>
              <LabelWrapper>
                <LabelV2 text='日時' fontSize='large' />
              </LabelWrapper>
              <Text>
                {toFormattedDateTimeDurationStr(
                  eventSchedule.startAtV2,
                  eventSchedule.endAtV2,
                  'yyyy/MM/dd(E) HH:mm',
                  '~'
                )}
              </Text>
            </CardRow>
            <Border />
            <CardRow>
              <LabelWrapper>
                <LabelV2 text='申込期限' fontSize='large' />
              </LabelWrapper>
              <Text>{format(deadlineDate, 'yyyy/MM/dd(EE) HH:mm', { locale: ja })}</Text>
            </CardRow>
            <Border />
            {isBaseFunctionToggleEnabled(base, BaseFunctionToggleCode.FUNCTION_USER_POINT) && (
              <>
                <CardRow>
                  <LabelWrapper>
                    <LabelV2 text='ポイント' fontSize='large' />
                  </LabelWrapper>
                  <Text>
                    {isZero(eventSchedule.points) ? '参加無料' : `${eventSchedule.points.toLocaleString()} ポイント`}
                  </Text>
                </CardRow>
                <Border />
              </>
            )}
            <CardRow>
              <LabelWrapper>
                <LabelV2 text='定員' fontSize='large' />
              </LabelWrapper>
              <Text>
                {isNull(eventSchedule.capacity) || isUndefined(eventSchedule.capacity)
                  ? '定員なし'
                  : `${eventSchedule.capacity}名`}
              </Text>
            </CardRow>
            <Border />
            <CardRow>
              <LabelWrapper>
                <LabelV2 text='開催方法' fontSize='large' />
              </LabelWrapper>
              <HoldingMethodWrapper>
                <Text>{EVENT_HOLDING_METHOD_LABELS[eventSchedule.holdingMethod]}</Text>
                {eventSchedule.holdingMethod === EventHoldingMethod.OFFLINE && <Text>{eventSchedule.location}</Text>}
                {isHoldingMethodButtonVisible && (
                  <ButtonV2
                    type='secondary'
                    label={getButtonLabelForHoldingMethod(eventSchedule.holdingMethod)}
                    onClick={() => handleHoldingMethodButtonClicked(eventSchedule.holdingMethod)}
                  />
                )}
              </HoldingMethodWrapper>
            </CardRow>
          </CardWrapper>
          {event.description && (
            <CardWrapper>
              <DescriptionWrapper>
                <LabelV2 text='イベント詳細' fontSize='large' />
                <Description ref={eventDescriptionRef} open={isAllEventDescriptionShown} rows={6}>
                  {event.description}
                </Description>
                {isLongEventDescription && (
                  <DisplayButton onClick={() => setIsAllEventDescriptionShown(!isAllEventDescriptionShown)}>
                    {isAllEventDescriptionShown ? '閉じる' : 'すべて表示'}
                  </DisplayButton>
                )}
              </DescriptionWrapper>
            </CardWrapper>
          )}
          <CardWrapper>
            {appliedParticipants && ParticipantIcons(appliedParticipantsAndCount, '参加者')}
            {interestedParticipants && ParticipantIcons(interestedParticipantsAndCount, '興味あり')}
            <ButtonV2 width={143} type='tertiary' label='参加者一覧へ' onClick={openAccountParticipantsScreen} />
          </CardWrapper>
          {event.participatingMembershipType === ParticipatingMembershipTypeEnum.LIMITED && (
            <CardWrapper>
              <LabelV2 text='このイベントに参加可能な会員種別' fontSize='large' />
              <MembershipWrapper>
                {event.eventMemberships?.map((eventMembership: EventMembership, index: Index) => (
                  <div key={`event-membership-${index}`}>・{eventMembership.authority!.authorityName}</div>
                ))}
              </MembershipWrapper>
            </CardWrapper>
          )}
          {hasQuestionnaire && (
            <CardWrapper>
              <Wrapper gap={16}>
                <LabelV2 text='アンケート' fontSize='large' />
                {QuestionnaireCard(preQuestionnaire, 'pre')}
                {isHeld && QuestionnaireCard(postQuestionnaire, 'post')}
              </Wrapper>
            </CardWrapper>
          )}
        </Contents>

        {isApplied && (
          <>
            {!isHeld && hasPreQuestionnaire && (
              <Footer>
                <StyledFooterButton
                  type={isAnswered(preQuestionnaire) ? 'secondary' : 'primary'}
                  label={`事前アンケートに${isAnswered(preQuestionnaire) ? '再回答' : '回答'}`}
                  onClick={openQuestionnaire}
                />
              </Footer>
            )}
            {isHeld && hasPostQuestionnaire && (
              <Footer>
                <StyledFooterButton
                  type={isAnswered(postQuestionnaire) ? 'secondary' : 'primary'}
                  label={`事後アンケートに${isAnswered(postQuestionnaire) ? '再回答' : '回答'}`}
                  onClick={openQuestionnaire}
                />
              </Footer>
            )}
          </>
        )}
        {isBefore(new Date(), deadlineDate) &&
          !isApplied &&
          isValidMembership &&
          isWithinCapacity &&
          isInitialized &&
          isAvailablePoint && (
            <Footer>
              <StyledFooterButton
                type='primary'
                label={user ? '申し込む' : 'ログイン'}
                onClick={openAccountEventEntryFormScreen}
              />
              {user && (
                <SwitchWrapper>
                  <LabelText>興味あり</LabelText>
                  <SwitchV2
                    disabled={isSaving}
                    checked={isInterested}
                    onChange={isInterested ? deleteParticipant : saveParticipantForInterest}
                  />
                </SwitchWrapper>
              )}
            </Footer>
          )}
        {!isApplied && isValidMembership && !isWithinCapacity && (
          <Footer>
            <Text>定員に達しているため</Text>
            <Text>申し込みできません。</Text>
          </Footer>
        )}
        {!isApplied && !isValidMembership && (
          <Footer>
            <Text>対象の会員種別ではないため</Text>
            <Text>申し込みできません。</Text>
          </Footer>
        )}
        {!isApplied && isValidMembership && isWithinCapacity && !isAvailablePoint && (
          <Footer>
            <Text>所持ポイントが不足しているため</Text>
            <Text>申し込みできません。</Text>
          </Footer>
        )}
      </Container>
    </Component>
  );
});

AccountEventDetail.displayName = 'AccountEventDetail';
export default AccountEventDetail;

const styleForComponent: CSSProperties = {
  width: '100%',
  height: `100%`,
  display: 'flex',
  justifyContent: 'center'
};

const Container = styled.div`
  width: 100%;
  height: 100%;
`;

const Contents = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  padding-bottom: ${themeV2.mixins.v2.spacing * 30}px;
  gap: 8px;
  ${media.greaterThan('small')`
    gap: 24px;
  `}
`;

const ThumbnailWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  ${media.greaterThan('small')`
    width: 160px;
    min-width: 160px;
    height: 120px;
  `}
`;

const Thumbnail = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  aspect-ratio: 16 / 9;
  ${media.greaterThan('small')`
    border-radius: 12px;
  `}
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  ${media.greaterThan('small')`
    flex-direction: row;
    margin: 0 ${themeV2.mixins.v2.spacing * 2}px 0;
  `};
`;

const TextWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 0 ${themeV2.mixins.v2.spacing * 2}px 0;
  ${media.greaterThan('small')`
    gap: 8px;
    padding: 0;
  `};
`;

const Wrapper = styled.div<{ gap?: Gap }>`
  display: flex;
  flex-direction: column;
  gap: ${({ gap }) => gap ?? themeV2.mixins.v2.spacing}px;
`;

const HeaderWrapper = styled(Wrapper)``;
const HoldingMethodWrapper = styled(Wrapper)``;

const DescriptionWrapper = styled(Wrapper)`
  ${themeV2.mixins.v2.typography.body.large};
  align-items: start;
`;

const Description = styled.div<{ open: boolean; rows: Count }>`
  width: 100%;
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: pre-wrap;
  max-width: 100%;
  overflow-x: hidden;
  hyphens: auto;
  ${({ open, rows }) =>
    !open && `display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: ${rows};overflow: hidden;`};
`;

const DisplayButton = styled(Button)`
  padding: 0;
  color: ${themeV2.mixins.v2.color.background.pink};
  justify-content: start;
`;

const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${themeV2.mixins.v2.spacing * 2}px;
  background: ${themeV2.mixins.v2.color.background.white};
  border-radius: 8px;
  margin: 0 ${themeV2.mixins.v2.spacing}px 0;
`;

const CardRow = styled.div`
  width: 100%;
  display: flex;
  gap: 8px;
`;

const LabelWrapper = styled.div`
  width: 72px;
`;

const Text = styled.div`
  ${themeV2.mixins.v2.typography.body.large};
`;

const Border = styled.div`
  border-top: 1px dashed ${themeV2.mixins.v2.color.border.gray};
  margin: ${themeV2.mixins.v2.spacing * 2}px 0;
`;

const ParticipantsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 12px;
  margin-bottom: ${themeV2.mixins.v2.spacing * 2}px;
`;

const LabelText = styled.span`
  ${themeV2.mixins.v2.typography.label.large};
  color: ${themeV2.mixins.v2.color.font.gray};
`;

const IconWrapper = styled.div`
  min-width: 40px;
  width: 100%;
  display: flex;
  flex: 1;
`;

const Icon = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 20px;
  margin-right: -${themeV2.mixins.v2.spacing}px;
`;

const NumberOfHiddenParticipants = styled.div`
  display: flex;
  align-items: center;
  ${themeV2.mixins.v2.typography.title.small};
  color: ${themeV2.mixins.v2.color.font.gray};
  margin-left: ${themeV2.mixins.v2.spacing}px;
`;

const LargeText = styled.div`
  ${themeV2.mixins.v2.typography.body.large};
  color: ${themeV2.mixins.v2.color.font.black};
`;

const MembershipWrapper = styled(LargeText)`
  margin-top: ${themeV2.mixins.v2.spacing}px;
`;

const QuestionnaireWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${themeV2.mixins.v2.spacing * 2}px;
  background: ${themeV2.mixins.v2.color.background.offWhite};
  border-radius: 8px;
  padding: ${themeV2.mixins.v2.spacing * 2}px;
  cursor: pointer;
`;

const GrayText = styled.div`
  ${themeV2.mixins.v2.typography.body.medium};
  color: ${themeV2.mixins.v2.color.font.gray};
`;

const Footer = styled.div`
  width: 100%;
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  left: 0;
  bottom: 0;
  padding: ${themeV2.mixins.v2.spacing * 2}px;
  background: ${themeV2.mixins.v2.color.background.white};
  gap: 8px;
`;

const StyledFooterButton = styled(ButtonV2).attrs(() => ({ size: 'large', isFullWidth: true }))`
  ${media.greaterThan('small')`
      min-width:220px !important;
      width: max-content !important;
  `};
`;

const SwitchWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;
