import {
  ButtonV2,
  CardWithLabelV2,
  Component,
  customMedia,
  InformationPanel,
  InputDateWithLabelV2,
  InputWithLabelV2,
  PageHeaderV2,
  PullDownOption,
  PullDownV2,
  ScreenLoaderV2,
  styleForFullExpansion,
  themeV2,
  themeV3,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import {
  BaseDto,
  BaseFunctionToggleCode,
  BaseResourceCategoryOption,
  BaseResourceCategoryOptionInputType,
  CHECK_SPACE_RESERVATION_LIMIT,
  CheckSpaceReservationLimitRequest,
  CheckSpaceReservationLimitResponse,
  ContractV2,
  CREATE_STRIPE_CHECKOUT_SESSION,
  CreateStripeCheckoutSessionRequest,
  CreateStripeCheckoutSessionResponse,
  DOMAINS,
  FETCH_CONTRACT_V2_BY_USER,
  FETCH_SPACE_PAYMENT_METHOD,
  FETCH_SPACE_RESERVATION,
  FETCH_SPACE_USAGES,
  FetchContractV2ByUserRequest,
  FetchContractV2ByUserResponse,
  FetchSpacePaymentMethodPayment,
  FetchSpacePaymentMethodRequest,
  FetchSpacePaymentMethodResponse,
  FetchSpaceReservationRequest,
  FetchSpaceReservationResponse,
  FetchSpaceUsagesRequest,
  FetchSpaceUsagesResponse,
  isBaseFunctionToggleEnabled,
  REFUND_SPACE_RESERVATION,
  RefundSpaceReservationRequest,
  RefundSpaceReservationResponse,
  ReservationPaymentMethod,
  SAVE_SPACE_RESERVATION,
  SAVE_TEMP_SPACE_RESERVATION,
  SaveSpaceReservationRequest,
  SaveSpaceReservationResponse,
  SaveTempSpaceReservationRequest,
  SaveTempSpaceReservationResponse,
  Space,
  SPACE_RESERVATION_ID,
  SpaceParticipant,
  SpaceParticipantDiv,
  SpaceReservation,
  SpaceReservationId,
  SpaceReservationOption,
  SpaceReservationPaymentStatus,
  SpaceReservationStatus,
  SpaceReservationType,
  SpaceUsagesForTime,
  STRIPE_PAY_RESULT,
  STRIPE_SESSION_ID,
  StripeSessionId,
  User
} from '@atomica.co/irori';
import { Count, Hour, Id, Index, Label, Message, Minute, Price, Remarks, Text, Time, Title } from '@atomica.co/types';
import {
  builder,
  calcTimeDiff,
  embedIdInPath,
  EMPTY,
  formatTime,
  hasLength,
  isEmpty,
  isGreaterThanZero,
  isLessThanZero,
  isNull,
  Language,
  MILLI_SECONDS_OF_ONE_SECOND,
  MINUS_ONE,
  ONE,
  SECONDS_OF_ONE_MINUTE,
  toBeginningOfDay,
  toFormattedDateTimeDurationStr,
  toHour,
  uuid,
  ZERO
} from '@atomica.co/utils';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import {
  addDays,
  addMinutes,
  format,
  hoursToMinutes,
  isAfter,
  isBefore,
  isEqual,
  isSameDay,
  subDays,
  subMinutes
} from 'date-fns';
import { differenceInCalendarDays } from 'date-fns/esm/fp';
import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';

import {
  CreateSpaceReservationPayment200,
  CreateSpaceReservationPaymentBodyMetadata,
  CreateSpaceReservationPaymentBodyPayItemsItem
} from '../../__generated/model';
import { getBsSpace } from '../../__generated/user/bs-space/bs-space';
import DefaultModal from '../../components/modal/DefaultModal';
import { ERROR, SUCCESS } from '../../constants/snackbar-const';
import { RESERVATION_REMARKS_LEN } from '../../constants/space-reservation-const';
import { AvailabilityTimes, getStartAndEndTimesForDay } from '../../converters/space-converter';
import env from '../../env/env';
import { useSnackbarV2 } from '../../provider/SnackbarProviderV2';
import useCachedSpaceReservation from '../../redux/hooks/useCachedSpaceReservation';
import useCachedURL from '../../redux/hooks/useCachedURL';
import usePath from '../../redux/hooks/usePath';
import useUser from '../../redux/hooks/useUser';
import CommonRequest from '../../requests/common-request';
import { Path, PATH_IDS } from '../../router/Routes';
import { SPACE_UNAVAILABLE_DATE } from '../../texts/error-message-text';
import { SPACE_RESERVE_EDITED, SPACE_RESERVE_ERROR, SPACE_RESERVE_EXTENDED } from '../../texts/snackbar-text';
import { isNotFoundIndex } from '../../utils/common-util';
import { toDateWithNewTime } from '../../utils/date-util';
import {
  getReservationStartUnit,
  isReceptionClosed,
  isReceptionOpened,
  toInputTypeFromBaseResourceCategoryOptionValidationType,
  useCreditCardPayment
} from '../../utils/space-reservation-util';
import { getSpaceAvailabilityAtOfTargetDate, SpaceAvailabilityAt } from '../../utils/space-utils';
import LoggerService from './../../services/logger-service';
import { SpaceReservationOperation } from './CompleteSpaceReservationScreen';
import SpacePriceDetail from './space-price-detail/SpacePriceDetail';
import SpaceReservationPayment from './space-reservation-payment/SpaceReservationPaymentMethod';

interface P {
  base: BaseDto;
  user: User | undefined;
}

const toFormattedTimes = (startAt: Date, endAt: Date): Time =>
  toFormattedDateTimeDurationStr(startAt, endAt, 'HH:mm', '-');

const getOptions = (times: Date[]): PullDownOption[] =>
  times.map((time, idx) => {
    let formattedTime = format(time, 'HH:mm');
    if (idx === times.length - 1 && formattedTime === '00:00') {
      formattedTime = '24:00';
    }
    return builder<PullDownOption>().id(idx.toString()).label(formattedTime).build();
  });

const getTimeIdx = (options: PullDownOption[], date: Date | undefined): Index => {
  if (!date) return MINUS_ONE;

  // FIXME
  // 日跨ぎ設定の時に終了時刻24:00を選択すると次の日の0:00となる（予約処理としては問題なし）
  return options.findIndex(option => {
    if (!option.label) return false;
    const targetDate = option.label === '24:00' ? addDays(date, 1) : date;
    return isEqual(toDateWithNewTime(date, option.label), targetDate);
  });
};

const getPullDownOptionIdxByLabel = (options: PullDownOption[], label: Label): Index =>
  options.findIndex(option => option.label === label);

const toCategoryOptions = (space?: Space) => {
  if (!space || !space.baseResourceCategory) return [];
  return space.baseResourceCategory.baseResourceCategoryOptions?.sort((a, b) => (a.order < b.order ? -1 : 1)) ?? [];
};
const toCategoryOptionValues = (space?: Space, options?: SpaceReservationOption[]) => {
  if (!space || !space.baseResourceCategory) return [];
  const categoryOptions = toCategoryOptions(space);
  return categoryOptions.map(categoryOption => {
    return (
      options?.find(
        option =>
          option.baseResourceCategoryOption?.baseResourceCategoryOptionId ===
          categoryOption.baseResourceCategoryOptionId
      )?.value ?? EMPTY
    );
  });
};

const SpaceReservationConfirmScreen: React.FC<P> = React.memo(props => {
  const { base, user } = props;
  const { firebase } = useUser();
  const { saveCurrentURL } = useCachedURL();
  const { path, params, openBasePath, openPath } = usePath();
  const { openSnackbar } = useSnackbarV2();
  const { cachedSpaceReservation, clearCachedSpaceReservation, saveCachedSpaceReservation } =
    useCachedSpaceReservation();

  const { cachedSpace, cachedSelectedStartDate, cachedSelectedEndDate } = cachedSpaceReservation;

  const unmountRef = useUnmountRef();
  const [space, setSpace] = useSafeState<Space | undefined>(unmountRef, cachedSpace);
  const [spaceUsages, setSpaceUsages] = useSafeState<SpaceUsagesForTime>(unmountRef);

  const [selectedStartDate, setSelectedStartDate] = useSafeState<Date | undefined>(
    unmountRef,
    cachedSelectedStartDate ? new Date(cachedSelectedStartDate) : undefined
  );

  const [selectedEndDate, setSelectedEndDate] = useSafeState<Date | undefined>(
    unmountRef,
    cachedSelectedEndDate ? new Date(cachedSelectedEndDate) : undefined
  );

  const selectedDate = useMemo<Date | undefined>(() => {
    if (!selectedStartDate) return undefined;
    const date = new Date(selectedStartDate);
    return toBeginningOfDay(date);
  }, [selectedStartDate]);

  const [prevEndDate, setPrevEndDate] = useSafeState<Date | undefined>(unmountRef);
  const [isValidating, setIsValidating] = useSafeState<boolean>(unmountRef, false);
  const [remarksToSave, setRemarksToSave] = useSafeState<Remarks>(unmountRef, EMPTY);
  // TODO: 登録時のデータに詰める
  const [reservationPaymentMethod, setReservationPaymentMethod] = useSafeState<ReservationPaymentMethod>(unmountRef);

  const [spacePaymentMethodWithAvailabilities, setSpacePaymentMethodWithAvailability] = useSafeState<
    FetchSpacePaymentMethodPayment[]
  >(unmountRef, []);

  const [loading, setLoading] = useSafeState<boolean>(unmountRef, false);

  const [startPullDownOptions, setStartPullDownOptions] = useSafeState<PullDownOption[]>(unmountRef, []);
  const [endPullDownOptions, setEndPullDownOptions] = useSafeState<PullDownOption[]>(unmountRef, []);

  const [selectedStartTimeIdx, setSelectedStartTimeIdx] = useSafeState<Index | null>(unmountRef, null);
  const [selectedEndTimeIdx, setSelectedEndTimeIdx] = useSafeState<Index | null>(unmountRef, null);

  const [contract, setContract] = useSafeState<ContractV2>(unmountRef);

  const [participants, setParticipants] = useSafeState<SpaceParticipant[]>(unmountRef, []);

  const [baseResourceCategoryOptions, setBaseResourceCategoryOptions] = useSafeState<BaseResourceCategoryOption[]>(
    unmountRef,
    toCategoryOptions(cachedSpace)
  );
  const [reservationOptionValues, setReservationOptionValues] = useSafeState<Text[]>(
    unmountRef,
    toCategoryOptionValues(cachedSpace)
  );

  const isShowSpacePaymentStatus = useMemo<boolean>(() => {
    if (!contract) return true;
    return false;
  }, [contract]);

  const handleOptionValueChanged = useSafeCallback(
    (value: Text, index: Index): void => {
      setReservationOptionValues(prev => {
        prev.splice(index, 1, value);
        return prev.concat();
      });
    },
    [setReservationOptionValues]
  );

  const [isModalOpen, setIsModalOpen] = useSafeState<boolean>(unmountRef, false);

  const [isReservationLimited, setIsReservationLimited] = useSafeState<boolean>(unmountRef, true);
  const [errorMessageForLimit, setErrorMessageForLimit] = useSafeState<Message>(unmountRef, EMPTY);

  const [isInitialized, setIsInitialized] = useSafeState<boolean>(unmountRef, false);

  const currentSpaceReservationId = useMemo<SpaceReservationId>(() => params[SPACE_RESERVATION_ID], [params]);
  const [currentReservation, setCurrentReservation] = useSafeState<SpaceReservation | undefined>(unmountRef);

  const isReservationTimeExtension = useMemo<boolean>(() => path === Path.RESERVE_SPACE_EXTENSION, [path]);
  const isReservationTimeEdit = useMemo<boolean>(() => path === Path.RESERVE_SPACE_EDIT, [path]);
  const isUpdate = useMemo<boolean>(
    () => isReservationTimeEdit || isReservationTimeExtension,
    [isReservationTimeEdit, isReservationTimeExtension]
  );
  const isReservableMultipleDays = space?.isReservableMultipleDays;

  const isProvisional = useMemo<boolean>(() => {
    return (currentReservation?.status ?? space?.defaultReservationStatus) === SpaceReservationStatus.PROVISIONAL;
  }, [currentReservation, space]);

  const pageTitle = useMemo<Title>(() => {
    if (isReservationTimeExtension) return '利用時間の延長';
    if (isReservationTimeEdit) return '利用日時の変更';
    return '予約内容の確認';
  }, [isReservationTimeEdit, isReservationTimeExtension]);

  const minimumReservationIndex = useMemo<Count>(() => {
    if (!space) return 0;
    const { minimumReservation } = space;
    const reservationStartUnit = getReservationStartUnit([space]);
    return Math.floor(minimumReservation / reservationStartUnit);
  }, [space]);

  const spaceAvailabilityAt = useMemo<SpaceAvailabilityAt | undefined>(() => {
    if (!space || !hasLength(space.availabilities) || !selectedStartDate) return;
    return getSpaceAvailabilityAtOfTargetDate(selectedStartDate, base.timezone, space.availabilities);
  }, [base, selectedStartDate, space]);

  const pricePerHour = useMemo<Price>(() => {
    return space?.item?.unitPrice ?? 0;
  }, [space]);

  const pricePerHourText = useMemo<Text>(() => {
    return pricePerHour ? '¥' + pricePerHour.toLocaleString() + ' / 時間' : EMPTY;
  }, [pricePerHour]);

  const currentUsageHour = useMemo<Hour>(() => {
    if (!selectedStartDate || !selectedEndDate) return ZERO;
    return toHour(calcTimeDiff(selectedStartDate, selectedEndDate));
  }, [selectedEndDate, selectedStartDate]);

  const prevUsageHour = useMemo<Hour>(() => {
    if (!currentReservation) return ZERO;
    const { startAt, endAt } = currentReservation;
    return toHour(calcTimeDiff(startAt!, endAt!));
  }, [currentReservation]);

  const actualUsageHour = useMemo<Hour>(() => {
    return currentUsageHour - prevUsageHour;
  }, [currentUsageHour, prevUsageHour]);

  const isEnabledCreditCardPayment = useMemo<boolean>(() => {
    return useCreditCardPayment(base, contract, selectedStartDate, user);
  }, [base, contract, selectedStartDate, user]);

  const reserveReceptionStartDate = useMemo<Date>(() => {
    if (!space || space.reservationStartDayOffset < 0) return new Date();
    return addDays(new Date(), space.reservationStartDayOffset);
  }, [space]);

  const reserveReceptionEndDate = useMemo<Date | undefined>(() => {
    if (!space || space.reservationAdvanceBookingDays < 0) return;
    return addDays(
      toBeginningOfDay(new Date())!,
      space.reservationAdvanceBookingDays + (isReservableMultipleDays ? 1 : 0)
    );
  }, [isReservableMultipleDays, space]);

  const errorMessageForDate = useMemo<Message | undefined>(() => {
    if (!hasLength(startPullDownOptions) || !hasLength(endPullDownOptions)) return SPACE_UNAVAILABLE_DATE;
  }, [endPullDownOptions, startPullDownOptions]);

  const errorMessagesForTime = useMemo<Message[]>(() => {
    if (!space || !selectedStartDate || !selectedEndDate) return [];
    const messages: Message[] = [];
    const { lastStartAt, minimumReservation, maximumReservation, reservationUnit, spaceId } = space;
    const spaceLastStartAt = toDateWithNewTime(selectedStartDate, lastStartAt);
    const reservationMinutes = Math.floor(
      calcTimeDiff(selectedStartDate, selectedEndDate) / (SECONDS_OF_ONE_MINUTE * MILLI_SECONDS_OF_ONE_SECOND)
    );

    if (!hasLength(endPullDownOptions)) {
      messages.push(`選択可能な終了時間が見つかりませんでした。`);
    }

    if (isNotFoundIndex(selectedStartTimeIdx) || !hasLength(endPullDownOptions)) {
      messages.push(`開始時間 ${format(selectedStartDate, 'HH:mm')} は無効です。開始時間を再選択してください。`);
    }
    if (isNotFoundIndex(selectedEndTimeIdx)) {
      messages.push(`終了時間 ${format(selectedEndDate, 'HH:mm')} は無効です。終了時間を再選択してください。`);
    }

    if (!isReceptionOpened(space, selectedStartDate)) {
      messages.push(`開始日は ${format(reserveReceptionStartDate, 'yyyy年M月d日')} 以降を選択してください。`);
    }
    if (reserveReceptionEndDate && isReceptionClosed(space, selectedEndDate)) {
      messages.push(`終了日時は ${format(reserveReceptionEndDate, 'yyyy年M月d日 HH:mm')}以前を選択してください。`);
    }

    if (spaceUsages) {
      const duplicatedUsage = spaceUsages[spaceId].find(usage => {
        const isNewReservation = !currentReservation;
        const isDifferentReservation = usage.spaceReservationId !== currentReservation?.spaceReservationId;
        const isNewOrDifferentReservation = isNewReservation || isDifferentReservation;
        const isDuplicatedTime =
          (usage.start <= selectedStartDate && selectedStartDate < usage.end) ||
          (usage.start < selectedEndDate && selectedEndDate <= usage.end) ||
          (selectedStartDate <= usage.start && usage.end <= selectedEndDate);
        return isNewOrDifferentReservation && isDuplicatedTime;
      });
      if (duplicatedUsage) {
        const { start, end } = duplicatedUsage;
        messages.push(`${toFormattedTimes(start, end)}は予約されているため、別の時間帯を選択してください。`);
      }
    }

    if (minimumReservation > reservationMinutes) {
      messages.push(`施設の予約は${minimumReservation}分以上から可能となります。`);
    }
    if (maximumReservation >= 0 && maximumReservation < reservationMinutes) {
      messages.push(`施設の予約は最大${maximumReservation}分まで可能となります。`);
    }
    if (isGreaterThanZero(reservationMinutes) && reservationMinutes % reservationUnit !== ZERO) {
      messages.push(`施設の予約は${reservationUnit}分単位で選択してください。`);
    }
    if (space.lastStartAt) {
      if (isAfter(selectedStartDate, spaceLastStartAt)) {
        messages.push(`利用開始時刻は${formatTime(space.lastStartAt, Language.JAPANESE)}よりも前を選択してください。`);
      }
    }
    if (isReservableMultipleDays && selectedStartDate >= selectedEndDate) {
      messages.push('開始日時より古い終了日時が指定されています');
    }
    setIsValidating(false);
    return messages;
  }, [
    currentReservation,
    endPullDownOptions,
    reserveReceptionEndDate,
    reserveReceptionStartDate,
    selectedEndDate,
    selectedEndTimeIdx,
    selectedStartDate,
    selectedStartTimeIdx,
    space,
    setIsValidating,
    spaceUsages,
    isReservableMultipleDays
  ]);

  const getTimeIntervals = useSafeCallback(
    (start: Date, end: Date, intervalMinutes: Minute): Date[] => {
      const times: Date[] = [];
      const current = new Date(start.getTime());
      while (current <= end) {
        if (isAfter(current, subMinutes(new Date(), intervalMinutes)) || isReservationTimeExtension) {
          times.push(new Date(current.getTime()));
        }
        current.setMinutes(current.getMinutes() + intervalMinutes);
      }
      return times;
    },
    [isReservationTimeExtension]
  );

  const isEnabledPrimayButton = useMemo<boolean>(() => {
    return (
      !isInitialized ||
      loading ||
      isValidating ||
      !!errorMessageForDate ||
      hasLength(errorMessagesForTime) ||
      !!isReservationLimited ||
      baseResourceCategoryOptions.some((option, index) => {
        if (!option.isRequired) return false;
        return isEmpty(reservationOptionValues[index]);
      })
    );
  }, [
    baseResourceCategoryOptions,
    isInitialized,
    errorMessageForDate,
    errorMessagesForTime,
    isReservationLimited,
    isValidating,
    loading,
    reservationOptionValues
  ]);

  const getPullDownId = useSafeCallback(
    (selectedTimeIdx: Index | null) =>
      isNull(selectedTimeIdx) || isNotFoundIndex(selectedStartTimeIdx) ? EMPTY : selectedTimeIdx.toString(),
    [selectedStartTimeIdx]
  );

  const getStartPullDownOptionLabel = useSafeCallback(
    (index: Index): Label | undefined => {
      return startPullDownOptions.find(option => option.id === index.toString())?.label;
    },
    [startPullDownOptions]
  );

  const getEndPullDownOptionLabel = useSafeCallback(
    (index: Index): Label | undefined => {
      return endPullDownOptions.find(option => option.id === index.toString())?.label;
    },
    [endPullDownOptions]
  );

  const startTimeLabel = useMemo<Label | undefined>(() => {
    if (isNull(selectedStartTimeIdx)) return;
    return getStartPullDownOptionLabel(selectedStartTimeIdx);
  }, [getStartPullDownOptionLabel, selectedStartTimeIdx]);

  const endTimeLabel = useMemo<Label | undefined>(() => {
    if (isNull(selectedEndTimeIdx)) return;
    return getEndPullDownOptionLabel(selectedEndTimeIdx);
  }, [getEndPullDownOptionLabel, selectedEndTimeIdx]);

  const adjustEndDatetime = useSafeCallback(
    (date: Date): void => {
      const endTimeLabelIdx = getPullDownOptionIdxByLabel(endPullDownOptions, startTimeLabel!);
      const newEndTimeIdx = endTimeLabelIdx! + minimumReservationIndex;
      const selectedTime = getEndPullDownOptionLabel(newEndTimeIdx);
      const newEndDate = toDateWithNewTime(date, selectedTime);
      setSelectedEndTimeIdx(newEndTimeIdx);
      setSelectedEndDate(newEndDate);
    },
    [
      endPullDownOptions,
      startTimeLabel,
      minimumReservationIndex,
      getEndPullDownOptionLabel,
      setSelectedEndTimeIdx,
      setSelectedEndDate
    ]
  );

  const handleStartDateChanged = useSafeCallback(
    (date: Date): void => {
      if (!selectedStartDate || !selectedEndDate) return;
      setSelectedStartDate(toDateWithNewTime(date, startTimeLabel));
      if (isReservableMultipleDays) {
        const startTimeToCompare = toDateWithNewTime(new Date(), startTimeLabel);
        const endTimeToCompare = toDateWithNewTime(new Date(), endTimeLabel);
        if (isAfter(date, selectedEndDate) && isBefore(startTimeToCompare, endTimeToCompare))
          setSelectedEndDate(toDateWithNewTime(date, endTimeLabel));
        if (
          (isEqual(date, selectedEndDate) || isAfter(date, selectedEndDate)) &&
          (isEqual(startTimeToCompare, endTimeToCompare) || isAfter(startTimeToCompare, endTimeToCompare))
        ) {
          adjustEndDatetime(date);
        }
      }
    },
    [
      adjustEndDatetime,
      endTimeLabel,
      isReservableMultipleDays,
      selectedEndDate,
      selectedStartDate,
      setSelectedEndDate,
      setSelectedStartDate,
      startTimeLabel
    ]
  );

  const handleEndDateChanged = useSafeCallback(
    (date: Date): void => {
      if (!isReservableMultipleDays || !selectedStartDate) return;
      setSelectedEndDate(toDateWithNewTime(date, endTimeLabel));
      const startTimeToCompare = toDateWithNewTime(new Date(), startTimeLabel);
      const endTimeToCompare = toDateWithNewTime(new Date(), endTimeLabel);
      if (isBefore(date, selectedStartDate)) {
        const newStartDate = toDateWithNewTime(date, startTimeLabel);
        setSelectedStartDate(newStartDate);
        if (isBefore(startTimeToCompare, endTimeToCompare)) {
          setSelectedEndDate(toDateWithNewTime(date, endTimeLabel));
        } else {
          adjustEndDatetime(newStartDate);
        }
        return;
      }
      if (
        (isEqual(date, selectedStartDate) || isBefore(date, selectedStartDate)) &&
        (isEqual(startTimeToCompare, endTimeToCompare) || isAfter(startTimeToCompare, endTimeToCompare))
      ) {
        adjustEndDatetime(selectedStartDate);
      }
    },
    [adjustEndDatetime, endTimeLabel, isReservableMultipleDays, selectedStartDate, setSelectedEndDate, startTimeLabel]
  );

  const handleStartTimeSelected = useSafeCallback(
    (id: Id): void => {
      if (!selectedStartDate || !space) return;
      setIsValidating(true);
      const newStartTimeIdx = Number(id);
      const selectedTime = getStartPullDownOptionLabel(newStartTimeIdx);
      const newStartDate = toDateWithNewTime(selectedStartDate, selectedTime);
      setSelectedStartTimeIdx(newStartTimeIdx);
      setSelectedStartDate(newStartDate);

      const newEndDate = addMinutes(newStartDate, space.minimumReservation);
      const newEndTimeIdx = newStartTimeIdx + minimumReservationIndex;

      setSelectedEndTimeIdx(idx => (isNull(idx) || idx < newStartTimeIdx ? newEndTimeIdx : idx));
      setSelectedEndDate(endDate => (!endDate || isAfter(endDate, newEndDate) ? endDate : newEndDate));
    },
    [
      getStartPullDownOptionLabel,
      minimumReservationIndex,
      selectedStartDate,
      setIsValidating,
      setSelectedEndDate,
      setSelectedEndTimeIdx,
      setSelectedStartDate,
      setSelectedStartTimeIdx,
      space
    ]
  );

  const handleEndTimeSelected = useSafeCallback(
    async (id: Id): Promise<void> => {
      if (!selectedStartDate || !selectedEndDate) return;
      if ((!isReservableMultipleDays && !selectedStartDate) || (isReservableMultipleDays && !selectedEndDate) || !space)
        return;
      setIsValidating(true);
      const newEndTimeIdx = Number(id);
      const selectedTime = getEndPullDownOptionLabel(newEndTimeIdx);
      const newEndDate = toDateWithNewTime(
        isReservableMultipleDays ? selectedEndDate : selectedStartDate,
        selectedTime
      );
      setSelectedEndTimeIdx(newEndTimeIdx);
      setSelectedEndDate(newEndDate);

      const newStartDate = subMinutes(newEndDate, space.minimumReservation);
      const newStartTimeIdex = newEndTimeIdx - minimumReservationIndex;
      setSelectedStartTimeIdx(idx => (isNull(idx) || idx > newEndTimeIdx ? newStartTimeIdex : idx));
      setSelectedStartDate(startDate => (!startDate || isBefore(startDate, newStartDate) ? startDate : newStartDate));
    },
    [
      getEndPullDownOptionLabel,
      isReservableMultipleDays,
      minimumReservationIndex,
      selectedEndDate,
      selectedStartDate,
      setIsValidating,
      setSelectedEndDate,
      setSelectedEndTimeIdx,
      setSelectedStartDate,
      setSelectedStartTimeIdx,
      space
    ]
  );

  const createCachedSpaceReservation = useSafeCallback((): void => {
    saveCachedSpaceReservation({
      cachedSpace: space,
      cachedSelectedStartDate: selectedEndDate,
      cachedSelectedEndDate: selectedStartDate
    });
  }, [saveCachedSpaceReservation, selectedEndDate, selectedStartDate, space]);

  const saveSpaceReservation = useSafeCallback(
    async (
      reservationStatus?: SpaceReservationStatus,
      disableSnackBar?: boolean
    ): Promise<SpaceReservationId | undefined> => {
      if (!space?.item?.tax || !selectedStartDate || !selectedEndDate) return;
      if (!user) {
        createCachedSpaceReservation();

        const logger = new LoggerService(base, firebase, user);
        await logger.saveSignInEvent();

        saveCurrentURL();
        openBasePath(Path.SIGN_IN);
        return;
      }

      const options =
        baseResourceCategoryOptions
          .map((option, index) =>
            builder<SpaceReservationOption>()
              .spaceReservationOptionId(uuid())
              .baseResourceCategoryOption(option)
              .value(reservationOptionValues[index])
              .build()
          )
          .filter(option => !!option.value) ?? [];
      const manuallyReservationNoOption = space.baseResourceCategory?.baseResourceCategoryOptions?.find(
        option => option.isReservationNo
      );

      const reservation = builder<SpaceReservation>()
        .spaceReservationId(isReservationTimeEdit || isReservationTimeExtension ? currentSpaceReservationId : uuid())
        .reservationName(EMPTY)
        .pricePerHour(space.item.unitPrice)
        .taxRate(space.item.tax.taxRate)
        .taxDiv(space.item.taxDiv)
        .remarks(remarksToSave)
        .startAt(selectedStartDate)
        .endAt(selectedEndDate)
        .space(space)
        .createdUser(user)
        .options(options)
        .status(reservationStatus ?? space.defaultReservationStatus);

      if (manuallyReservationNoOption) {
        reservation.reservationNo(
          options.find(
            option =>
              option.baseResourceCategoryOption?.baseResourceCategoryOptionId ===
              manuallyReservationNoOption.baseResourceCategoryOptionId
          )?.value ?? EMPTY
        );
      }

      if (reservationPaymentMethod === ReservationPaymentMethod.CASH) {
        reservation.paymentStatus(SpaceReservationPaymentStatus.UNPAID);
      }

      if (
        !contract &&
        !isBaseFunctionToggleEnabled(base, BaseFunctionToggleCode.FUNCTION_USE_RESERVATION_SPACE_PAYMENT)
      ) {
        reservation.paymentMethod(ReservationPaymentMethod.CASH);
      } else {
        reservation.paymentMethod(reservationPaymentMethod);
      }

      const reservationRequest = reservation.build();

      const participant = builder<SpaceParticipant>()
        .spaceParticipantId(uuid())
        .participantDiv(SpaceParticipantDiv.OWNER)
        .user(user)
        .build();

      const request = builder<SaveSpaceReservationRequest>()
        .baseId(base.baseId)
        .userId(user.userId)
        .spaceReservation(reservationRequest)
        .spaceParticipants(isReservationTimeEdit || isReservationTimeExtension ? participants : [participant])
        .build();
      const response = await CommonRequest.call<SaveSpaceReservationRequest, SaveSpaceReservationResponse>(
        SAVE_SPACE_RESERVATION,
        request
      );

      const isSavedSuccessfully = !!response?.spaceReservationId;
      if (isSavedSuccessfully && !isReservationTimeEdit && !isReservationTimeExtension)
        return response?.spaceReservationId;
      const successMessage = isReservationTimeEdit ? SPACE_RESERVE_EDITED : SPACE_RESERVE_EXTENDED;
      const message = isSavedSuccessfully ? successMessage : SPACE_RESERVE_ERROR;
      const status = isSavedSuccessfully ? SUCCESS : ERROR;

      !disableSnackBar && openSnackbar(message, status, 3000);
      return response?.spaceReservationId;
    },
    [
      base.baseCode,
      base.baseId,
      baseResourceCategoryOptions,
      createCachedSpaceReservation,
      currentSpaceReservationId,
      firebase,
      isReservationTimeEdit,
      isReservationTimeExtension,
      openBasePath,
      openSnackbar,
      participants,
      remarksToSave,
      reservationOptionValues,
      reservationPaymentMethod,
      saveCurrentURL,
      selectedEndDate,
      selectedStartDate,
      space,
      user
    ]
  );

  const getBasePullDownOptions = useSafeCallback(
    (date: Date, times: AvailabilityTimes): PullDownOption[] => {
      if (!space) return [];
      const { startTime, endTime: originalEndTime } = times;
      const endTime = originalEndTime === '00:00' ? '24:00' : originalEndTime;
      const reservationStartUnit = getReservationStartUnit([space]);
      const startAt = toDateWithNewTime(date, startTime);
      const endAt = startTime === endTime ? subMinutes(addDays(startAt, 1), 1) : toDateWithNewTime(date, endTime);

      const timeIntervals = getTimeIntervals(startAt, endAt, reservationStartUnit);
      return getOptions(timeIntervals);
    },
    [getTimeIntervals, space]
  );

  const toStartPullDownOptions = useSafeCallback(
    (options: PullDownOption[]): PullDownOption[] => {
      if (!selectedStartDate || !spaceAvailabilityAt?.spaceStartAt || !spaceAvailabilityAt?.spaceEndAt || !space)
        return [];
      const { lastStartAt, reservationUnit } = space;
      const lastStartTimeAt = toDateWithNewTime(selectedStartDate, lastStartAt);
      const minimumReservation =
        differenceInCalendarDays(spaceAvailabilityAt.spaceEndAt, spaceAvailabilityAt.spaceStartAt) > 0
          ? reservationUnit
          : space.minimumReservation;
      const currentLastStartAt = isBefore(lastStartTimeAt, spaceAvailabilityAt.spaceEndAt)
        ? lastStartTimeAt
        : subMinutes(spaceAvailabilityAt.spaceEndAt, minimumReservation);
      const filteredPullDownOptions = options.filter(option => {
        if (!lastStartAt) return true;
        if (!option.label) return false;
        const optionTimeAt = toDateWithNewTime(selectedStartDate, option.label);
        return optionTimeAt <= currentLastStartAt;
      });

      return filteredPullDownOptions;
    },
    [selectedStartDate, space, spaceAvailabilityAt]
  );

  const toEndPullDownOptions = useSafeCallback(
    (options: PullDownOption[], startTimeLabel: Label | undefined): PullDownOption[] => {
      if (!space) return [];
      if (isReservationTimeEdit) return options.filter((_, idx) => idx > minimumReservationIndex - 1);
      const startTime =
        isReservationTimeExtension && !!currentReservation && currentReservation.endAt
          ? format(currentReservation.endAt, 'HH:mm')
          : startTimeLabel;
      const { minimumReservation = 0, reservationUnit } = space;
      const filteredPullDownOptions = options.filter(option => {
        if (!option.label) return false;
        const optionTimeAt = toDateWithNewTime(new Date(), option.label);
        const startTimeAt = toDateWithNewTime(new Date(), startTime);
        return (
          addMinutes(startTimeAt, isReservationTimeExtension ? reservationUnit : minimumReservation) <= optionTimeAt
        );
      });
      return filteredPullDownOptions;
    },
    [currentReservation, isReservationTimeEdit, isReservationTimeExtension, minimumReservationIndex, space]
  );

  const createPullDownOptions = useSafeCallback((): void => {
    if (!selectedStartDate || !selectedEndDate || !space || !hasLength(space.availabilities)) return;
    const availabilities = space.availabilities;

    const [availabilityTimesOfStartDate, availabilityTimesOfEndDate] = [
      getStartAndEndTimesForDay(base.timezone, selectedStartDate, availabilities),
      getStartAndEndTimesForDay(base.timezone, selectedEndDate, availabilities)
    ];

    if (!availabilityTimesOfStartDate || !availabilityTimesOfEndDate) {
      setStartPullDownOptions([]);
      setEndPullDownOptions([]);
      setSelectedEndTimeIdx(null);
      setSelectedStartTimeIdx(null);
      return;
    }

    const [startOptions, endOptions] = [
      getBasePullDownOptions(selectedStartDate, availabilityTimesOfStartDate),
      getBasePullDownOptions(selectedEndDate, availabilityTimesOfEndDate)
    ];

    const filteredStartOptions = toStartPullDownOptions(startOptions);
    const startTimeIdx = getTimeIdx(startOptions, selectedStartDate);
    const startTimeLabel = filteredStartOptions.find(option => option.id === startTimeIdx.toString())?.label;
    const filteredEndOptions = isSameDay(selectedStartDate, selectedEndDate)
      ? toEndPullDownOptions(endOptions, startTimeLabel)
      : endOptions;
    const endTimeIdx = getTimeIdx(endOptions, selectedEndDate);

    setStartPullDownOptions(filteredStartOptions);
    setEndPullDownOptions(filteredEndOptions);
    setSelectedStartTimeIdx(startTimeIdx);
    setSelectedEndTimeIdx(endTimeIdx);
  }, [
    base.timezone,
    getBasePullDownOptions,
    selectedEndDate,
    selectedStartDate,
    setEndPullDownOptions,
    setSelectedEndTimeIdx,
    setSelectedStartTimeIdx,
    setStartPullDownOptions,
    space,
    toEndPullDownOptions,
    toStartPullDownOptions
  ]);

  const buildSpaceReservation = useSafeCallback(async (): Promise<void> => {
    if (!isReservationTimeExtension && !isReservationTimeEdit) return;
    const request = builder<FetchSpaceReservationRequest>().spaceReservationId(currentSpaceReservationId).build();
    const { spaceReservation } = await CommonRequest.call<FetchSpaceReservationRequest, FetchSpaceReservationResponse>(
      FETCH_SPACE_RESERVATION,
      request
    );
    const { space, startAt, endAt, remarks, participants, options = [] } = spaceReservation;
    setSpace(space);
    setSelectedStartDate(startAt ? new Date(startAt) : undefined);
    setSelectedEndDate(addMinutes(endAt!, isReservationTimeExtension ? space!.reservationUnit : 0));
    setPrevEndDate(endAt);
    setRemarksToSave(remarks);
    setParticipants(participants!);
    setCurrentReservation(spaceReservation);
    setBaseResourceCategoryOptions(toCategoryOptions(space));
    setReservationOptionValues(toCategoryOptionValues(space, options));
  }, [
    currentSpaceReservationId,
    isReservationTimeExtension,
    isReservationTimeEdit,
    setSpace,
    setSelectedStartDate,
    setSelectedEndDate,
    setPrevEndDate,
    setRemarksToSave,
    setParticipants,
    setCurrentReservation,
    setBaseResourceCategoryOptions,
    setReservationOptionValues
  ]);

  const buildSpaceUsages = useSafeCallback(async (): Promise<void> => {
    if (!selectedDate || !space) return;
    setLoading(true);

    const reservationType =
      space?.baseResourceCategory?.resourceCategoryDef?.reservationType ?? SpaceReservationType.TIME;

    const request = builder<FetchSpaceUsagesRequest>()
      .spaceIds([space.spaceId])
      .baseId(base.baseId)
      .fromDate(selectedDate)
      .toDate(addDays(selectedDate, ONE))
      .reservationType(reservationType)
      .userId(user?.userId)
      .build();
    const response = await CommonRequest.call<FetchSpaceUsagesRequest, FetchSpaceUsagesResponse>(
      FETCH_SPACE_USAGES,
      request
    );
    setSpaceUsages(response.spaceUsages);
    setLoading(false);
  }, [base.baseId, selectedDate, setLoading, setSpaceUsages, space, user?.userId]);

  const buildContractV2ByUser = useSafeCallback(async (): Promise<void> => {
    if (!user) return;
    const request = builder<FetchContractV2ByUserRequest>().baseId(base.baseId).userId(user.userId).build();
    const response = await CommonRequest.call<FetchContractV2ByUserRequest, FetchContractV2ByUserResponse>(
      FETCH_CONTRACT_V2_BY_USER,
      request
    );
    setContract(response.contract);
  }, [base, setContract, user]);

  const fetchSpacePaymentMethodAvailability = useSafeCallback(async (): Promise<void> => {
    if (space) {
      const request = builder<FetchSpacePaymentMethodRequest>().spaceId(space.spaceId).build();
      const response = await CommonRequest.call<FetchSpacePaymentMethodRequest, FetchSpacePaymentMethodResponse>(
        FETCH_SPACE_PAYMENT_METHOD,
        request
      );
      hasLength(response.paymentMethods) && setSpacePaymentMethodWithAvailability(response.paymentMethods);
    }
  }, [space, base, setSpacePaymentMethodWithAvailability]);

  const openPathOnExtension = useSafeCallback((): void => {
    createCachedSpaceReservation();
    if (isUpdate) {
      openPath(embedIdInPath(Path.ACCOUNT_RESERVATION_DETAIL, PATH_IDS, [base.baseCode, currentSpaceReservationId]));
    } else {
      openBasePath(Path.RESERVE_SPACE);
    }
  }, [base, createCachedSpaceReservation, isUpdate, openBasePath, openPath, currentSpaceReservationId]);

  const spaceReservationOperation = useMemo<SpaceReservationOperation>(() => {
    if (isReservationTimeExtension) return 'extension';
    if (isReservationTimeEdit) return 'edit';
    return space?.defaultReservationStatus ?? SpaceReservationStatus.CONFIRMED;
  }, [isReservationTimeEdit, isReservationTimeExtension, space]);

  const openCompleteSpaceReservationScreen = useSafeCallback(() => {
    openPath(embedIdInPath(Path.RESERVE_SPACE_COMPLETE, PATH_IDS, [base.baseCode, spaceReservationOperation]));
  }, [base, openPath, spaceReservationOperation]);

  const updateReservation = useSafeCallback(async (): Promise<void> => {
    setLoading(true);
    const spaceReservationId = await saveSpaceReservation();
    setLoading(false);
    spaceReservationId && createCachedSpaceReservation();
    spaceReservationId && openCompleteSpaceReservationScreen();
  }, [createCachedSpaceReservation, openCompleteSpaceReservationScreen, saveSpaceReservation, setLoading]);

  const createStripeCheckoutSession = useSafeCallback(
    async (spaceReservationId?: SpaceReservationId): Promise<CreateSpaceReservationPayment200 | undefined> => {
      if (!space || !actualUsageHour || !user) return undefined;

      const diffMinutes = hoursToMinutes(actualUsageHour);
      const quantity = Math.ceil(diffMinutes / space.reservationUnit);

      const payItems: CreateSpaceReservationPaymentBodyPayItemsItem[] = [
        { itemId: space.item!.itemId, quantity, reservationUnitMinutes: space.reservationUnit }
      ];

      const myschedulePath = `https://${DOMAINS[env]}${embedIdInPath(Path.ACCOUNT_MY_SCHEDULES, PATH_IDS, [
        base.baseCode
      ])}`;
      const successUrl = `${myschedulePath}?${STRIPE_SESSION_ID}={CHECKOUT_SESSION_ID}&${STRIPE_PAY_RESULT}=success`;
      const returnUrl = `https://${DOMAINS[env]}${embedIdInPath(Path.RESERVE_SPACE, PATH_IDS, [base.baseCode])}`;

      const metadata: CreateSpaceReservationPaymentBodyMetadata = isUpdate
        ? {
            isUpdate: String(isUpdate),
            beforeFrom: currentReservation?.startAt?.getTime().toString() ?? EMPTY,
            beforeTo: currentReservation?.endAt?.getTime().toString() ?? EMPTY,
            beforeStatus: currentReservation?.status ?? EMPTY,
            baseId: base.baseId
          }
        : {
            isUpdate: String(isUpdate),
            baseId: base.baseId
          };

      if (typeof spaceReservationId === 'string') {
        const { data } = await getBsSpace().createSpaceReservationPayment(base.baseId, spaceReservationId, {
          userId: user.userId,
          payItems,
          successUrl,
          returnUrl,
          metadata
        });

        return data;
      } else {
        const request = builder<CreateStripeCheckoutSessionRequest>()
          .baseId(base.baseId)
          .userId(user.userId)
          .payItems(payItems)
          .successUrl(successUrl)
          .returnUrl(returnUrl)
          .metadata(metadata)
          .build();

        return await CommonRequest.call<CreateStripeCheckoutSessionRequest, CreateStripeCheckoutSessionResponse>(
          CREATE_STRIPE_CHECKOUT_SESSION,
          request
        );
      }
    },
    [actualUsageHour, base.baseCode, base.baseId, space, user, isUpdate, currentReservation]
  );

  const saveTempSpaceReservation = useSafeCallback(
    async (stripeSessionId: StripeSessionId): Promise<void> => {
      if (!space?.item?.tax || !selectedStartDate || !selectedEndDate) return;
      if (!user) {
        createCachedSpaceReservation();

        const logger = new LoggerService(base, firebase, user);
        await logger.saveSignInEvent();

        saveCurrentURL();
        openBasePath(Path.SIGN_IN);
        return;
      }

      const request = builder<SaveTempSpaceReservationRequest>()
        .tempSpaceReservationId(currentSpaceReservationId || undefined)
        .reservationName(EMPTY)
        .pricePerHour(space.item.unitPrice)
        .taxRate(space.item.tax.taxRate)
        .taxDiv(space.item.taxDiv)
        .remarks(remarksToSave)
        .startAt(selectedStartDate)
        .endAt(selectedEndDate)
        .spaceId(space.spaceId)
        .userId(user.userId)
        .stripeSessionId(stripeSessionId)
        .build();
      await CommonRequest.call<SaveTempSpaceReservationRequest, SaveTempSpaceReservationResponse>(
        SAVE_TEMP_SPACE_RESERVATION,
        request
      );
    },
    [
      base.baseCode,
      createCachedSpaceReservation,
      currentSpaceReservationId,
      firebase,
      openBasePath,
      remarksToSave,
      saveCurrentURL,
      selectedEndDate,
      selectedStartDate,
      space,
      user
    ]
  );

  const updateReservationWithPayment = useSafeCallback(async (): Promise<void> => {
    setLoading(true);
    if (isBaseFunctionToggleEnabled(base, BaseFunctionToggleCode.FUNCTION_REFACTOR_TEMP_SPACE_RESERVATIONS)) {
      const updatedStatus = isUpdate
        ? SpaceReservationStatus.ADDITIONAL_PAYMENT_IN_PROGRESS
        : SpaceReservationStatus.PAYMENT_IN_PROGRESS;
      const spaceReservationId = await saveSpaceReservation(updatedStatus, true);
      if (!spaceReservationId) {
        openSnackbar(SPACE_RESERVE_ERROR, 'error', 10000);
        return;
      }
      const { sessionId, redirectUrl } = (await createStripeCheckoutSession(spaceReservationId)) ?? {};
      if (!sessionId || !redirectUrl) return;
      window.location.href = redirectUrl;
    } else {
      const { sessionId, redirectUrl } = (await createStripeCheckoutSession()) ?? {};
      if (!sessionId || !redirectUrl) return;
      await saveTempSpaceReservation(sessionId);
      window.location.href = redirectUrl;
    }
    setLoading(false);
  }, [base, createStripeCheckoutSession, isUpdate, saveSpaceReservation, saveTempSpaceReservation, setLoading]);

  const updateReservationWithRefund = useSafeCallback(async (): Promise<void> => {
    if (!user) return;
    setLoading(true);

    const request = builder<RefundSpaceReservationRequest>()
      .useCancelPolicy(false)
      .spaceReservationId(currentSpaceReservationId)
      .refundMinutes(hoursToMinutes(actualUsageHour) * MINUS_ONE)
      .build();
    const { result } = await CommonRequest.call<RefundSpaceReservationRequest, RefundSpaceReservationResponse>(
      REFUND_SPACE_RESERVATION,
      request
    );

    if (!result) {
      setLoading(false);
      return;
    }

    const spaceReservationId = await saveSpaceReservation();

    setLoading(false);
    spaceReservationId && createCachedSpaceReservation();
    spaceReservationId && openCompleteSpaceReservationScreen();
  }, [
    actualUsageHour,
    createCachedSpaceReservation,
    currentSpaceReservationId,
    openCompleteSpaceReservationScreen,
    saveSpaceReservation,
    setLoading,
    user
  ]);

  const handleCancelClicked = useSafeCallback((): void => {
    openPathOnExtension();
  }, [openPathOnExtension]);

  const checkSpaceReservationLimit = useSafeCallback(async (): Promise<void> => {
    if (!user || !selectedStartDate || !selectedEndDate) return;
    const request = builder<CheckSpaceReservationLimitRequest>()
      .baseId(base.baseId)
      .start(selectedStartDate)
      .end(selectedEndDate)
      .userId(user.userId)
      .spaceId(space?.spaceId)
      .spaceReservationId(currentSpaceReservationId)
      .build();
    const response = await CommonRequest.call<CheckSpaceReservationLimitRequest, CheckSpaceReservationLimitResponse>(
      CHECK_SPACE_RESERVATION_LIMIT,
      request
    );
    setIsReservationLimited(response.isLimited);
    setErrorMessageForLimit(response.errorMessage ?? EMPTY);
  }, [
    base,
    isReservationTimeExtension,
    prevEndDate,
    setErrorMessageForLimit,
    setIsReservationLimited,
    selectedEndDate,
    selectedStartDate,
    space,
    user
  ]);

  const initialize = useSafeCallback(async (): Promise<void> => {
    if (isInitialized) return;
    await Promise.all([buildContractV2ByUser(), buildSpaceReservation()]).then(() =>
      fetchSpacePaymentMethodAvailability()
    );
    setIsInitialized(true);
  }, [
    buildContractV2ByUser,
    buildSpaceReservation,
    isInitialized,
    setIsInitialized,
    fetchSpacePaymentMethodAvailability
  ]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  useEffect(() => {
    buildSpaceUsages();
  }, [buildSpaceUsages]);

  useEffect(() => {
    if (!selectedStartDate || isReservableMultipleDays) return;
    setSelectedEndDate((prev: Date | undefined) => {
      if (!prev) return undefined;
      const formattedTime = format(prev, 'HH:mm');
      if (formattedTime === '00:00') {
        return addDays(toDateWithNewTime(selectedStartDate, '00:00'), 1);
      }
      return toDateWithNewTime(selectedStartDate, formattedTime);
    });
  }, [isReservableMultipleDays, selectedStartDate, setSelectedEndDate]);

  useEffect(() => {
    createPullDownOptions();
  }, [createPullDownOptions]);

  useEffect(() => {
    if (isInitialized) return;
    clearCachedSpaceReservation();
  }, [isInitialized, clearCachedSpaceReservation]);

  useEffect(() => {
    checkSpaceReservationLimit();
  }, [checkSpaceReservationLimit]);

  useEffect(() => {
    if (isUpdate || (space && selectedEndDate && selectedStartDate)) return;
    openBasePath(Path.RESERVE_SPACE);
  }, [isUpdate, openBasePath, selectedEndDate, selectedStartDate, space]);

  const handleReserveClick = useSafeCallback(async (): Promise<void> => {
    if (!isEnabledCreditCardPayment || !pricePerHour || reservationPaymentMethod === ReservationPaymentMethod.CASH) {
      await updateReservation();
      return;
    }
    if (isGreaterThanZero(actualUsageHour) && reservationPaymentMethod === ReservationPaymentMethod.CREDIT_CARD) {
      await updateReservationWithPayment();
      return;
    }

    if (isLessThanZero(actualUsageHour) && reservationPaymentMethod === ReservationPaymentMethod.CREDIT_CARD) {
      await updateReservationWithRefund();
      return;
    }
    await updateReservation();
  }, [
    reservationPaymentMethod,
    isEnabledCreditCardPayment,
    actualUsageHour,
    updateReservation,
    updateReservationWithPayment,
    updateReservationWithRefund,
    pricePerHour
  ]);

  return (
    <Component
      className='space-reservation-confirm-screen'
      style={styleForFullExpansion}
      loading={!isInitialized || !space || !selectedEndDate || !selectedStartDate}
      title={`knotPLACE -${pageTitle}-`}
    >
      <Container>
        <Content>
          <PageTitleWrapper>
            <PageHeaderV2 title={pageTitle} />
          </PageTitleWrapper>
          <CardWrapper>
            <CardWithLabelV2 label='予約対象' text={space?.spaceName ?? EMPTY} subText={pricePerHourText} />
            {isReservableMultipleDays && (
              <>
                <CardContent>
                  <InputDateWithLabelV2
                    readonly={isReservationTimeExtension}
                    text='予約開始日時'
                    minDate={isReservationTimeExtension ? undefined : reserveReceptionStartDate}
                    maxDate={
                      isReservationTimeExtension || !reserveReceptionEndDate
                        ? undefined
                        : subDays(reserveReceptionEndDate, 1)
                    }
                    value={selectedStartDate || new Date()}
                    onChange={handleStartDateChanged}
                    error={!!errorMessageForDate}
                  />
                  {hasLength(startPullDownOptions) && (
                    <PullDownV2
                      readonly={!!errorMessageForDate || isReservationTimeExtension}
                      id={getPullDownId(selectedStartTimeIdx)}
                      placeholder='選択'
                      options={startPullDownOptions}
                      onClick={handleStartTimeSelected}
                    />
                  )}
                </CardContent>
                <CardContent>
                  <InputDateWithLabelV2
                    readonly={isReservationTimeExtension}
                    text='予約終了日時'
                    minDate={isReservationTimeExtension ? undefined : reserveReceptionStartDate}
                    maxDate={isReservationTimeExtension ? undefined : reserveReceptionEndDate}
                    value={selectedEndDate || new Date()}
                    onChange={handleEndDateChanged}
                    error={!!errorMessageForDate}
                  />
                  {hasLength(endPullDownOptions) && (
                    <PullDownV2
                      readonly={!!errorMessageForDate}
                      id={getPullDownId(selectedEndTimeIdx)}
                      placeholder='選択'
                      options={endPullDownOptions}
                      onClick={handleEndTimeSelected}
                    />
                  )}
                </CardContent>
              </>
            )}
            {!isReservableMultipleDays && (
              <CardContent>
                <InputDateWithLabelV2
                  readonly={isReservationTimeExtension}
                  text='予約日時'
                  minDate={isReservationTimeExtension ? undefined : reserveReceptionStartDate}
                  maxDate={isReservationTimeExtension ? undefined : reserveReceptionEndDate}
                  value={selectedStartDate || new Date()}
                  onChange={handleStartDateChanged}
                  errorMessage={errorMessageForDate}
                  error={!!errorMessageForDate}
                />
                {hasLength(startPullDownOptions) && (
                  <PullDownWrapper>
                    <PullDownV2
                      readonly={!!errorMessageForDate || isReservationTimeExtension}
                      id={getPullDownId(selectedStartTimeIdx)}
                      placeholder='選択'
                      options={startPullDownOptions}
                      onClick={handleStartTimeSelected}
                    />
                    <Hyphen>-</Hyphen>
                    <PullDownV2
                      readonly={!!errorMessageForDate}
                      id={getPullDownId(selectedEndTimeIdx)}
                      placeholder='選択'
                      options={endPullDownOptions}
                      onClick={handleEndTimeSelected}
                    />
                  </PullDownWrapper>
                )}
              </CardContent>
            )}
            {(errorMessageForLimit || hasLength(errorMessagesForTime)) && (
              <ErrorMessageArea>
                <StyledInfoOutlinedIcon />
                <div>
                  {errorMessageForLimit ||
                    errorMessagesForTime.map((message: Message, index: Index) => (
                      <div key={`error-message-${index}`}>{message}</div>
                    ))}
                </div>
              </ErrorMessageArea>
            )}
            {space?.baseResourceCategory?.baseResourceCategoryOptions?.map((option, index) => (
              <InputWithLabelV2
                key={`option-value-${index}`}
                multiline={option.inputType === BaseResourceCategoryOptionInputType.MULTILIINE_TEXT}
                required={option.isRequired}
                text={option.optionLabel}
                value={reservationOptionValues[index]}
                inputType={toInputTypeFromBaseResourceCategoryOptionValidationType(option.validationType)}
                onChange={value => handleOptionValueChanged(value, index)}
              />
            ))}
            {isBaseFunctionToggleEnabled(base, BaseFunctionToggleCode.FUNCTION_USE_RESERVATION_SPACE_PAYMENT) &&
              isShowSpacePaymentStatus && (
                <SpaceReservationPayment
                  spacePaymentMethodWithAvailabilities={spacePaymentMethodWithAvailabilities}
                  reservationPaymentMethod={reservationPaymentMethod}
                  setReservationPaymentMethod={setReservationPaymentMethod}
                  selectedStartDate={selectedStartDate}
                />
              )}
            <InputWithLabelV2
              text='備考'
              optional
              multiline
              maxLength={RESERVATION_REMARKS_LEN}
              value={remarksToSave}
              onChange={setRemarksToSave}
            />
          </CardWrapper>
          {!(errorMessageForLimit || hasLength(errorMessagesForTime)) && isInitialized && !isReservationLimited && (
            <SpacePriceDetail
              isEnabledCreditCardPayment={isEnabledCreditCardPayment}
              reservationType={
                space?.baseResourceCategory?.resourceCategoryDef?.reservationType ?? SpaceReservationType.TIME
              }
              name={space?.spaceName ?? EMPTY}
              item={space?.item}
              selectedStartDate={selectedStartDate}
              selectedEndDate={selectedEndDate}
              contract={contract}
              currentReservation={currentReservation}
              isContractPlanDiscountRateTarget={space?.baseResourceCategory?.isContractPlanDiscountRateTarget ?? false}
              enableFunctionToggleCodes={base.enabledFunctions?.map(e => e.toggleCode) ?? []}
            />
          )}
          {isProvisional && (
            <InformationPanel isBold title='この予約は仮予約です。' status='warning'>
              この予約は仮予約です。送信後すぐに予約が確定するものではありません。
            </InformationPanel>
          )}
        </Content>
        <Footer>
          <StyledFooterButton label='キャンセル' onClick={handleCancelClicked} />
          {!isUpdate && (
            <StyledFooterButton
              type='primary'
              label={`${isProvisional ? '仮予約' : '予約'}`}
              disabled={isEnabledPrimayButton}
              onClick={handleReserveClick}
            />
          )}
          {isUpdate && (
            <StyledFooterButton
              type='primary'
              label={`${isReservationTimeExtension ? '利用時間の延長' : '利用日時の変更'}`}
              disabled={isEnabledPrimayButton}
              onClick={() => setIsModalOpen(true)}
            />
          )}
        </Footer>
      </Container>
      {selectedEndDate && (
        <DefaultModal
          isOpen={isModalOpen}
          headerLabel={pageTitle}
          rightButtonProps={{ label: isReservationTimeExtension ? '延長' : '変更', onClick: handleReserveClick }}
          onClose={() => setIsModalOpen(false)}
        >
          <DialogDescription>
            {isReservationTimeExtension &&
              `${format(selectedEndDate, 'HH:mm')}まで利用時間を延長します。よろしいですか？`}
            {isReservationTimeEdit && '利用日時を変更します。よろしいですか？'}
          </DialogDescription>
        </DefaultModal>
      )}
      <ScreenLoaderV2 loading={loading} />
    </Component>
  );
});

SpaceReservationConfirmScreen.displayName = 'SpaceReservationConfirmScreen';
export default SpaceReservationConfirmScreen;

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const Content = styled.div`
  width: 100%;
  max-width: 640px;
  height: auto;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: ${themeV2.mixins.v2.spacing}px ${themeV2.mixins.v2.spacing}px ${themeV2.mixins.v2.spacing * 35}px;
  gap: ${themeV2.mixins.v2.spacing}px;
`;

const PageTitleWrapper = styled.div`
  margin: ${themeV2.mixins.v2.spacing}px;
`;

const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${themeV2.mixins.v2.spacing * 2}px;
  padding: ${themeV2.mixins.v2.spacing * 2}px;
  background: ${themeV2.mixins.v2.color.background.white};
  border-radius: 8px;
`;

const Hyphen = styled.div`
  margin: 0 ${themeV2.mixins.v2.spacing * 2}px;
  ${themeV2.mixins.v2.typography.body.large};
`;

const CardContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${themeV2.mixins.v2.spacing}px;
`;

const PullDownWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const Footer = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  gap: ${themeV2.mixins.v2.spacing}px;
  padding: ${themeV2.mixins.v2.spacing * 2}px;
  background: ${themeV2.mixins.v2.color.background.white};
  box-shadow: ${themeV2.mixins.v2.shadow.elevation5};
`;

const StyledFooterButton = styled(ButtonV2).attrs(() => ({ size: 'large', isFullWidth: true }))`
  ${customMedia.greaterThan('small')`
      width:200px !important;
  `};
`;

const ErrorMessageArea = styled.div`
  ${themeV2.mixins.v2.typography.body.medium};
  display: flex;
  align-items: center;
  gap: ${themeV2.mixins.v2.spacing * 2}px;
  padding: ${themeV2.mixins.v2.spacing * 2}px;
  color: ${themeV3.mixins.v3.color.container.action.errorDark};
  background: ${themeV3.mixins.v3.color.container.action.errorPale};
  border-radius: 8px;
  white-space: pre-wrap;
`;

const StyledInfoOutlinedIcon = styled(InfoOutlinedIcon)`
  color: ${themeV3.mixins.v3.color.container.action.error};
`;

const DialogDescription = styled.div`
  align-self: stretch;
  color: ${themeV2.mixins.v2.color.font.black};
  ${themeV2.mixins.v2.typography.body.medium}
  line-height: 150%;
  white-space: pre-wrap;
`;
