import {
  Component,
  InformationPanel,
  PageHeaderV2,
  TabsV3,
  themeV2,
  useMobile,
  useSafeCallback,
  useSafeState,
  useUnmountRef
} from '@atomica.co/components';
import {
  AccommodationPlan,
  AccommodationPlanId,
  BaseDto,
  BaseFunctionToggleCode,
  BaseResourceCategory,
  BaseResourceCategoryId,
  BaseResourceCategoryRelationship,
  ChildReservationMap,
  ContractPlanV2,
  ContractV2,
  FETCH_CONTRACT_V2_BY_USER,
  FETCH_SPACE_RESERVATIONS_BY_USER,
  FETCH_SPACE_USAGES,
  FetchContractV2ByUserRequest,
  FetchContractV2ByUserResponse,
  FetchSpaceReservationsByUserRequest,
  FetchSpaceReservationsByUserResponse,
  FetchSpaceUsagesRequest,
  FetchSpaceUsagesResponse,
  RelationshipForFetchChildReservationMap,
  ResourceCategoryCode,
  SAVE_SPACE_RESERVATION,
  SEARCH_SPACES,
  SPACE_RESERVATION_ID,
  SaveSpaceReservationRequest,
  SaveSpaceReservationResponse,
  SearchSpacesRequest,
  SearchSpacesResponse,
  Space,
  SpaceCategory,
  SpaceId,
  SpaceParticipant,
  SpaceParticipantDiv,
  SpacePublishOption,
  SpaceReservation,
  SpaceReservationId,
  SpaceReservationStatus,
  SpaceReservationType,
  SpaceUsagesForTime,
  UPDATE_SPACE_RESERVATION_STATUS,
  UpdateSpaceReservationStatusRequest,
  UpdateSpaceReservationStatusResponse,
  User,
  isBaseFunctionToggleEnabled
} from '@atomica.co/irori';
import { Id, Index, Label, Message, Name, Remarks, Text, Title, Word } from '@atomica.co/types';
import {
  EMPTY,
  FIRST_INDEX,
  ONE,
  builder,
  embedIdInPath,
  hasLength,
  isGreaterThanZero,
  isNull,
  isNumber,
  isUndefined,
  toBeginningOfDay,
  toEndOfDay,
  uuid
} from '@atomica.co/utils';
// https://github.com/fullcalendar/fullcalendar-vue/issues/152#issue-918047023
// https://github.com/fullcalendar/fullcalendar/issues/6371#issuecomment-1060708159
// @fullcalendar/react/dist/vdomをfullcalendar系importの最上位に置く
import '@fullcalendar/react/dist/vdom';
// prettier-ignore
import { DateClickArg } from '@fullcalendar/interaction';
// prettier-ignore
import { DateSelectArg, EventClickArg } from '@fullcalendar/react';
import { addDays, format, isAfter } from 'date-fns';
import { useSnackbar } from 'notistack';
import React, { useEffect, useMemo, useRef } from 'react';
import styled from 'styled-components';
import { ERROR, SUCCESS } from '../../constants/snackbar-const';
import { ReservationModalMode, SearchOption } from '../../enums/space-enum';
import useCachedSpaceReservation from '../../redux/hooks/useCachedSpaceReservation';
import useCachedURL from '../../redux/hooks/useCachedURL';
import useCommonRequest from '../../redux/hooks/useCommonRequest';
import usePath from '../../redux/hooks/usePath';
import useUser from '../../redux/hooks/useUser';
import CommonRequest from '../../requests/common-request';
import { PATH_IDS, Path } from '../../router/Routes';
import ItemService from '../../services/item-service';
import {
  SPACE_RESERVE_DELETED,
  SPACE_RESERVE_ERROR,
  SPACE_RESERVE_SAVED,
  SPACE_RESERVE_UPDATED
} from '../../texts/snackbar-text';
import { isNotFoundIndex } from '../../utils/common-util';
import { getTaxRate } from '../../utils/item-util';
import {
  filterResourcesByCategory,
  filterResourcesByUser,
  isReceptionClosed,
  isReceptionOpened
} from '../../utils/space-reservation-util';
import { isAdmin } from '../../utils/user-util';
import LoggerService from './../../services/logger-service';
import ReservationErrorModal from './modal/ReservationErrorModal';
import ReservationModal from './modal/ReservationModal';
import Notion from './notion/Notion';
import SelectDate from './select-date/SelectDate';
import SelectSpacePlan from './select-space-plan/SelectSpacePlan';
import SelectTime from './select-time/SelectTime';
import SpaceConditionInput from './space-condition-input/SpaceConditionInput';
import SpaceList from './space-list/SpaceList';

const TAB_CHANGING_MS = 100;

interface TabOption {
  id: BaseResourceCategoryId;
  label: Label;
  informationTitle: Title;
  informationNote: Text;
  reservationType: SpaceReservationType;
}

interface P {
  base: BaseDto;
  user: User | undefined;
}

const SpaceReservationScreen: React.FC<P> = React.memo(props => {
  const { base, user } = props;
  const reserveTimeRef = useRef<HTMLDivElement>(null);
  const { firebase } = useUser();
  const { path, queryParams, openBasePath, openPath } = usePath();
  const { enqueueSnackbar } = useSnackbar();
  const { saveCurrentURL } = useCachedURL();
  const isMobile = useMobile();
  const { cachedSpaceReservation, saveCachedSpaceReservation, clearCachedSpaceReservation } =
    useCachedSpaceReservation();

  const { commonRequest } = useCommonRequest();

  const unmountRef = useUnmountRef();
  const [isModalOpen, setIsModalOpen] = useSafeState<boolean>(unmountRef, false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useSafeState<boolean>(unmountRef, false);
  const [isSearchingReservation, setIsSearchingReservation] = useSafeState<boolean>(unmountRef, false);
  const [modalMode, setModalMode] = useSafeState<ReservationModalMode>(unmountRef, ReservationModalMode.READONLY);
  const [spaces, setSpaces] = useSafeState<Space[] | undefined>(unmountRef);
  const [isTabChanging, setIsTabChangeing] = useSafeState<boolean>(unmountRef, false);
  const [spaceUsages, setSpaceUsages] = useSafeState<SpaceUsagesForTime>(unmountRef);
  const [reservationName, setReservationName] = useSafeState<Name>(unmountRef, EMPTY);
  const [remarks, setRemarks] = useSafeState<Remarks>(unmountRef, EMPTY);
  const [spaceToReserve, setSpaceToReserve] = useSafeState<Space>(unmountRef);
  const [spaceIdToReserve, setSpaceIdToReserve] = useSafeState<SpaceId | undefined>(unmountRef);
  const [selectedSpaceIds, setSelectedSpaceIds] = useSafeState<SpaceId[]>(unmountRef, []);
  const [selectedDate, setSelectedDate] = useSafeState<Date | undefined>(unmountRef, toBeginningOfDay(new Date()));

  const [selectedStartAt, setSelectedStartAt] = useSafeState<Date | undefined>(unmountRef);
  const [selectedEndAt, setSelectedEndAt] = useSafeState<Date | undefined>(unmountRef);
  const [selectedReservation, setSelectedReservation] = useSafeState<SpaceReservation | undefined>(unmountRef);
  const [selectedTabIdx, setSelectedTabIdx] = useSafeState<Index | null>(unmountRef, null);

  const [errorMessage, setErrorMessage] = useSafeState<Message>(unmountRef, EMPTY);

  const [contractPlan, setContractPlan] = useSafeState<ContractPlanV2>(unmountRef);

  const [parentReservations, setParentReservations] = useSafeState<SpaceReservation[]>(unmountRef, []);
  const [childReservationMap, setChildReservationMap] = useSafeState<ChildReservationMap | undefined>(unmountRef, {});
  const [isSearching, setIsSearching] = useSafeState<boolean>(unmountRef, false);
  const [isContractMember, setIsContractMember] = useSafeState<boolean>(unmountRef, false);

  const [reservableSpacePlans, setReservableSpacePlans] = useSafeState<AccommodationPlan[] | undefined>(
    unmountRef,
    undefined
  );
  const [selectedSpacePlanId, setSelectedSpacePlanId] = useSafeState<AccommodationPlanId>(unmountRef, EMPTY);
  const [isSpacePlanSearching, setIsSpacePlanSearching] = useSafeState<boolean>(unmountRef, false);

  const isContractPlanInitialized = useRef<boolean>(false);

  const selectSpacePlanRef = useRef<HTMLDivElement>(null);

  // フィルターはバックエンドでやりたい
  const filteredCategories = useMemo<BaseResourceCategory[]>(() => {
    const baseResourceCategories = base.baseResourceCategories;
    if (!hasLength(baseResourceCategories)) return [];
    return baseResourceCategories
      .filter(baseResourceCategory => {
        const { resourceCategoryDef, contractPlansV2, isInvisible } = baseResourceCategory;
        const isNotEntrance = resourceCategoryDef?.categoryCode !== ResourceCategoryCode.ENTRANCE;
        // 暫定
        const isNotFreeSpace = resourceCategoryDef?.categoryCode !== ResourceCategoryCode.FREE_SPACE;
        const isValidPlan =
          !hasLength(contractPlansV2) ||
          contractPlansV2.some(plan => plan.contractPlanId === contractPlan?.contractPlanId);
        const isVisible = !isInvisible;
        return isNotEntrance && isValidPlan && isVisible && isNotFreeSpace;
      })
      .sort((a, b) => (a.order < b.order ? -1 : 1));
  }, [base, contractPlan]);

  const resourceCategoryTabOptions = useMemo<TabOption[]>(() => {
    return filteredCategories.map(category => ({
      id: category.baseResourceCategoryId,
      label: category.categoryLabel,
      informationTitle: category.informationTitle,
      informationNote: category.informationNote ?? EMPTY,
      reservationType: category.resourceCategoryDef!.reservationType
    }));
  }, [filteredCategories]);

  const informationTitle = useMemo<Title>(() => {
    if (isNull(selectedTabIdx) || !resourceCategoryTabOptions[selectedTabIdx]) return EMPTY;
    return resourceCategoryTabOptions[selectedTabIdx].informationTitle;
  }, [resourceCategoryTabOptions, selectedTabIdx]);

  const informationNote = useMemo<Text>(() => {
    if (isNull(selectedTabIdx) || !resourceCategoryTabOptions[selectedTabIdx]) return EMPTY;
    return resourceCategoryTabOptions[selectedTabIdx].informationNote;
  }, [resourceCategoryTabOptions, selectedTabIdx]);

  // フィルターはバックエンドでやりたい
  const filteredSpaces = useMemo<Space[]>(() => {
    if (!hasLength(spaces) || isTabChanging) return [];

    const filteredSpacesByUser = isBaseFunctionToggleEnabled(base, BaseFunctionToggleCode.FUNCTION_RESOURCE_AUTHORITY)
      ? filterResourcesByUser(base, spaces, user)
      : spaces;

    const selectedCategoryId =
      hasLength(resourceCategoryTabOptions) && isNumber(selectedTabIdx)
        ? resourceCategoryTabOptions[selectedTabIdx].id
        : undefined;

    return filterResourcesByCategory(filteredSpacesByUser, selectedCategoryId);
  }, [base, resourceCategoryTabOptions, selectedTabIdx, spaces, user, isTabChanging]);

  const isInitialized = useMemo<boolean>(() => {
    return (isNumber(selectedTabIdx) || !hasLength(resourceCategoryTabOptions)) && !!spaces;
  }, [resourceCategoryTabOptions, selectedTabIdx, spaces]);

  const isTabVisibled = useMemo<boolean>(() => {
    return isInitialized && isGreaterThanZero(resourceCategoryTabOptions.length);
  }, [isInitialized, resourceCategoryTabOptions]);

  const loadCachedSpaceReservation = useSafeCallback((): void => {
    const { cachedSpace, cachedSelectedDate, cachedSelectedStartDate, cachedSelectedEndDate } = cachedSpaceReservation;
    if (cachedSpace && cachedSelectedDate && cachedSelectedStartDate && cachedSelectedEndDate) {
      setSpaceToReserve(cachedSpace);
      setSelectedDate(toBeginningOfDay(new Date(cachedSelectedDate))!);
      setSelectedEndAt(new Date(cachedSelectedEndDate));
      setSelectedSpaceIds([cachedSpace.spaceId]);
      setSelectedStartAt(new Date(cachedSelectedStartDate));
    }
    clearCachedSpaceReservation();
  }, [
    cachedSpaceReservation,
    clearCachedSpaceReservation,
    setSelectedDate,
    setSelectedEndAt,
    setSelectedSpaceIds,
    setSelectedStartAt,
    setSpaceToReserve
  ]);

  const fetchSpaceUsages = useSafeCallback(async (): Promise<void> => {
    if (!selectedDate || isUndefined(filteredSpaces) || !hasLength(filteredSpaces)) return;
    setIsSearchingReservation(true);
    const reservationType = isNull(selectedTabIdx)
      ? SpaceReservationType.TIME
      : resourceCategoryTabOptions[selectedTabIdx].reservationType;
    const request = builder<FetchSpaceUsagesRequest>()
      .reservationType(reservationType)
      .baseId(base.baseId)
      .fromDate(selectedDate)
      .toDate(addDays(selectedDate, 1))
      .spaceIds(filteredSpaces.map(space => space.spaceId))
      .userId(user?.userId)
      .build();
    const response = await CommonRequest.call<FetchSpaceUsagesRequest, FetchSpaceUsagesResponse>(
      FETCH_SPACE_USAGES,
      request
    );
    setSpaceUsages(response.spaceUsages);
    setIsSearchingReservation(false);
  }, [
    base,
    resourceCategoryTabOptions,
    selectedTabIdx,
    selectedDate,
    setIsSearchingReservation,
    setSpaceUsages,
    filteredSpaces,
    user
  ]);

  const openAccountReservationDetail = useSafeCallback(
    (spaceReservationId: Id): void => {
      if (spaceReservationId) {
        saveCachedSpaceReservation({ fromPath: Path.RESERVE_SPACE });
        openPath(embedIdInPath(Path.ACCOUNT_RESERVATION_DETAIL, PATH_IDS, [base.baseCode, spaceReservationId]));
      }
    },
    [base, openPath, saveCachedSpaceReservation]
  );

  const handleSpaceClicked = useSafeCallback(
    (spaceId: SpaceId): void => {
      setSelectedSpaceIds(selectedSpaceIds => {
        const newSelectedSpaceIds: SpaceId[] = selectedSpaceIds.concat();
        newSelectedSpaceIds.includes(spaceId)
          ? newSelectedSpaceIds.splice(newSelectedSpaceIds.indexOf(spaceId), 1)
          : newSelectedSpaceIds.push(spaceId);
        return newSelectedSpaceIds;
      });
    },
    [setSelectedSpaceIds]
  );

  const handleDateClicked = useSafeCallback(
    (arg: DateClickArg): void => {
      setSelectedDate(toBeginningOfDay(new Date(arg.date))!);
      reserveTimeRef.current?.scrollIntoView({ behavior: 'smooth' });
    },
    [reserveTimeRef, setSelectedDate]
  );

  const handleTabClicked = useSafeCallback(
    (idx: Index): void => {
      if (idx === selectedTabIdx) return;
      setSelectedTabIdx(idx);
      setSelectedSpaceIds([]);
      setIsTabChangeing(true);
      setTimeout(() => {
        setIsTabChangeing(false);
      }, TAB_CHANGING_MS);
    },
    [selectedTabIdx, setSelectedSpaceIds, setSelectedTabIdx]
  );

  const handleTimeSelected = useSafeCallback(
    (arg: DateSelectArg): void => {
      if (isUndefined(spaces)) return;
      const startAt = arg.start;
      const endAt = arg.end;
      const spaceId = arg.resource?.id;
      const space = spaces.find(space => space.spaceId === spaceId);
      if (isUndefined(space)) return;

      if (!isReceptionOpened(space, selectedDate!)) {
        const reservationStartDate = format(addDays(new Date(), space.reservationStartDayOffset), 'yyyy年MM月dd日');
        setErrorMessage(`${space.spaceName}は ${reservationStartDate} から予約が可能です`);
        setIsErrorModalOpen(true);
        return;
      }

      if (isReceptionClosed(space, toEndOfDay(new Date(selectedDate!))!)) {
        const reservationEndDate = format(
          addDays(new Date(), space.reservationAdvanceBookingDays + ONE),
          'yyyy年MM月dd日'
        );
        setErrorMessage(`${space.spaceName}は ${reservationEndDate} 以降の予約はできません`);
        setIsErrorModalOpen(true);
        return;
      }

      const lastStarAt = new Date(`${format(startAt, 'yyyy/MM/dd')} ${space.lastStartAt}`);
      if (isAfter(startAt, lastStarAt)) {
        setErrorMessage(
          `${space.spaceName}は ${format(lastStarAt, 'HH時mm分')} より後の開始時刻を選択することはできません。`
        );
        setIsErrorModalOpen(true);
        return;
      }

      setSelectedStartAt(startAt);
      setSelectedEndAt(endAt);
      setSpaceIdToReserve(spaceId);
      setModalMode(ReservationModalMode.NEW);

      if (isBaseFunctionToggleEnabled(base, BaseFunctionToggleCode.FUNCTION_USE_SPACE_ORDER_PERIOD)) {
        saveCachedSpaceReservation({
          cachedSpace: space,
          cachedSelectedDate: selectedDate,
          cachedSelectedStartDate: startAt,
          cachedSelectedEndDate: endAt
        });
        openBasePath(Path.RESERVE_SPACE_CONFIRM);
      } else {
        setIsModalOpen(true);
      }
    },
    [
      base,
      openBasePath,
      saveCachedSpaceReservation,
      setErrorMessage,
      setIsErrorModalOpen,
      setIsModalOpen,
      setModalMode,
      setSelectedStartAt,
      setSelectedEndAt,
      setSpaceIdToReserve,
      selectedDate,
      spaces
    ]
  );

  const onClickEvent = useSafeCallback(
    (arg: EventClickArg): void => {
      if (!user) return;
      const reservationId = arg.event.id;
      const resourceIds = arg.event._def?.resourceIds;
      if (!resourceIds) return;
      const spaceUsage = Object.values(spaceUsages ?? {})
        .flat()
        .find(usage => usage.spaceReservationId === reservationId && usage.spaceId === resourceIds[FIRST_INDEX]);
      const isOwnReservation = spaceUsage?.isParticipationEnabled && !spaceUsage?.isRelationalReservation;
      if (!isOwnReservation) return;

      saveCachedSpaceReservation({ fromPath: Path.RESERVE_SPACE });
      openPath(embedIdInPath(Path.ACCOUNT_RESERVATION_DETAIL, PATH_IDS, [base.baseCode, reservationId]));
    },
    [base, openPath, spaceUsages, user, saveCachedSpaceReservation]
  );

  const onCloseModal = useSafeCallback((): void => {
    clearCachedSpaceReservation();
    fetchSpaceUsages();
    setRemarks(EMPTY);
    setReservationName(EMPTY);
    setSelectedReservation(undefined);
    setSelectedStartAt(undefined);
    setSelectedEndAt(undefined);
    setModalMode(ReservationModalMode.READONLY);
    setIsModalOpen(false);
  }, [
    clearCachedSpaceReservation,
    fetchSpaceUsages,
    setIsModalOpen,
    setModalMode,
    setRemarks,
    setReservationName,
    setSelectedReservation,
    setSelectedStartAt,
    setSelectedEndAt
  ]);

  const getContract = useSafeCallback(async (): Promise<ContractV2 | null> => {
    if (!user) return null;
    const request = builder<FetchContractV2ByUserRequest>().baseId(base.baseId).userId(user.userId).build();
    const response = await commonRequest<FetchContractV2ByUserRequest, FetchContractV2ByUserResponse>(
      FETCH_CONTRACT_V2_BY_USER,
      request
    );
    return response.contract;
  }, [base, commonRequest, user]);

  const searchSpaces = useSafeCallback(
    async (searchingWord: Word, isContractMember: boolean): Promise<void> => {
      setIsSearching(true);

      const publishes = isContractMember
        ? [SpacePublishOption.OPEN, SpacePublishOption.CONTRACT_LIMITED]
        : [SpacePublishOption.OPEN];

      const { LIMIT, OFFSET } = SearchOption;
      const request = builder<SearchSpacesRequest>()
        .base(base)
        .limit(LIMIT)
        .offset(OFFSET)
        .word(searchingWord)
        .publishes(publishes)
        .categories([SpaceCategory.CONFERENCE, SpaceCategory.DEVICE])
        .build();
      const response = await commonRequest<SearchSpacesRequest, SearchSpacesResponse>(SEARCH_SPACES, request);

      setSpaces(response.spaces);
      setIsSearching(false);
    },
    [base, commonRequest, setIsSearching, setSpaces]
  );

  const saveSpaceReservation = useSafeCallback(async (): Promise<SpaceReservationId | undefined> => {
    if (isUndefined(spaces)) return;
    const space = spaces.find(space => space.spaceId === spaceIdToReserve);
    if (!space || !selectedStartAt || !selectedEndAt) return;

    if (!user) {
      saveCachedSpaceReservation({
        cachedSpace: spaceToReserve,
        cachedSelectedDate: selectedDate,
        cachedSelectedStartDate: selectedStartAt,
        cachedSelectedEndDate: selectedEndAt
      });

      const logger = new LoggerService(base, firebase, user);
      await logger.saveSignInEvent();

      saveCurrentURL();
      setIsModalOpen(false);
      openBasePath(Path.SIGN_IN);
      return;
    }

    const reservation = builder<SpaceReservation>()
      .spaceReservationId(selectedReservation ? selectedReservation.spaceReservationId : uuid())
      .reservationName(reservationName)
      .pricePerHour(ItemService.calcTaxExcludedPrice(space.item))
      .taxRate(getTaxRate(space.item))
      .remarks(remarks)
      .startAt(selectedStartAt)
      .endAt(selectedEndAt)
      .space(space)
      .createdUser(user);

    if (!selectedReservation) reservation.status(space.defaultReservationStatus);

    const reservationRequest = reservation.build();

    const participant = builder<SpaceParticipant>()
      .spaceParticipantId(uuid())
      .participantDiv(SpaceParticipantDiv.OWNER)
      .user(user)
      .build();

    const request = builder<SaveSpaceReservationRequest>()
      .baseId(base.baseId)
      .userId(user.userId)
      .spaceReservation(reservationRequest)
      .spaceParticipants(selectedReservation ? [] : [participant])
      .build();

    const response = await CommonRequest.call<SaveSpaceReservationRequest, SaveSpaceReservationResponse>(
      SAVE_SPACE_RESERVATION,
      request
    );

    const isSavedSuccessfully = !!response.spaceReservationId;
    const succeededMessage = selectedReservation ? SPACE_RESERVE_UPDATED : SPACE_RESERVE_SAVED;
    const message = isSavedSuccessfully ? succeededMessage : SPACE_RESERVE_ERROR;
    const variant = isSavedSuccessfully ? SUCCESS : ERROR;

    onCloseModal();
    enqueueSnackbar(message, { variant });
    return response.spaceReservationId;
  }, [
    base,
    firebase,
    enqueueSnackbar,
    onCloseModal,
    openBasePath,
    remarks,
    reservationName,
    saveCachedSpaceReservation,
    saveCurrentURL,
    setIsModalOpen,
    spaces,
    spaceToReserve,
    selectedDate,
    selectedReservation,
    selectedStartAt,
    selectedEndAt,
    spaceIdToReserve,
    user
  ]);

  const deleteSpaceReservation = useSafeCallback(async (): Promise<SpaceReservationId | undefined> => {
    if (!selectedReservation || !user) return;

    const request = builder<UpdateSpaceReservationStatusRequest>()
      .spaceReservationIds([selectedReservation.spaceReservationId])
      .userId(user.userId)
      .baseId(base.baseId)
      .status(SpaceReservationStatus.CANCELED)
      .build();
    const response = await commonRequest<UpdateSpaceReservationStatusRequest, UpdateSpaceReservationStatusResponse>(
      UPDATE_SPACE_RESERVATION_STATUS,
      request
    );

    const isDeletedSuccessfully = !!response.spaceReservationIds[0];
    const message = isDeletedSuccessfully ? SPACE_RESERVE_DELETED : SPACE_RESERVE_ERROR;
    const variant = isDeletedSuccessfully ? SUCCESS : ERROR;

    onCloseModal();
    enqueueSnackbar(message, { variant });
  }, [base.baseId, commonRequest, enqueueSnackbar, onCloseModal, selectedReservation, user]);

  const fetchSpaceReservation = useSafeCallback(async (): Promise<void> => {
    await fetchSpaceUsages();
  }, [fetchSpaceUsages]);

  const baseResourceCategoryRelationship = useMemo<BaseResourceCategoryRelationship | undefined>(() => {
    if (isNull(selectedTabIdx)) return undefined;
    const relationships = filteredCategories[selectedTabIdx].relationalBaseResourceCategoryRelationships;
    if (!relationships || !hasLength(relationships)) return undefined;
    return relationships[FIRST_INDEX];
  }, [filteredCategories, selectedTabIdx]);

  const isSpaceConditionInputShown = useMemo<boolean>(() => {
    return (
      !isNull(selectedTabIdx) &&
      resourceCategoryTabOptions[selectedTabIdx].reservationType === SpaceReservationType.DATE
    );
  }, [resourceCategoryTabOptions, selectedTabIdx]);

  const fetchParentSpaceReservations = useSafeCallback(async (): Promise<void> => {
    if (
      !user ||
      !baseResourceCategoryRelationship ||
      !baseResourceCategoryRelationship.baseResourceCategory ||
      !baseResourceCategoryRelationship.relationalBaseResourceCategory
    )
      return;
    const relationship = builder<RelationshipForFetchChildReservationMap>()
      .parentResourceCategoryId(baseResourceCategoryRelationship.baseResourceCategory.baseResourceCategoryId)
      .childResourceCategoryId(baseResourceCategoryRelationship.relationalBaseResourceCategory.baseResourceCategoryId)
      .build();
    const request = builder<FetchSpaceReservationsByUserRequest>()
      .userIds([user.userId])
      .fromDate(new Date())
      .baseId(base.baseId)
      .relationship(relationship)
      .build();
    const response = await commonRequest<FetchSpaceReservationsByUserRequest, FetchSpaceReservationsByUserResponse>(
      FETCH_SPACE_RESERVATIONS_BY_USER,
      request
    );

    // 動作確認用
    console.log(response);

    if (!response) return;

    setParentReservations(response.spaceReservations);
    setChildReservationMap(response.childReservationMap);
  }, [
    base.baseId,
    baseResourceCategoryRelationship,
    commonRequest,
    setParentReservations,
    setChildReservationMap,
    user
  ]);

  const getContractPlan = useSafeCallback(async (): Promise<void> => {
    if (!user) return;
    const request = builder<FetchContractV2ByUserRequest>().baseId(base.baseId).userId(user.userId).build();
    const { contract } = await CommonRequest.call<FetchContractV2ByUserRequest, FetchContractV2ByUserResponse>(
      FETCH_CONTRACT_V2_BY_USER,
      request
    );
    if (!contract) return;
    setContractPlan(contract.contractPlan!);
  }, [base, setContractPlan, user]);

  useEffect(() => {
    fetchSpaceReservation();
  }, [fetchSpaceReservation]);

  useEffect(() => {
    fetchParentSpaceReservations();
  }, [fetchParentSpaceReservations]);

  useEffect(() => {
    if (isContractPlanInitialized.current) return;
    getContractPlan();
    isContractPlanInitialized.current = true;
  }, [getContractPlan]);

  const initialize = useSafeCallback(async (): Promise<void> => {
    if (!!user && !user.isActivated) {
      const logger = new LoggerService(base, firebase, user);
      await logger.saveRegisterAccountEvent();
      saveCurrentURL();
      openBasePath(Path.REGISTER_ACCOUNT);
      return;
    }
    if (!user && path === Path.RESERVE_SPACE_NO_GUARDED) {
      await searchSpaces(EMPTY, false);
    }
    if (!!user && path === Path.RESERVE_SPACE_NO_GUARDED) {
      openBasePath(Path.RESERVE_SPACE);
    }
    if (!!user && path === Path.RESERVE_SPACE) {
      const contract = await getContract();
      const isContractMember = Boolean(contract || user.contract || isAdmin(user, base.baseCode));
      setIsContractMember(isContractMember);
      await searchSpaces(EMPTY, isContractMember);
    }
    loadCachedSpaceReservation();
  }, [
    base,
    firebase,
    getContract,
    loadCachedSpaceReservation,
    openBasePath,
    path,
    saveCurrentURL,
    searchSpaces,
    setIsContractMember,
    user
  ]);

  const destroy = useSafeCallback(async (): Promise<void> => {
    setRemarks(EMPTY);
    setReservationName(EMPTY);
    setSelectedEndAt(undefined);
    setSelectedReservation(undefined);
    setSelectedSpaceIds([]);
    setSelectedStartAt(undefined);
    setSelectedTabIdx(null);
    setSpaces(undefined);
    setSpaceIdToReserve(undefined);
  }, [
    setRemarks,
    setReservationName,
    setSelectedEndAt,
    setSelectedReservation,
    setSelectedSpaceIds,
    setSelectedStartAt,
    setSelectedTabIdx,
    setSpaces,
    setSpaceIdToReserve
  ]);

  useEffect(() => {
    initialize();
    return () => {
      destroy();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!hasLength(resourceCategoryTabOptions)) return;
    const selectedResourceCategory = spaceToReserve?.baseResourceCategory;
    const selectedTabIndex = resourceCategoryTabOptions.findIndex(
      category => category.id === selectedResourceCategory?.baseResourceCategoryId
    );
    setSelectedTabIdx(isNotFoundIndex(selectedTabIndex) ? 0 : selectedTabIndex);
  }, [resourceCategoryTabOptions, setSelectedTabIdx, spaceToReserve]);

  useEffect(() => {
    if (!isInitialized) return;
    const spaceReservationId = queryParams[SPACE_RESERVATION_ID];
    if (spaceReservationId) {
      openAccountReservationDetail(spaceReservationId);
      return;
    }
  }, [isInitialized, openAccountReservationDetail, queryParams]);

  return (
    <Component className='space-reservation-screen' title='knotPLACE -予約-'>
      <Container>
        <Content>
          <TitleWrapper>
            <PageHeaderV2
              title='予約'
              subTitle={base ? `[${base.baseName}]` : EMPTY}
              titleSize={isMobile ? 'small' : 'default'}
            />
          </TitleWrapper>

          {isTabVisibled && (
            <TabsV3
              tabs={resourceCategoryTabOptions}
              variant='separated'
              selectedTabIdx={selectedTabIdx!}
              onChange={handleTabClicked}
            />
          )}

          {informationTitle && (
            <InformationPanel isBold title={informationTitle}>
              {informationNote}
            </InformationPanel>
          )}

          {isSpaceConditionInputShown && (
            <SpaceConditionInput
              baseId={base.baseId}
              spacePlan={reservableSpacePlans?.find(plan => plan.accommodationPlanId === selectedSpacePlanId)}
              baseResourceCategory={filteredCategories[selectedTabIdx!]}
              baseResourceCategoryRelationship={baseResourceCategoryRelationship!}
              parentSpaceReservations={parentReservations}
              childReservationMap={childReservationMap}
              setReservableSpacePlans={setReservableSpacePlans}
              setIsSpacePlanSearching={setIsSpacePlanSearching}
              onClick={() => selectSpacePlanRef.current?.scrollIntoView({ behavior: 'smooth' })}
            />
          )}
          {isSpaceConditionInputShown && (
            <SelectSpacePlan
              ref={selectSpacePlanRef}
              isSearching={isSpacePlanSearching}
              categoryName={isTabVisibled ? resourceCategoryTabOptions[selectedTabIdx!]?.label : '施設・設備'}
              spacePlans={reservableSpacePlans}
              selectedSpacePlanId={selectedSpacePlanId}
              handleSpacePlanClicked={setSelectedSpacePlanId}
            />
          )}

          {!isSpaceConditionInputShown && (
            <SpaceList
              base={base}
              user={user}
              categoryName={isTabVisibled ? resourceCategoryTabOptions[selectedTabIdx!]?.label : '施設・設備'}
              handleSpaceClicked={handleSpaceClicked}
              isInitialized={isInitialized}
              isSearching={isSearching || isTabChanging}
              selectedSpaceIds={selectedSpaceIds}
              spaces={filteredSpaces}
              searchSpaces={searchingWord => searchSpaces(searchingWord, isContractMember)}
            />
          )}

          {!isSpaceConditionInputShown && hasLength(selectedSpaceIds) && hasLength(spaces) && (
            <SelectDate handleDateClicked={handleDateClicked} />
          )}

          {!isSpaceConditionInputShown && hasLength(selectedSpaceIds) && selectedDate && (
            <SelectTimeWrapper ref={reserveTimeRef}>
              {isInitialized && (
                <SelectTime
                  isSearchingReservation={isSearchingReservation}
                  spaceUsages={spaceUsages}
                  selectedDate={selectedDate}
                  selectedSpaceIds={selectedSpaceIds}
                  setSelectedDate={setSelectedDate}
                  spaces={filteredSpaces}
                  timezone={base.timezone}
                  handleEventClicked={onClickEvent}
                  handleSelected={handleTimeSelected}
                />
              )}
            </SelectTimeWrapper>
          )}

          {base.isSpacePaymentsRequired && !!base.spacePayments && (
            <Notion title='お支払方法' text={base.spacePayments} />
          )}

          {!!base.spaceNotes && <Notion title='禁止事項・注意事項' text={base.spaceNotes} />}

          {base.isSpacePaymentsRequired && !!base.spaceCancelPolicy && (
            <Notion title='キャンセルポリシー' text={base.spaceCancelPolicy} />
          )}
        </Content>

        <ReservationModal
          isOpen={isModalOpen}
          mode={modalMode}
          remarks={remarks}
          reservationName={reservationName}
          selectedDate={selectedDate}
          startAt={selectedStartAt}
          endAt={selectedEndAt}
          spaces={spaces ? spaces.filter(s => selectedSpaceIds.includes(s.spaceId)) : []}
          spaceReservationIdToUpdate={selectedReservation?.spaceReservationId}
          spaceIdToReserve={spaceIdToReserve}
          spaceUsages={spaceUsages}
          user={user}
          base={base}
          deleteSpaceReservation={deleteSpaceReservation}
          saveSpaceReservation={saveSpaceReservation}
          setReservationName={setReservationName}
          setRemarks={setRemarks}
          setSpaceIdToReserve={setSpaceIdToReserve}
          onClose={onCloseModal}
        />
        <ReservationErrorModal
          isOpen={isErrorModalOpen}
          title='予約可能期間外です'
          message={errorMessage}
          onClose={() => setIsErrorModalOpen(false)}
        />
      </Container>
    </Component>
  );
});

SpaceReservationScreen.displayName = 'SpaceReservationScreen';
export default SpaceReservationScreen;

const Container = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  padding-bottom: ${themeV2.mixins.v2.spacing * 10}px;
`;

const Content = styled.div`
  width: 100%;
  max-width: 1124px;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: ${themeV2.mixins.v2.spacing}px;
  padding: ${themeV2.mixins.v2.spacing}px;
`;

const TitleWrapper = styled.div`
  width: 100%;
  height: auto;
  margin: ${themeV2.mixins.v2.spacing}px;
`;

const SelectTimeWrapper = styled.div`
  width: 100%;
  height: auto;
  scroll-margin-top: ${themeV2.mixins.v2.spacing * 2}px;
`;
